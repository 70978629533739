import React from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "urql";
import { Chip, Avatar, Button, TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';

const ENGINEERS_QUERY = gql`
  query Engineers($tenantKey: ID!) {
    tenant(key: $tenantKey) {
      engineers {
        all {
          totalCount
          edges {
            node {
              id
              fullName
            }
          }
        }
      }
    }
  }
`;

const SET_ENGINEERS_MUTATION = gql`
  mutation SetEngineers($tenantKey: ID!, $jobId: ID!, $engineers: [ID!]!) {
    tenant(key: $tenantKey) {
      jobs {
        setEngineers(id: $jobId, input: { engineers: $engineers }) {
          job {
            id
            assignedEngineers {
              totalCount
              edges {
                cursor
                node {
                  id
                  fullName
                  emailAddress
                }
              }
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  actions: {
    marginTop: theme.spacing(2),
  },
  btnLeft: {
    marginRight: theme.spacing(2),
  },
}));

const JobEngineersEditBody = ({
  tenantKey,
  jobId,
  assignedEngineers,
  onChanged,
  onCancel,
}) => {
  const classes = useStyles();

  const alreadyAssignedOptions = assignedEngineers.edges.map(
    ({ node: { id, fullName } }) => ({
      id,
      fullName,
    })
  );

  const [engineersToAssign, setEngineersToAssign] = React.useState(
    alreadyAssignedOptions
  );

  const [engineersResult] = useQuery({
    query: ENGINEERS_QUERY,
    variables: { tenantKey },
    pause: !tenantKey,
    requestPolicy: "cache-and-network",
  });

  const [{ fetching: mutating }, executeSetEngineers] = useMutation(
    SET_ENGINEERS_MUTATION
  );

  const availableEngineers =
    engineersResult.data?.tenant.engineers.all.edges.map(
      ({ node: { id, fullName } }) => ({ id, fullName })
    ) ?? [];
  const fetching = engineersResult.fetching;

  const handleChange = (_, values) => {
    setEngineersToAssign(values);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    executeSetEngineers({
      tenantKey,
      jobId,
      engineers: engineersToAssign.map(({ id }) => id),
    }).then(() => onChanged());
  };

  return <>
    <Autocomplete
      multiple
      options={availableEngineers}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.fullName}
      defaultValue={alreadyAssignedOptions}
      onChange={handleChange}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            avatar={<Avatar alt={option.fullName} />}
            label={option.fullName}
            disabled={mutating}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          disabled={mutating || fetching}
          variant="standard"
          label="Assigned engineers"
          placeholder="Engineers"
        />
      )}
    />
    <div className={classes.actions}>
      <Button
        variant="contained"
        color="primary"
        aria-label="save change to schedule"
        onClick={handleSubmit}
        disabled={fetching || mutating}
        className={classes.btnLeft}
      >
        Save changes
      </Button>
      <Button onClick={onCancel} disabled={mutating}>
        Cancel
      </Button>
    </div>
  </>;
};

export default JobEngineersEditBody;
