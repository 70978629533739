import React from "react";
import { Container, Typography, Grid } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import JobsCard from "./Dashboard/JobsCard";
import TimeslipsCard from "./Dashboard/TimeslipsCard";
import CustomersCard from "./Dashboard/CustomersCard";
import MaterialsCard from "./Dashboard/MaterialsCard";
import MembersCard from "./Dashboard/MembersCard";

const useStyles = makeStyles((theme) => ({
  cardContainer: { marginBottom: theme.spacing(2) },
  "mt-2": { marginTop: theme.spacing(2) },
}));

const Page = () => {
  const classes = useStyles();

  return (
    <Container className={classes["mt-2"]}>
      <Typography variant="h3" gutterBottom>
        Admin
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div className={classes.cardContainer}>
            <JobsCard />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <div className={classes.cardContainer}>
            <TimeslipsCard />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <div className={classes.cardContainer}>
            <MembersCard />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <div className={classes.cardContainer}>
            <CustomersCard />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <div className={classes.cardContainer}>
            <MaterialsCard />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Page;
