import React from "react";
import { FormControlLabel, Checkbox, FormGroup } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  errorContainer: {
    // backgroun
    color: theme.palette.error.dark
  }
}));

const CheckboxGroup = ({ fields, options, meta }) => {
  const classes = useStyles();
  const toggle = (event, option) => {
    if (event.target.checked) fields.push(option);
    else fields.remove(option);
  };

  return (
    <FormGroup>
      {options.map((option, index) => (
        <FormControlLabel
          key={`check${index}`}
          control={
            <Checkbox
              id={`check${option}`}
              onChange={event => toggle(event, option)}
              color="primary"
              value={option}
            />
          }
          required
          label={option}
        />
      ))}
      {meta.touched && meta.error && (
        <div className={classes.errorContainer}>{meta.error}</div>
      )}
    </FormGroup>
  );
};

export default CheckboxGroup;
