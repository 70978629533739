import React from "react";
import clsx from "clsx";
import showdown from "showdown";
import dayjs from "dayjs";
import { Grid, Typography, Button, Link } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {
  CalendarToday as ScheduleIcon,
  Place as AddressIcon,
  ContactPhone as ContactIcon,
  GroupWork as EngineersIcon,
  FormatAlignLeft as DescriptionIcon,
  AttachFile as AttachmentsIcon,
  PermDeviceInformation as ReferenceIcon,
} from "@mui/icons-material";
import JobAttachmentUpload from "../../JobAttachmentUpload";

const mdConverter = new showdown.Converter();

const useSectionStyles = makeStyles((theme) => ({
  sectionRoot: {
    display: "flex",
  },
  sectionIcon: { marginRight: theme.spacing(2) },
  sectionContent: {},
  gutterBottom: { marginBottom: theme.spacing(4) },
}));

const JobDetailSection = ({ icon, title, children, gutterBottom }) => {
  const classes = useSectionStyles();

  return (
    <div
      className={clsx(classes.sectionRoot, {
        [classes.gutterBottom]: gutterBottom,
      })}
    >
      <div className={classes.sectionIcon}>{icon}</div>
      <div className={classes.sectionContent}>
        {title && <b>{title}</b>}
        {children}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  marginRight: { marginRight: theme.spacing(2) },
  customerRoot: {
    background: theme.palette.background.default,
    padding: `${theme.spacing(2)} 0`,
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  completeBtnContainer: {
    marginTop: theme.spacing(2),
  },
  attachmentRoot: {
    marginBottom: theme.spacing(2),
  },
  uploadContainer: { margin: `${theme.spacing(1)} 0` },
}));

const JobTabPanel = ({ tenantKey, node, onCompleteClick }) => {
  const classes = useStyles();

  const descriptionHtml = React.useMemo(
    () => mdConverter.makeHtml(node?.description || ""),
    [node]
  );

  const canComplete = node.status === "SCHEDULED";

  return (
    <>
      <Typography variant="h6" className={classes.title}>
        {node.title}
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={4} md={4}>
          <div className={classes.marginRight}>
            <JobDetailSection icon={<ScheduleIcon />} gutterBottom>
              {dayjs(node.startDate).format("L LT")}
              <br />
              {dayjs(node.endDate).format("L LT")}
              {canComplete && (
                <div className={classes.completeBtnContainer}>
                  <Button
                    onClick={onCompleteClick}
                    variant="outlined"
                    color="secondary"
                  >
                    Complete
                  </Button>
                </div>
              )}
            </JobDetailSection>
            {node.reference && (
              <JobDetailSection icon={<ReferenceIcon />} gutterBottom>
                <div>{node.reference}</div>
              </JobDetailSection>
            )}
            <JobDetailSection icon={<AddressIcon />} gutterBottom>
              <div>
                <Link
                  target="_blank"
                  href={`https://maps.google.com/?q=${node.site.addressFormat}`}
                >
                  {node.site.addressFormat}
                </Link>
              </div>
            </JobDetailSection>

            <JobDetailSection
              icon={<ContactIcon />}
              title={node.contact.name}
              gutterBottom
            >
              <p>
                Email:{" "}
                <>
                  <Link href={`mailto:${node.contact.emailAddress}`}>
                    {node.contact.emailAddress}
                  </Link>
                </>
              </p>
              {node.contact.telephone.map((tel, idx) => (
                <p key={`tel-${idx}`}>
                  {tel.label}:{" "}
                  <>
                    <Link href={`tel:${tel.phone}`}>{tel.phone}</Link>
                  </>
                </p>
              ))}
            </JobDetailSection>
            <JobDetailSection
              icon={<EngineersIcon />}
              title="Engineers"
              gutterBottom
            >
              {node.assignedEngineers.edges.map(
                ({ cursor, node: engineer }) => (
                  <p key={cursor}>{engineer.fullName}</p>
                )
              )}
            </JobDetailSection>
          </div>
        </Grid>
        <Grid item xs={12} sm={8} md={8}>
          <>
            <JobDetailSection icon={<DescriptionIcon />} title="Description">
              <div dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
            </JobDetailSection>
            <JobDetailSection icon={<AttachmentsIcon />} title="Attachments">
              <>
                <div className={classes.uploadContainer}>
                  <JobAttachmentUpload tenantKey={tenantKey} jobId={node.id} />
                </div>
                {node.attachments.edges.map(
                  ({ cursor, node: attachmentNode }) => (
                    <div key={cursor} className={classes.attachmentRoot}>
                      <div>
                        <Link href={attachmentNode.src} target="_blank">
                          {attachmentNode.originalFilename}
                        </Link>
                      </div>
                      <div>
                        <small>
                          Attached{" "}
                          {dayjs(attachmentNode.attachedAt).format("L LT")}
                        </small>
                      </div>
                    </div>
                  )
                )}
              </>
            </JobDetailSection>
          </>
        </Grid>
      </Grid>
    </>
  );
};

export default JobTabPanel;
