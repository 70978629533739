import React from "react";

import gql from "graphql-tag";
import { useQuery } from "urql";
import { Form } from "react-final-form";
import { Button, LinearProgress } from "@mui/material";
import FormFields from "./FormFields";
import arrayMutators from "final-form-arrays";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const QUERY = gql`
  query MemberNode($id: ID!) {
    node(id: $id) {
      id
      ... on MembershipType {
        roles
        user {
          fullName
          emailAddress
        }
      }
    }
  }
`;

const EditDialog = ({
  membershipId: id,
  open,
  onClose,
  onSubmit,
  fetching,
}) => {
  const [result] = useQuery({
    query: QUERY,
    variables: { id },
    pause: !id,
    requestPolicy: "cache-and-network",
  });
  const [initialFromValues, setInitialFromValues] = React.useState({});

  React.useEffect(() => {
    const node = result.data?.node;

    if (!node) return;

    setInitialFromValues({
      name: node.user.fullName,
      emailAddress: node.user.emailAddress,
      isAdmin: node.roles.some((role) => role === "ADMIN"),
    });
  }, [result]);

  const node = result.data?.node;

  return (
    <Dialog
      open={open}
      scroll="paper"
      fullWidth
      onClose={onClose}
      aria-labelledby="form-dialog-title">
      {result.fetching && (
        <>
          <DialogTitle id="form-dialog-title">
            Edit user information
          </DialogTitle>
          <DialogContent>
            <LinearProgress variant="query" />
          </DialogContent>
        </>
      )}
      {!result.fetching && !node && (
        <>
          <DialogTitle id="form-dialog-title">
            Edit user information
          </DialogTitle>
          <DialogContent>User not found</DialogContent>
        </>
      )}
      {!result.fetching && node && (
        <>
          <Form
            initialValues={initialFromValues}
            mutators={{
              ...arrayMutators,
            }}
            onSubmit={onSubmit}
            render={({ handleSubmit, pristine, form }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <DialogTitle id="form-dialog-title">
                    Edit user details - {node.name}
                  </DialogTitle>
                  <DialogContent dividers>
                    <FormFields disabled={fetching} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={onClose} color="primary">
                      {pristine ? "Close" : "Close and discard changes"}
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      disabled={pristine || fetching}
                    >
                      Save changes
                    </Button>
                  </DialogActions>
                </form>
              </>
            )}
          />
        </>
      )}
    </Dialog>
  );
};

export default EditDialog;
