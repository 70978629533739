import React from "react";

import gql from "graphql-tag";
import { useQuery } from "urql";
import { tenantUrl, signInUrl } from "../Urls";
import { Link, Navigate } from "react-router-dom";

const MEMBERSHIPS = gql`
  query {
    me {
      memberships {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            tenantKey
            roles
          }
        }
      }
    }
  }
`;

const TenantSelection = () => {
  const [membershipResult] = useQuery({
    query: MEMBERSHIPS,
  });

  if (membershipResult.fetching) return "Loading memberships...";

  if (!membershipResult.data) return <Navigate to={signInUrl()} />;

  if (membershipResult.data.me.memberships.totalCount > 1) {
    return (
      <ul>
        {membershipResult.data.me.memberships.edges.map(({ cursor, node }) => (
          <li key={cursor}>
            <Link to={tenantUrl(node.tenantKey)}>{node.tenantKey}</Link>
          </li>
        ))}
      </ul>
    );
  }
  const selectedMembership = membershipResult.data.me.memberships.edges[0];

  if (selectedMembership === undefined) return "Nothing to see here";

  return <Navigate to={tenantUrl(selectedMembership.node.tenantKey)} />;
};

export default TenantSelection;
