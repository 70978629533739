import React from "react";
import dayjs from "dayjs";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.caption
  }
}));

const DayJobSchedule = ({ date, startDate, endDate }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        {date.isSame(startDate, "day")
          ? dayjs(startDate).format("LT")
          : date.isSame(endDate, "day")
          ? "End"
          : "All day"}
      </div>
      <div>
        {date.isSame(endDate, "day") ? dayjs(endDate).format("LT") : ""}
      </div>
    </div>
  );
};

export default DayJobSchedule;
