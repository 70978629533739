import React from "react";
import dayjs from "dayjs";
import clsx from "clsx";
import gql from "graphql-tag";
import { useQuery } from "urql";
import { useTenantKey } from "../../../useTenantKey";
import { LinearProgress } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import DayHeader from "./DayHeader";
import DayJob from "./DayJob";
import CalendarHeader from "./CalendarHeader";
import CalendarDays from "./CalendarDays";

const JOBS_QUERY = gql`
  query EngineerJobsDashbaordQuery(
    $tenantKey: ID!
    $dateLow: DateTimeOffset!
    $dateHigh: DateTimeOffset!
  ) {
    tenant(key: $tenantKey) {
      me {
        timeslips {
          all(filter: { dateBetween: { low: $dateLow, high: $dateHigh } }) {
            totalCount
            pageInfo {
              hasNextPage
            }
            edges {
              cursor
              node {
                id
                date
                totalTime
              }
            }
          }
        }
      }
      jobs {
        all(
          filter: {
            assignedToUser: true
            scheduledBetween: { low: $dateLow, high: $dateHigh }
            statusIn: [SCHEDULED, COMPLETED]
          }
        ) {
          totalCount
          pageInfo {
            hasNextPage
          }
          edges {
            cursor
            node {
              id
              title
              status
              startDate
              endDate
              customer {
                id
                name
              }
              site {
                name
              }
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  dayJobContainer: {
    padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
  },
  dayJobContainerNotFirst: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  jobToday: {
    color: theme.palette.action.disabled,
  },
}));

const JobsCard = () => {
  const classes = useStyles();
  const { tenantKey } = useTenantKey();
  const [searchParams, setSearchParams] = React.useState({
    dateLow: dayjs().startOf("week").format(),
    dateHigh: dayjs().endOf("week").format(),
  });
  const [result] = useQuery({
    query: JOBS_QUERY,
    variables: { tenantKey, ...searchParams },
    requestPolicy: "cache-and-network",
  });

  const handleTodayClick = () =>
    setSearchParams({
      dateLow: dayjs().startOf("week").format(),
      dateHigh: dayjs().endOf("week").format(),
    });

  const handlePreviousWeekClick = () => {
    setSearchParams((prev) => ({
      ...prev,
      dateLow: dayjs(prev.dateLow).subtract(7, "day").format(),
      dateHigh: dayjs(prev.dateHigh).subtract(7, "day").format(),
    }));
  };

  const handleNextWeekClick = () => {
    setSearchParams((prev) => ({
      ...prev,
      dateLow: dayjs(prev.dateLow).add(7, "day").format(),
      dateHigh: dayjs(prev.dateHigh).add(7, "day").format(),
    }));
  };

  const jobEdges = result.data?.tenant.jobs.all.edges || [];
  const timeslipEdges = result.data?.tenant.me.timeslips.all.edges || [];

  const bookedTime = timeslipEdges.reduce((agg, { node }) => {
    const date = dayjs(node.date);
    const totalTime = node.totalTime;

    const dayAgg = agg.find((x) => date.isSame(x.date, "day"));
    if (!dayAgg) {
      return [...agg, { date: date.format(), totalTime }];
    }

    const index = agg.indexOf(dayAgg);
    const newDay = { ...dayAgg, totalTime: dayAgg.totalTime + totalTime };

    return [...agg.slice(0, index), newDay, ...agg.splice(index + 1)];
  }, []);

  return (
    <>
      <div>
        <CalendarHeader
          firstDate={searchParams.dateLow}
          onPreviousClick={handlePreviousWeekClick}
          onNextClick={handleNextWeekClick}
          onTodayClick={handleTodayClick}
        />
        <CalendarDays
          firstDate={searchParams.dateLow}
          jobs={jobEdges}
          bookedTime={bookedTime}
        />
      </div>

      {result.fetching && <LinearProgress variant="indeterminate" />}

      {result.data && (
        <>
          {[0, 1, 2, 3, 4, 5, 6]
            .map((day) => dayjs(searchParams.dateLow).add(day, "day"))
            .map((date) => {
              const jobsOnDay = jobEdges.filter(({ node }) =>
                date.isBetween(node.startDate, node.endDate, "day", "[")
              );

              return (
                <div key={date.format()} className={classes.p2}>
                  <DayHeader date={date} />
                  {jobsOnDay.length === 0 && (
                    <div
                      className={clsx(
                        classes["px-1"],
                        classes.dayJobContainer,
                        classes.jobToday
                      )}
                    >
                      <p>Nothing to show for today</p>
                    </div>
                  )}
                  {jobsOnDay.map(({ cursor, node }, idx) => (
                    <DayJob
                      key={cursor}
                      date={date}
                      job={node}
                      className={clsx({
                        [classes.dayJobContainer]: true,
                        [classes.dayJobContainerNotFirst]: idx !== 0,
                      })}
                    />
                  ))}
                </div>
              );
            })}
        </>
      )}
    </>
  );
};

export default JobsCard;
