import { useTenantKey } from "./useTenantKey";

export const tenantUrl = (key) => `/${key}`;
export const signInUrl = () => `/login`;

export const useEngineerUrls = () => {
  const { tenantKey } = useTenantKey();

  return {
    dashboard: `/${tenantKey}/engineer`,
    jobs: `/${tenantKey}/engineer/jobs`,
    job: (id) => `/${tenantKey}/engineer/jobs/${id}`,
  };
};

export const useAdminUrls = () => {
  const { tenantKey } = useTenantKey();

  return {
    dashboard: `/${tenantKey}/admin`,
    jobs: `/${tenantKey}/admin/jobs`,
    newJob: `/${tenantKey}/admin/jobs/new`,
    job: (id) => `/${tenantKey}/admin/jobs/${id}`,
    customers: `/${tenantKey}/admin/customers`,
    customer: (id) => `/${tenantKey}/admin/customers/${id}`,
    materials: `/${tenantKey}/admin/materials`,
    editMaterial: (id) => `/${tenantKey}/admin/materials/${id}`,
    members: `/${tenantKey}/admin/members`,
    editMembership: (id) => `/${tenantKey}/admin/members/${id}`,
  };
};
