import React from "react";
import gql from "graphql-tag";
import { useQuery } from "urql";
import { useTenantKey } from "./useTenantKey";

const QUERY = gql`
  query Membership($tenantKey: ID!) {
    tenant(key: $tenantKey) {
      me {
        membership {
          id
          roles
          userId
        }
      }
    }
  }
`;

const Context = React.createContext();

function Provider(props) {
  const { tenantKey } = useTenantKey();

  const [{ data, fetching, error }] = useQuery({
    query: QUERY,
    variables: { tenantKey },
  });

  const membership = React.useMemo(() => {
    const isInAnyRole = (roles) => {
      let requiredRoles = roles;

      if (typeof roles === "string") requiredRoles = [roles];

      const actualRoles = data?.tenant.me.membership.roles;

      return requiredRoles.some((role) => actualRoles.includes(role));
    };

    return {
      tenantKey,
      fetching,
      error,
      membership: data?.tenant.me.membership,
      isInAnyRole,
    };
  }, [tenantKey, fetching, error, data]);

  React.useEffect(() => {
    console.log("membership", membership);
  }, [tenantKey, data, membership]);

  return <Context.Provider value={membership} {...props} />;
}

const useMembership = () => React.useContext(Context);

export { Provider, useMembership };
