import React from "react";
import { Switch } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Field } from "react-final-form";
import { FormControlLabel, Grid } from "@mui/material";

import TextField from "../../Fields/TextField";

const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(2),
  },
}));

const TimeslipFormFields = ({ disabled }) => {
  const classes = useStyles();

  return (
    <>
      <Field name="overtime" type="checkbox">
        {({ input, meta }) => (
          <FormControlLabel
            label="Overtime"
            control={
              <>
                <Switch {...input} />
                {meta.touched && meta.error && <span>{meta.error}</span>}
              </>
            }
          />
        )}
      </Field>
      <TextField
        name="date"
        type="date"
        label="Date"
        required
        className={classes.formField}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={6} sm={3}>
          <TextField
            name="startTime"
            type="time"
            step="300"
            fullWidth
            label="Start time"
            required
            className={classes.formField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            name="siteArrivalTime"
            type="time"
            step="300"
            fullWidth
            label="Arrival time"
            required
            className={classes.formField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            name="siteDepartureTime"
            type="time"
            step="300"
            fullWidth
            label="Dept. time"
            required
            className={classes.formField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            name="finishTime"
            type="time"
            step="300"
            fullWidth
            label="Finish time"
            required
            className={classes.formField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <TextField
        name="notes"
        type="text"
        label="Notes"
        fullWidth
        className={classes.formField}
      />
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              name="vehicle.registration"
              label="Reg"
              fullWidth
              className={classes.formField}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <TextField
              name="vehicle.startOdometerReading"
              required
              fullWidth
              type="number"
              label="Starting mileage"
              className={classes.formField}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <TextField
              name="vehicle.finishOdometerReading"
              required
              fullWidth
              type="number"
              label="Finish mileage"
              className={classes.formField}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default TimeslipFormFields;
