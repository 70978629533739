import React from "react";
import dayjs from "dayjs";
import gql from "graphql-tag";
import { useMutation } from "urql";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Form } from "react-final-form";
import TimeslipFormFields from "./TimeslipFormFields";

const ADD_MUTATION = gql`
  mutation AddJobTimeslip(
    $tenantKey: ID!
    $slips: [Tenant_Me_Timeslips_Add_TimeslipInputType!]!
  ) {
    tenant(key: $tenantKey) {
      me {
        timeslips {
          add(slips: $slips) {
            timeslips {
              id
              date
            }
          }
        }
      }
    }
  }
`;

const AddTimeslipDialog = ({ tenantKey, jobId, open, onAdded, onCancel }) => {
  const [{ fetching }, executeAddTimeslip] = useMutation(ADD_MUTATION);

  const [initialFromValues] = React.useState({
    date: dayjs().format("YYYY-MM-DD"),
    overtime: false,
    startTime: "09:00",
    siteArrivalTime: "09:00",
    siteDepartureTime: "17:30",
    finishTime: "17:30",
    notes: "",
    vehicle: {
      registration: "",
      startOdometerReading: null,
      finishOdometerReading: null,
    },
  });

  const onSubmit = ({
    date,
    startTime,
    siteArrivalTime,
    siteDepartureTime,
    finishTime,
    notes,
    overtime,
    vehicle,
  }) => {
    var slips = [
      {
        jobId,
        start: dayjs(`${date}T${startTime}`).format(),
        siteArrival: dayjs(`${date}T${siteArrivalTime}`).format(),
        siteDeparture: dayjs(`${date}T${siteDepartureTime}`).format(),
        finish: dayjs(`${date}T${finishTime}`).format(),
        notes,
        vehicle: {
          finishOdometerReading: Boolean(vehicle.finishOdometerReading)
            ? Number(vehicle.finishOdometerReading)
            : null,
          registration: vehicle.registration,
          startOdometerReading: Boolean(vehicle.startOdometerReading)
            ? Number(vehicle.startOdometerReading)
            : null,
        },
        overtime,
      },
    ];

    executeAddTimeslip({ tenantKey, slips }).then(({ data, error }) => {
      if (!error) {
        onAdded();
        return;
      }

      console.error(error);
    });
  };
  return (
    <>
      <Dialog open={open} fullWidth>
        <Form
          initialValues={initialFromValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle id="form-dialog-title">Add timesheet</DialogTitle>
              <DialogContent>
                {/* <code>{JSON.stringify(values, null, 2)}</code> */}
                <TimeslipFormFields disabled={fetching} />
              </DialogContent>
              <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                <Button type="submit" color="primary" disabled={fetching}>
                  Add timeslip
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

export default AddTimeslipDialog;
