import React from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { MoreVert as MenuIcon } from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center"
  },
  qtyContainer: {
    marginRight: theme.spacing(1)
  },
  qtyLabel: {},
  nameContainer: {
    flex: "1 1 auto"
  },
  nameValue: {
    ...theme.typography.h5,
    flex: "1 1 auto"
  }
}));

const MaterialRow = ({ node, onRemove }) => {
  const classes = useStyles();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const handleMenuClick = e => {
    setMenuAnchorEl(e.target);
  };

  const handleMenuClose = () => setMenuAnchorEl(null);

  return (
    <div className={classes.root}>
      <div className={classes.nameContainer}>
        <div className={classes.nameValue}>{node.name}</div>
      </div>
      <div className={classes.qtyContainer}>
        <div className={classes.qtyLabel}>x{node.qty}</div>
      </div>
      <div className={classes.menuContainer}>
        <IconButton
          aria-controls="options-menu"
          aria-haspopup="true"
          onClick={handleMenuClick}
          size="large">
          <MenuIcon />
        </IconButton>
        <Menu
          id="options-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={() => {
              onRemove().then(handleMenuClose);
            }}
          >
            Remove
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default MaterialRow;
