import React from "react";
import gql from "graphql-tag";
import { useMutation } from "urql";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import useQueryString from "../useQueryString";

import { Form } from "react-final-form";
import { Button, Container, Typography, Card } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Alert } from '@mui/material';
import EmailField from "../Fields/EmailField";
import PasswordField from "../Fields/PasswordField";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  "mt-6": {
    marginTop: theme.spacing(6),
  },
  "m-2": {
    margin: theme.spacing(2),
  },
  "mb-2": {
    marginBottom: theme.spacing(2),
  },
  "mt-2": {
    marginTop: theme.spacing(2),
  },
}));

const CREATE_USER_ACCESS_TOKEN = gql`
  mutation CreateUserAccessToken($email: String!, $password: String!) {
    createUserAccessToken(input: { email: $email, password: $password }) {
      success
      user {
        id
        fullName
        emailAddress
      }
      token
      expiresIn
    }
  }
`;

const SignIn = () => {
  const { continueTo } = useQueryString();
  const classes = useStyles();
  const navigate = useNavigate();
  const { signIn: setSignIn } = useAuth();
  const [validationMessage, setValidationMessage] = React.useState(null);
  const [createUserAccessTokenResult, createUserAccessToken] = useMutation(
    CREATE_USER_ACCESS_TOKEN
  );

  const onSubmit = (values) => {
    setValidationMessage(null);

    createUserAccessToken(values)
      .then(({ data, error }) => {
        console.log({ data, error });
        const { createUserAccessToken } = data;
        if (!createUserAccessToken.success) {
          setValidationMessage("Email or password not recognised");
          return;
        }

        setSignIn(createUserAccessToken).then(
          () => navigate(continueTo || "/"),
          (error) => {
            console.log("Failed login", error);
            setValidationMessage(error.message);
          }
        );
      })
      .catch((error) => {
        console.error(error);
        setValidationMessage("There was a problem signing in");
      });
  };
  return (
    <>
      <Container maxWidth="xs">
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <Card className={classes["mt-6"]}>
              <div className={classes["m-2"]}>
                <form onSubmit={handleSubmit}>
                  <Typography variant="h1" gutterBottom>
                    Sign in
                  </Typography>
                  <EmailField
                    name="email"
                    placeholder="you@example.com"
                    label="Email"
                    required
                    fullWidth
                    className={classes["mb-2"]}
                  />
                  <PasswordField
                    name="password"
                    label="Password"
                    required
                    fullWidth
                    className={classes["mb-2"]}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={createUserAccessTokenResult.fetching}
                  >
                    Sign in
                  </Button>
                  {validationMessage && (
                    <Alert className={classes["mt-2"]} severity="error">
                      {validationMessage}
                    </Alert>
                  )}
                </form>
              </div>
            </Card>
          )}
        />
      </Container>
    </>
  );
};

export default SignIn;
