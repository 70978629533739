import React from "react";
import clsx from "clsx";
import gql from "graphql-tag";
import { LinearProgress } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from "urql";
import FileUpload from "../../FileUpload";

const SIGN_UPLOAD_MUTATION = gql`
  mutation SignRiskAssessmentUpload(
    $tenantKey: ID!
    $jobId: ID!
    $filename: String!
    $contentType: String!
  ) {
    tenant(key: $tenantKey) {
      jobs {
        rams {
          signUpload(
            id: $jobId
            input: { filename: $filename, contentType: $contentType }
          ) {
            fileKey
            signedUrl
          }
        }
      }
    }
  }
`;

const ATTACH_UPLOAD_MUTATION = gql`
  mutation AttachRiskAssessment(
    $tenantKey: ID!
    $jobId: ID!
    $originalFilename: String!
    $fileKey: String!
  ) {
    tenant(key: $tenantKey) {
      jobs {
        rams {
          attach(
            id: $jobId
            input: { fileKey: $fileKey, originalFilename: $originalFilename }
          ) {
            job {
              id
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    // padding: theme.spacing(2),
    // alignItems: "top"
  },
  iconContainer: {
    flex: "0 0 auto",
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  bodyContainer: {
    flex: "1 1 auto",
    paddingTop: theme.spacing(1),
  },
  actionContainer: { flex: "0 0 auto" },
  "mb-2": { marginBottom: theme.spacing(2) },
  attachmentRoot: {
    marginBottom: theme.spacing(2),
  },
  dropContainer: {
    // margin: `${theme.spacing(1)} 0`,
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.background.default}`,
    padding: theme.spacing(1),
  },
  dropContainerActive: {
    background: theme.palette.secondary.light,
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.getContrastText(theme.palette.secondary.light),
  },
}));

const JobRiskAssessmentUpload = ({ tenantKey, jobId }) => {
  const [progress, setProgress] = React.useState(0);
  const [uploading, setUploading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const classes = useStyles();

  const [, executePreSignUrl] = useMutation(SIGN_UPLOAD_MUTATION);
  const [, executeAttachUpload] = useMutation(ATTACH_UPLOAD_MUTATION);

  const handleProgress = ({ total }) => {
    setProgress(total);
    setUploading(total < 1);
    setError(null);
  };

  const handleError = (error) => {
    console.log("error", error);
    setProgress(0);
    setUploading(false);
    setError("Error");
  };

  const handleGetPreSignedUrl = ({ name, type }) => {
    return executePreSignUrl({
      tenantKey,
      jobId,
      filename: name,
      contentType: type,
    }).then(({ data, error }) => {
      if (error) console.error({ error });
      return data.tenant.jobs.rams.signUpload;
    });
  };

  const handleUploadReady = ({
    file: { name: originalFilename },
    preSign: { fileKey },
  }) => {
    return executeAttachUpload({
      tenantKey,
      jobId,
      originalFilename,
      fileKey,
    });
  };

  return (
    <div className={classes.root}>
      {uploading && (
        <LinearProgress
          variant={progress === 0 ? "indeterminate" : "determinate"}
          value={progress * 100}
        />
      )}

      {error && <p>{error}</p>}

      <FileUpload
        getSignedUrl={handleGetPreSignedUrl}
        onProgress={handleProgress}
        onError={handleError}
        onUploadReady={handleUploadReady}
      >
        {({ isDragActive }) => (
          <div
            className={clsx(classes.dropContainer, {
              [classes.dropContainerActive]: isDragActive,
            })}
          >
            {isDragActive ? (
              <span>Drop the risk assessment file here ...</span>
            ) : (
              <span>
                Drag 'n' drop the risk assessment file here, or click to select
                file
              </span>
            )}
          </div>
        )}
      </FileUpload>
    </div>
  );
};

export default JobRiskAssessmentUpload;
