import React from "react";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  noValue: {
    // color: theme.palette.warning.dark
  },
  row: {
    marginBottom: theme.spacing(1),
  },
}));

const JobNotificationEmailsBody = ({ emails }) => {
  const classes = useStyles();

  if (emails?.length > 0)
    return (
      <div>
        {emails.map((email) => (
          <div className={classes.row} key={email}>
            {email}
            <br />
          </div>
        ))}
      </div>
    );

  return (
    <div className={classes.noValue}>
      <em>Notification emails</em>
    </div>
  );
};

export default JobNotificationEmailsBody;
