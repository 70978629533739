import React from "react";

import gql from "graphql-tag";
import { useQuery, useMutation } from "urql";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import { useAdminUrls } from "../../Urls";
import { useTenantKey } from "../../useTenantKey";
import {
  Container,
  Breadcrumbs,
  Typography,
  LinearProgress,
  Fab,
  Snackbar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  IconButton,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Add as AddIcon, Delete as RemoveIcon } from "@mui/icons-material";

import NewDialog from "./NewDialog";
import EditDialog from "./EditDialog";

const QUERY = gql`
  query AllMaterialsQuery($tenantKey: ID!) {
    tenant(key: $tenantKey) {
      materials {
        all {
          totalCount
          pageInfo {
            hasNextPage
          }
          edges {
            cursor
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const EDIT_MUTATION = gql`
  mutation EditMaterial(
    $tenant_id: ID!
    $id: ID!
    $input: Tenant_Material_Edit_InputType!
  ) {
    tenant(key: $tenant_id) {
      materials {
        edit(id: $id, input: $input) {
          material {
            id
            name
            variants {
              name
              unitCost
            }
          }
        }
      }
    }
  }
`;

const REMOVE_MUTATION = gql`
  mutation RemoveMaterial($tenant_id: ID!, $id: ID!) {
    tenant(key: $tenant_id) {
      materials {
        remove(id: $id) {
          material {
            id
            name
            variants {
              name
              unitCost
            }
          }
        }
      }
    }
  }
`;

const CREATE_NEW_MUTATION = gql`
  mutation CreateNewMaterial(
    $tenant_id: ID!
    $input: Tenant_Material_CreateNew_InputType!
  ) {
    tenant(key: $tenant_id) {
      materials {
        createNew(input: $input) {
          material {
            id
            name
            variants {
              name
              unitCost
            }
          }
        }
      }
    }
  }
`;

const usePageLayoutStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  "mb-2": {
    marginBottom: theme.spacing(2),
  },
  "mt-2": { marginTop: theme.spacing(2) },
}));

const PageLayout = ({ children, onNewClick }) => {
  const adminUrls = useAdminUrls();
  const classes = usePageLayoutStyles();
  const handleAddClick = () => {
    onNewClick();
  };

  return (
    <Container className={classes["mt-2"]}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to={adminUrls.dashboard} component={RouterLink}>
          Admin
        </Link>
        ><Typography color="textPrimary">Materials</Typography>
      </Breadcrumbs>
      <Typography variant="h3" gutterBottom>
        Materials
      </Typography>
      {children}
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={handleAddClick}
      >
        <AddIcon />
      </Fab>
    </Container>
  );
};

const usePageStyles = makeStyles((theme) => ({
  "mb-8": {
    marginBottom: theme.spacing(8),
  },
  "mt-2": { marginTop: theme.spacing(2) },
}));

const Page = () => {
  const adminUrls = useAdminUrls();
  const classes = usePageStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const { tenantKey } = useTenantKey();
  const [result, executeQuery] = useQuery({
    query: QUERY,
    variables: { tenantKey },
    requestPolicy: "cache-and-network",
  });
  const [editResult, executeEdit] = useMutation(EDIT_MUTATION);

  const [removeMaterialId, setRemoveMaterialId] = React.useState(null);
  const [removeResult, executeRemove] = useMutation(REMOVE_MUTATION);
  const [createNewResult, executeCreateNew] = useMutation(CREATE_NEW_MUTATION);

  // const [editingId, setEditingId] = React.useState(id);
  const [newDialogOpen, setNewDialogOpen] = React.useState(null);
  const [newMaterialSnackbarOpen, setNewMaterialSnackbarOpen] =
    React.useState(false);

  const handleFinishEdit = () => {
    navigate(adminUrls.materials);
  };

  const handleCancelEdit = handleFinishEdit;

  const handleEditSubmit = (form) => {
    executeEdit({
      tenant_id: tenantKey,
      id,
      input: form,
    }).then(({ data, error }) => {
      if (!error) handleFinishEdit();
    });
  };

  const handleCreateNewSubmit = (form) => {
    executeCreateNew({
      tenant_id: tenantKey,
      input: form,
    }).then(() => {
      setNewDialogOpen(false);
      setNewMaterialSnackbarOpen(true);
    });
  };

  const handleRemoveMaterialClick = (id) => () => setRemoveMaterialId(id);

  const handleCancelRemoveClick = () => setRemoveMaterialId(null);

  const handleConfirmRemoveClick = () => {
    executeRemove({
      tenant_id: tenantKey,
      id: removeMaterialId,
    }).then(({ error }) => {
      if (!error) setRemoveMaterialId(null);
      executeQuery({ tenantKey });
    });
  };

  return (
    <PageLayout onNewClick={() => setNewDialogOpen(true)}>
      {(result.fetching || editResult.fetching || removeResult.fetching) && (
        <LinearProgress variant="indeterminate" />
      )}
      {!result.fetching && (
        <>
          <TableContainer component={Paper} className={classes["mb-8"]}>
            <Table aria-label="materials table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {result.data.tenant.materials.all.edges.map(
                  ({ cursor, node }) => (
                    <TableRow key={cursor}>
                      <TableCell>
                        <b>
                          <Link
                            component={RouterLink}
                            to={adminUrls.editMaterial(node.id)}
                          >
                            {node.name}
                          </Link>
                        </b>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={handleRemoveMaterialClick(node.id)}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <EditDialog
            fetching={editResult.fetching}
            materialId={id}
            tenantKey={tenantKey}
            open={Boolean(id)}
            onClose={handleCancelEdit}
            onSubmit={handleEditSubmit}
          />
          <NewDialog
            fetching={createNewResult.fetching}
            id={id}
            open={Boolean(newDialogOpen)}
            onClose={() => setNewDialogOpen(false)}
            onSubmit={handleCreateNewSubmit}
          />
          <Snackbar
            open={newMaterialSnackbarOpen}
            onClose={() => {
              setNewMaterialSnackbarOpen(false);
            }}
            message="Material added"
          />
          <Dialog
            open={Boolean(removeMaterialId)}
            onClose={handleCancelRemoveClick}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Remove material</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You are about to remove a material.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelRemoveClick} color="primary">
                Cancel
              </Button>
              <Button
                onClick={handleConfirmRemoveClick}
                color="primary"
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </PageLayout>
  );
};

export default Page;
