import React from "react";
import gql from "graphql-tag";
import { useQuery } from "urql";
import {
  LinearProgress,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
} from "@mui/material";
import { useCSVDownloader } from "react-papaparse";

const QUERY = gql`
  query JobNode($id: ID!) {
    node(id: $id) {
      id
      ... on Tenant_Job_JobType {
        title
        customer {
          name
        }
        site {
          name
        }
        materials {
          edges {
            cursor
            node {
              name
              qty
              unitCost
              subTotalCost
            }
          }
        }
      }
    }
  }
`;

// const useStyles = makeStyles(theme => ({
//   // tableContainer: {marginBottom}
// }));

const JobMaterialsTable = ({ jobId }) => {
  // const classes = useStyles();
  const [{ fetching, data }] = useQuery({
    query: QUERY,
    variables: { id: jobId },
    pause: !jobId,
    requestPolicy: "cache-and-network",
  });

  const { CSVDownloader, Type } = useCSVDownloader();

  const edges = React.useMemo(() => data?.node.materials.edges || [], [data]);

  const csvData = React.useMemo(() => {
    const rows = (edges || []).map(({ node }) => [
      data.node.customer.name,
      data.node.site.name,
      data.node.title,
      node.name || "",
      node.qty,
      node.unitCost,
      node.subTotalCost,
    ]);
    return [
      ["Customer", "Site", "Job", "Material", "Qty", "Unit Cost", "Total"],
      ...rows,
    ];
  }, [edges, data]);

  if (fetching) return <LinearProgress variant="indeterminate" />;

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="Job materials table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>Unit cost</TableCell>
              <TableCell>Sub total cost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {edges.map(({ cursor, node }) => (
              <TableRow key={cursor}>
                <TableCell>{node.name}</TableCell>
                <TableCell>{node.qty}</TableCell>
                <TableCell>{node.unitCost?.toFixed(2)}</TableCell>
                <TableCell>{node.subTotalCost?.toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
                <Button
                  variant="text"
                  size="small"
                  color="secondary"
                  component={CSVDownloader}
                  type={Type.Button}
                  filename={"materials"}
                  bom={true}
                  data={csvData}
                >
                  Download table
                </Button>
              </TableCell>
              <TableCell colSpan={2} align="right">
                Total
              </TableCell>
              <TableCell>
                {edges
                  .reduce((agg, { node }) => agg + (node.subTotalCost ?? 0), 0)
                  .toFixed(2)}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default JobMaterialsTable;
