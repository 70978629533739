import React from "react";
import showdown from "showdown";

const mdConverter = new showdown.Converter();

const JobDescriptionReadBody = ({ description }) => {
  const descriptionHtml = React.useMemo(
    () => mdConverter.makeHtml(description),
    [description]
  );

  return <div dangerouslySetInnerHTML={{ __html: descriptionHtml }} />;
};

export default JobDescriptionReadBody;
