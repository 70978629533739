import React from "react";
import clsx from "clsx";
import { useTenantKey } from "../../useTenantKey";
import { Container, Typography, Tabs, Tab, Box, Badge } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import JobTabPanel from "./JobTabPanel";
import RamsTabPanel from "./RamsTabPanel";
import TimeslipsTabPanel from "./TimeslipsTabPanel";
import MaterialsTabPanel from "./MaterialsTabPanel";
import CompleteJobDialog from "./CompleteJobDialog";
import { green } from '@mui/material/colors';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  marginRight: { marginRight: theme.spacing(2) },
  customerRoot: {
    background: theme.palette.background.default,
    padding: `${theme.spacing(2)} 0`,
    marginBottom: theme.spacing(2),
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
  "mb-0": { marginBottom: 0 },
  "mt-2": { marginTop: theme.spacing(2) },
  completedBanner: {
    background: green[500],
    color: theme.palette.getContrastText(green[500]),
    marginBottom: theme.spacing(2),
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
}));

const JobDetails = ({ job }) => {
  const { tenantKey } = useTenantKey();
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [completeJobOpen, setCompleteJobOpen] = React.useState(false);

  const handleCancelCompleteJob = () => setCompleteJobOpen(false);
  const handleCompleteJobDone = () => setCompleteJobOpen(false);

  const handleTabChange = (e, x) => {
    setSelectedTab(x);
  };

  const handleCompleteClick = () => setCompleteJobOpen(true);

  const completed = job?.status === "COMPLETED";
  const hasRams = job?.riskAssessment;

  return (
    <>
      <div
        className={clsx(classes.customerRoot, {
          [classes["mb-0"]]: completed,
        })}
      >
        <Container>
          <Typography variant="h3" gutterBottom>
            {job.title}
          </Typography>
          <Typography variant="h5">
            {job.customer.name} <small>({job.site.name})</small>
          </Typography>
        </Container>
      </div>
      {completed && <div className={classes.completedBanner}>Completed</div>}
      <Container>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          className={classes.tabs}
        >
          <Tab label="Job" />
          <Tab label="Materials" />
          <Tab label="Timeslips" />
          <Tab
            label={
              <Badge color="secondary" variant="dot" invisible={!hasRams}>
                RAMs
              </Badge>
            }
            disabled={!Boolean(job?.riskAssessment)}
          />
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <JobTabPanel
            tenantKey={tenantKey}
            node={job}
            onCompleteClick={handleCompleteClick}
          />
          <CompleteJobDialog
            tenantKey={tenantKey}
            jobId={job.id}
            open={completeJobOpen}
            onCompleted={handleCompleteJobDone}
            onCancel={handleCancelCompleteJob}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <MaterialsTabPanel tenantKey={tenantKey} jobId={job.id} />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <TimeslipsTabPanel tenantKey={tenantKey} jobId={job.id} />
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
          <RamsTabPanel
            riskAssessments={hasRams ? [job?.riskAssessment] : []}
          />
        </TabPanel>
      </Container>
    </>
  );
};

export default JobDetails;
