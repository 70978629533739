import React from "react";
import { Link } from "@mui/material";

const RamsTabPanel = ({ riskAssessments }) => {
  return (
    <>
      {riskAssessments &&
        riskAssessments.map((ram) => (
          <p key={ram.src}>
            <Link href={ram.src} target="_blank">
              {ram.originalFilename}
            </Link>
          </p>
        ))}
    </>
  );
};

export default RamsTabPanel;
