import React from "react";
import clsx from "clsx";
import makeStyles from '@mui/styles/makeStyles';

import JobTitleReadBody from "./JobTitleReadBody";
import JobTitleEditBody from "./JobTitleEditBody";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "top",
    padding: theme.spacing(1),
    marginLeft: -theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  rootInEdit: {
    background: theme.palette.action.focus,
  },
  iconContainer: {
    flex: "0 0 auto",
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  bodyContainer: {
    flex: "1 1 auto",
    paddingTop: theme.spacing(1),
  },
  actionContainer: { flex: "0 0 auto" },
  "mb-2": { marginBottom: theme.spacing(2) },
}));

const JobTitle = ({ tenantKey, jobId, title, editable }) => {
  const classes = useStyles();
  const [editMode, setEditMode] = React.useState(false);

  const handleEnterEditMode = () => {
    if (!editable) return;

    setEditMode(true);
  };

  const handleCanelEditMode = () => {
    setEditMode(false);
  };

  const handleChanged = () => {
    setEditMode(false);
  };

  return (
    <div className={clsx(classes.root, { [classes.rootInEdit]: editMode })}>
      <div className={classes.bodyContainer}>
        {editMode ? (
          <JobTitleEditBody
            {...{ tenantKey, jobId, title }}
            onCancel={handleCanelEditMode}
            onChanged={handleChanged}
          />
        ) : (
          <JobTitleReadBody {...{ title }} onClick={handleEnterEditMode} />
        )}
      </div>
    </div>
  );
};

export default JobTitle;
