import React from "react";
import "./App.css";
import { Provider as GraphQlProvider } from "urql";
import { Provider as AuthProvider } from "./AuthContext";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import theme from "./theme";

import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isBetween from "dayjs/plugin/isBetween";

import client from "./urqlClient";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";

dayjs.locale("en-gb");
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);

console.log("APP_VERSON:", process.env.REACT_APP_VERSION);
console.log("APP_CLIENT_NAME:", process.env.REACT_APP_CLIENT_NAME);

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GraphQlProvider value={client}>
          <AuthProvider>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </AuthProvider>
        </GraphQlProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
