import React from "react";
import { Tabs as MuiTabs, Tab } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useEngineerUrls } from "../../Urls";
// import {
//   List as ListIcon,
//   CalendarToday as CalendarIcon,
// } from "@mui/icons-material";

const Tabs = ({ selected = 0 }) => {
  const engineerUrls = useEngineerUrls();
  return (
    <MuiTabs variant="fullWidth" value={selected} aria-label="nav tabs example">
      <Tab
        component={RouterLink}
        to={engineerUrls.dashboard}
        label="Calendar"
        // icon={<CalendarIcon />}
      />
      <Tab
        component={RouterLink}
        to={engineerUrls.jobs}
        label="List"
        // icon={<ListIcon />}
      />
    </MuiTabs>
  );
};

export default Tabs;
