import React from "react";

import gql from "graphql-tag";
import { useQuery } from "urql";
import { Route, Routes, Navigate } from "react-router-dom";

import { Provider as TenantKeyProvider } from "../useTenantKey";
import { Provider as MembershipKeyProvider } from "../useMembership";

import MenuAppBar from "../Layout/AppBar";
import RequiresMembership from "../RequiresMembership";

import AdminRoutes from "../Admin/Routes";
import EngineerRoutes from "../Engineer/Routes";

import { useParams } from "react-router-dom";

const MEMBERSHIPS = gql`
  query {
    me {
      memberships {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            tenantKey
            roles
          }
        }
      }
    }
  }
`;

const Tenant = () => {
  const { tenantKey } = useParams();
  const [membershipResult] = useQuery({
    query: MEMBERSHIPS,
  });

  if (membershipResult.fetching) return "Loading...";

  const membership = membershipResult.data.me.memberships.edges.find(
    ({ node }) => node.tenantKey === tenantKey
  );

  if (membership === undefined) return "Nothing to see here";

  return (
    <TenantKeyProvider>
      <MembershipKeyProvider>
        <MenuAppBar />
        <Routes>
          <Route index element={<Navigate to="engineer" />} />
          <Route
            path="admin/*"
            element={
              <RequiresMembership rolesAnyOf={["ADMIN"]}>
                <AdminRoutes />
              </RequiresMembership>
            }
          />
          <Route
            path="engineer/*"
            element={
              <RequiresMembership rolesAnyOf={["ENGINEER"]}>
                <EngineerRoutes />
              </RequiresMembership>
            }
          />
        </Routes>
      </MembershipKeyProvider>
    </TenantKeyProvider>
  );
};

export default Tenant;
