import { createTheme, responsiveFontSizes, adaptV4Theme } from "@mui/material/styles";

import { red as primary, lime as secondary } from '@mui/material/colors';

// https://material.io/tools/color/#!/?view.left=0&view.right=1&primary.color=263238&secondary.color=00a9f4
const theme = responsiveFontSizes(
  createTheme(adaptV4Theme({
    palette: {
      primary: {
        // light: "#5cb4ff",
        main: primary[600],
        // dark: "#00599c"
      },
      secondary: {
        // main: "#d14800"
        // light: "#7538cb",
        main: secondary[700],
        // dark: "#00006a"
      },
    },
  }))
);

export default theme;
