import React from "react";

import auth from "./Auth";

const AnonStatus = { isAuthenticated: false };

const AuthContext = React.createContext();

function Provider(props) {
  const [status, setStatus] = React.useState(() => auth.status());

  const signIn = (data) => {
    return new Promise((resolve, _) => {
      const { user } = data;

      auth.setSession({
        idToken: data.token,
        expiresIn: data.expiresIn,
        userId: user.id,
        user,
      });

      setStatus({
        isAuthenticated: true,
        user,
      });

      resolve();
    });
  };

  const signOut = (continueTo) => {
    return new Promise((resolve, _) => {
      auth.logout(continueTo);
      setStatus(AnonStatus);
      resolve();
    });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: status.isAuthenticated || false,
        // account: status.account,
        user: status.user,
        // isInRole,
        signIn,
        signOut,
      }}
      {...props}
    />
  );
}

const useAuth = () => React.useContext(AuthContext);

export { Provider, useAuth };
