import React from "react";

import { Typography, Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import TextField from "../../Fields/TextField";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  "mt-6": {
    marginTop: theme.spacing(6),
  },
  "m-2": {
    margin: theme.spacing(2),
  },
  "mb-2": {
    marginBottom: theme.spacing(2),
  },
  "mt-2": {
    marginTop: theme.spacing(2),
  },
}));

const FormLayout = ({ disabled }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="name"
            placeholder="Customer name"
            label="Customer name"
            required
            fullWidth
            disabled={disabled}
            className={classes["mb-2"]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h5" gutterBottom>
            Address
          </Typography>
          <TextField
            name="address.line1"
            label="Line 1"
            required
            disabled={disabled}
            fullWidth
            className={classes["mb-2"]}
          />
          <TextField
            name="address.line2"
            label="Line 2"
            disabled={disabled}
            fullWidth
            className={classes["mb-2"]}
          />
          <TextField
            name="address.townCity"
            label="Town/city"
            required
            disabled={disabled}
            fullWidth
            className={classes["mb-2"]}
          />
          <TextField
            name="address.county"
            label="County"
            disabled={disabled}
            fullWidth
            className={classes["mb-2"]}
          />
          <TextField
            name="address.postCode"
            label="Post code"
            required
            disabled={disabled}
            fullWidth
            className={classes["mb-2"]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h5" gutterBottom>
            Contact
          </Typography>
          <TextField
            name="contact.name"
            label="Name"
            required
            disabled={disabled}
            fullWidth
            className={classes["mb-2"]}
          />
          <TextField
            name="contact.emailAddress"
            type="email"
            label="Email address"
            placeholder="contact@example.com"
            required
            disabled={disabled}
            fullWidth
            className={classes["mb-2"]}
          />
          <TextField
            name="contact.telephone[0].phone"
            type="tel"
            label="Phone number"
            required
            disabled={disabled}
            fullWidth
            className={classes["mb-2"]}
          />
          <TextField
            name="contact.telephone[0].label"
            label="Label"
            required
            disabled={disabled}
            fullWidth
            className={classes["mb-2"]}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormLayout;
