import React from "react";

import gql from "graphql-tag";
import { useMutation } from "urql";
import { Form } from "react-final-form";
import { Button } from "@mui/material";
import SiteFormFields from "./SiteFormFields";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const EDIT_SITE = gql`
  mutation EditCustomerSite(
    $tenantKey: ID!
    $customerId: ID!
    $siteId: ID!
    $input: Tenant_Customer_EditSite_InputType!
  ) {
    tenant(key: $tenantKey) {
      customers {
        editSite(id: $customerId, siteId: $siteId, input: $input) {
          customer {
            id
            sites {
              totalCount
              edges {
                node {
                  id
                  name
                  address {
                    line1
                    line2
                    townCity
                    county
                    postCode
                  }
                  contact {
                    name
                    emailAddress
                    telephone {
                      phone
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const EditSiteDialog = ({
  tenantKey,
  customerId,
  site,
  open,
  onCancel,
  onEditted,
  fetching,
}) => {
  const [, executeMutation] = useMutation(EDIT_SITE);

  const [initialFromValues, setInitalFormValues] = React.useState(null);

  React.useEffect(() => {
    setInitalFormValues({
      name: site.name,
      address: site.address && {
        line1: site.address.line1,
        line2: site.address.line2,
        townCity: site.address.townCity,
        county: site.address.county,
        postCode: site.address.postCode,
      },
      contact: site.contact && {
        name: site.contact.name,
        emailAddress: site.contact.emailAddress,
        telephone: site.contact.telephone.map((tel) => ({
          phone: tel.phone,
          label: tel.label,
        })),
      },
    });
  }, [site, setInitalFormValues]);

  const handleSubmit = (form) => {
    executeMutation({
      customerId,
      tenantKey,
      siteId: site.id,
      input: { ...form },
    }).then(() => {
      onEditted();
    });
  };

  const onCloseClick = (_event, reason) => {
    if (reason === "backdropClick") return;

    onCancel();
  };

  return (
    <Dialog
      open={open}
      scroll="paper"
      fullWidth
      onClose={onCloseClick}
      aria-labelledby="form-dialog-title"
      ba
    >
      <DialogTitle id="form-dialog-title">Edit customer site</DialogTitle>
      <Form
        initialValues={initialFromValues}
        onSubmit={handleSubmit}
        render={({ handleSubmit, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              {/* <pre>
                {JSON.stringify(
                  {
                    initialFromValues,
                    values,
                  },
                  null,
                  2
                )}
              </pre> */}
              <SiteFormFields disabled={fetching} values={values} />
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel} color="primary">
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={
                  (pristine || fetching) &&
                  !(
                    !Boolean(values.contact) &&
                    Boolean(initialFromValues.contact)
                  ) &&
                  !(
                    !Boolean(values.address) &&
                    Boolean(initialFromValues.address)
                  )
                }
              >
                Update site
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

export default EditSiteDialog;
