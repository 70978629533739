import React from "react";
import gql from "graphql-tag";
import { useMutation } from "urql";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useTenantKey } from "../../useTenantKey";
import { useAdminUrls } from "../../Urls";
import {
  Container,
  Stepper,
  Breadcrumbs,
  Link,
  Step,
  StepLabel,
  StepContent,
  Typography,
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import CustomerStep from "./New/CustomerStep";
import SiteAndContactStep from "./New/SiteAndContactStep";
import DetailStep from "./New/DetailStep";

const CREATE_JOB = gql`
  mutation CreateJob($tenantKey: ID!, $input: Tenant_Job_CreateNew_InputType!) {
    tenant(key: $tenantKey) {
      jobs {
        createNew(input: $input) {
          job {
            id
            status
            title
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  stepper: { padding: "0" },
  "mt-2": { marginTop: theme.spacing(2) },
}));

const NewPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { tenantKey } = useTenantKey();
  const adminUrls = useAdminUrls();
  const [activeStep, setActiveStep] = React.useState(0);
  const [form, setForm] = React.useState({});

  const [{ fetching }, executeCreateNewJob] = useMutation(CREATE_JOB);

  const handleCompleteCustomerStep = ({ customerId, contact, site }) => {
    setForm({
      customerId,
      contact,
      site,
    });
    handleNext();
  };

  const handleCompleteSiteAndContactStep = ({ contact, site }) => {
    setForm((prevForm) => ({ ...prevForm, contact, site }));
    handleNext();
  };

  const handleCompleteDetail = (data) => {
    var completeForm = { ...form, ...data };
    setForm((prevForm) => completeForm);

    executeCreateNewJob({
      tenantKey,
      input: completeForm,
    }).then(({ data, error }) => {
      if (!error) {
        var id = data.tenant.jobs.createNew.job.id;
        navigate(`/${tenantKey}/admin/jobs/${id}`);
        return;
      }
      console.error({ data, error });
    });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const disabled = fetching;

  return (
    <Container className={classes["mt-2"]}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to={adminUrls.dashboard} component={RouterLink}>
          Admin
        </Link>
        <Link color="inherit" to={adminUrls.jobs} component={RouterLink}>
          Jobs
        </Link>
        ><Typography color="textPrimary">Create new</Typography>
      </Breadcrumbs>
      <Typography variant="h3" gutterBottom>
        Create a new job
      </Typography>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        className={classes.stepper}
      >
        <Step>
          <StepLabel>Customer</StepLabel>
          <StepContent>
            <CustomerStep
              {...{ tenantKey, disabled, defaultCustomerId: form.customerId }}
              onComplete={handleCompleteCustomerStep}
            />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Site and contact</StepLabel>
          <StepContent>
            <SiteAndContactStep
              {...{ disabled, site: form.site, contact: form.contact }}
              onComplete={handleCompleteSiteAndContactStep}
              onBack={handleBack}
            />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Job details</StepLabel>
          <StepContent>
            <DetailStep
              disabled={disabled}
              onBack={handleBack}
              onComplete={handleCompleteDetail}
            />
          </StepContent>
        </Step>
      </Stepper>
      {/* <pre>{JSON.stringify(form, null, 2)}</pre> */}
    </Container>
  );
};

export default NewPage;
