import { createClient, cacheExchange, fetchExchange } from "urql";
import auth from "./Auth.js";

export const apiEndpoint = `${process.env.REACT_APP_API_ENDPOINT}${
  process.env.REACT_APP_API_ENDPOINT.endsWith("/") ? "" : "/"
}`;

const client = createClient({
  url: `${apiEndpoint}graphql`,
  fetchOptions: () => {
    const token = auth.getToken();
    return {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
        "client-name": process.env.REACT_APP_CLIENT_NAME,
        "client-version": process.env.REACT_APP_VERSION,
      },
    };
  },
  exchanges: [cacheExchange, fetchExchange],
});

export default client;
