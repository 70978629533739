import React from "react";
import { Form } from "react-final-form";
import { Button, LinearProgress } from "@mui/material";
import FormFields from "./FormFields";
import arrayMutators from "final-form-arrays";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const NewDialog = ({
  materialId: id,
  tenantKey,
  open,
  onClose,
  onSubmit,
  fetching
}) => {
  const [initialFromValues] = React.useState({
    name: "",
    variants: [
      {
        name: "",
        unitCost: null
      }
    ]
  });

  return (
    <Dialog
      open={open}
      scroll="paper"
      fullWidth
      onClose={onClose}
      aria-labelledby="form-dialog-title">
      <>
        <Form
          initialValues={initialFromValues}
          mutators={{
            ...arrayMutators
          }}
          onSubmit={onSubmit}
          render={({ handleSubmit, pristine, form }) => (
            <>
              <form onSubmit={handleSubmit}>
                <DialogTitle id="form-dialog-title">
                  Add new material
                </DialogTitle>
                <DialogContent dividers>
                  {fetching && <LinearProgress variant="indeterminate" />}
                  <FormFields disabled={fetching} />
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={pristine || fetching}
                  >
                    Create material
                  </Button>
                </DialogActions>
              </form>
            </>
          )}
        />
      </>
    </Dialog>
  );
};

export default NewDialog;
