import React from "react";
import { Link } from "@mui/material";

const JobSiteReadBody = ({ site }) => {
  const addressLines = [
    site.address.line1,
    site.address.line2,
    site.address.townCity,
    site.address.county,
    site.address.postCode
  ].filter(x => Boolean(x));

  return (
    <>
      <div style={{ marginBottom: "16px" }}>
        <b>{site.name}</b>
      </div>
      <p>
        {addressLines.map((x, idx) => (
          <span key={idx}>
            {x}
            <br />
          </span>
        ))}
      </p>
      <p>
        <Link
          target="_blank"
          href={`https://maps.google.com/?q=${addressLines.join(", ")}`}
        >
          View in Google maps
        </Link>
      </p>
    </>
  );
};

export default JobSiteReadBody;
