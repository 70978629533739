const LOGGED_OUT_PATH = "/";

const getToken = () => {
  return localStorage.getItem("id_token");
};

const setSession = authResult => {
  let expiresAt = JSON.stringify(
    authResult.expiresIn * 1000 + new Date().getTime()
  );

  localStorage.setItem("id_token", authResult.idToken);
  localStorage.setItem("expires_at", expiresAt);
  localStorage.setItem("user_id", authResult.userId);
  localStorage.setItem("user", JSON.stringify(authResult.user));
  // localStorage.setItem("account", JSON.stringify(authResult.account));
};

const logout = continueTo => {
  // Clear Access Token and ID Token from local storage
  localStorage.removeItem("id_token");
  localStorage.removeItem("expires_at");
  localStorage.removeItem("user_id");
  localStorage.removeItem("user");
  // localStorage.removeItem("account");

  // navigate to the home route
  const newPath = continueTo || LOGGED_OUT_PATH;
  // history.replace(newPath);
  window.location.replace(`${window.location.origin}${newPath}`);
};

const isAuthenticated = () => {
  const token = localStorage.getItem("id_token");
  let expiresAt = JSON.parse(localStorage.getItem("expires_at"));

  return token && token !== "undefined" && new Date().getTime() < expiresAt;
};

//   const isAuthorized = (requiredRoles)=> {
//     if (!requiredRoles) return true;

//     const context = status();
//     var userRole = (context.account && context.account.role) || "";

//     if (typeof requiredRoles === "string" && requiredRoles === userRole)
//       return true;
//     if (requiredRoles.includes(userRole)) return true;
//     return false;
//   }

const status = () => {
  const value = {
    isAuthenticated: isAuthenticated(),
    userId: localStorage.getItem("user_id"),
    user: JSON.parse(localStorage.getItem("user"))
    // account: JSON.parse(localStorage.getItem("account"))
  };

  return value;
};

const Auth = {
  getToken,
  setSession,
  logout,
  isAuthenticated,
  status
};

export default Auth;
