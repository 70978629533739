import React from "react";
import { Chip, Avatar } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  marginRight: { marginRight: theme.spacing(1) },
  noEngineersContainer: {
    color: theme.palette.warning.dark
  }
}));

const JobEngineersReadBody = ({ assignedEngineers }) => {
  const classes = useStyles();

  return (
    <>
      {assignedEngineers.totalCount === 0 && (
        <div className={classes.noEngineersContainer}>
          <em>No one is assigned to this job</em>
        </div>
      )}
      {assignedEngineers.totalCount > 0 &&
        assignedEngineers.edges.map(({ cursor, node }) => (
          <Chip
            key={cursor}
            avatar={<Avatar alt={node.fullName} />}
            label={node.fullName}
            className={classes.marginRight}
          />
        ))}
    </>
  );
};

export default JobEngineersReadBody;
