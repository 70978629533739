import React from "react";

import gql from "graphql-tag";
import { useQuery } from "urql";
import { Form } from "react-final-form";
import { Button, LinearProgress } from "@mui/material";
import FormFields from "./FormFields";
import EditDialogSitesDialogContent from "./EditDialogSitesDialogContent";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const QUERY = gql`
  query CustomerNode($id: ID!) {
    node(id: $id) {
      id
      ... on CustomerType {
        name
        status
        address {
          line1
          line2
          townCity
          county
          postCode
        }
        contact {
          name
          emailAddress
          telephone {
            phone
            label
          }
        }
        sites {
          totalCount
          edges {
            cursor
            node {
              id
              name
              address {
                line1
                line2
                townCity
                county
                postCode
              }
              contact {
                name
                emailAddress
                telephone {
                  phone
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`;

const EditDialog = ({
  customerId: id,
  tenantKey,
  open,
  onClose,
  onSubmit,
  fetching
}) => {
  const [result] = useQuery({
    query: QUERY,
    variables: { id },
    pause: !id,
    requestPolicy: "cache-and-network"
  });
  const [initialFromValues, setInitialFromValues] = React.useState({});

  React.useEffect(() => {
    const node = result.data?.node;

    if (!node) return;

    setInitialFromValues({
      name: node.name,
      address: {
        line1: node.address.line1,
        line2: node.address.line2,
        townCity: node.address.townCity,
        county: node.address.county,
        postCode: node.address.postCode
      },
      contact: {
        name: node.contact.name,
        emailAddress: node.contact.emailAddress,
        telephone: node.contact.telephone.map(({ phone, label }) => ({
          phone,
          label
        }))
      }
    });
  }, [result]);

  const node = result.data?.node;

  return (
    <Dialog
      open={open}
      scroll="paper"
      fullWidth
      onClose={onClose}
      aria-labelledby="form-dialog-title">
      {result.fetching && (
        <>
          <DialogTitle id="form-dialog-title">Edit customer</DialogTitle>
          <DialogContent>
            <LinearProgress variant="indeterminate" />
          </DialogContent>
        </>
      )}
      {!result.fetching && !node && (
        <>
          <DialogTitle id="form-dialog-title">Edit customer</DialogTitle>
          <DialogContent>Customer not found</DialogContent>
        </>
      )}
      {!result.fetching && node && (
        <>
          <Form
            initialValues={initialFromValues}
            onSubmit={onSubmit}
            render={({ handleSubmit, pristine }) => (
              <form onSubmit={handleSubmit}>
                <DialogTitle id="form-dialog-title">
                  Edit customer - {node.name}
                </DialogTitle>
                <DialogContent dividers>
                  <FormFields disabled={fetching} />
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} color="primary">
                    {pristine ? "Close" : "Close and discard changes"}
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={pristine || fetching}
                  >
                    Save changes
                  </Button>
                </DialogActions>
                <EditDialogSitesDialogContent
                  {...{
                    customerId: id,
                    tenantKey,
                    sites: node.sites
                  }}
                />
              </form>
            )}
          />
        </>
      )}
    </Dialog>
  );
};

export default EditDialog;
