import React from "react";
import gql from "graphql-tag";
import { useMutation } from "urql";
import { Form } from "react-final-form";
import TextField from "../../Fields/TextField";
import { Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AddressFormFields from "../../AddressFormFields";

const useStyles = makeStyles(theme => ({
  "mt-6": {
    marginTop: theme.spacing(6)
  },
  "m-2": {
    margin: theme.spacing(2)
  },
  "mb-2": {
    marginBottom: theme.spacing(2)
  },
  "mt-2": {
    marginTop: theme.spacing(2)
  },
  "mr-2": {
    marginRight: theme.spacing(2)
  }
}));

const CHANGE_SITE = gql`
  mutation ChangeSite(
    $tenantKey: ID!
    $id: ID!
    $input: Tenant_Job_ChangeSite_InputType!
  ) {
    tenant(key: $tenantKey) {
      jobs {
        changeSite(id: $id, input: $input) {
          job {
            id
            site {
              name
              address {
                line1
                line2
                townCity
                county
                postCode
              }
            }
          }
        }
      }
    }
  }
`;

const JobSite = ({ editable, tenantKey, jobId, site, onCancel, onChanged }) => {
  const classes = useStyles();
  const [initialFromValues] = React.useState({
    name: site.name,
    address: {
      line1: site.address.line1,
      line2: site.address.line2,
      townCity: site.address.townCity,
      county: site.address.county,
      postCode: site.address.postCode
    }
  });

  const [{ fetching }, executeChangeSite] = useMutation(CHANGE_SITE);

  const handleSaveChanges = form => {
    const values = {
      tenantKey,
      id: jobId,
      input: { site: { ...form } }
    };
    executeChangeSite(values).then(() => {
      onChanged();
    });
  };

  const disabled = fetching;

  return (
    <Form
      initialValues={initialFromValues}
      onSubmit={handleSaveChanges}
      render={({ handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit}>
          <TextField
            name="name"
            label="Site name"
            required
            disabled={disabled}
            fullWidth
            className={classes["mb-2"]}
          />
          <AddressFormFields disabled={disabled} />
          <div className={classes.actions}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={fetching}
              className={classes["mr-2"]}
            >
              Save site details
            </Button>
            <Button onClick={onCancel} disabled={fetching}>
              Cancel
            </Button>
          </div>
        </form>
      )}
    />
  );
};

export default JobSite;
