import React from "react";
import clsx from "clsx";
import { IconButton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {
  CalendarToday as ScheduleIcon,
  Edit as EditIcon,
} from "@mui/icons-material";

import JobScheduleReadBody from "./JobScheduleReadBody";
import JobScheduleEditBody from "./JobScheduleEditBody";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: theme.spacing(2),
    alignItems: "top",
    padding: theme.spacing(1),
    marginLeft: -theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  rootInEdit: {
    background: theme.palette.action.focus,
  },
  iconContainer: {
    flex: "0 0 auto",
    marginRight: theme.spacing(2),
  },
  bodyContainer: {
    flex: "1 1 auto",
  },
  actionContainer: { flex: "0 0 auto" },
}));

const JobSchedule = ({
  reschedulable,
  tenantKey,
  jobId,
  startDate,
  endDate,
}) => {
  const classes = useStyles();
  const [editMode, setEditMode] = React.useState(false);

  const handleEnterEditMode = () => {
    setEditMode(true);
  };

  const handleCanelEditMode = () => {
    setEditMode(false);
  };
  const handleRescheduled = () => {
    setEditMode(false);
  };

  return (
    <div className={clsx(classes.root, { [classes.rootInEdit]: editMode })}>
      <div className={classes.iconContainer}>
        <ScheduleIcon />
      </div>
      <div className={classes.bodyContainer}>
        {editMode ? (
          <JobScheduleEditBody
            {...{ tenantKey, jobId, startDate, endDate }}
            onCancel={handleCanelEditMode}
            onRescheduled={handleRescheduled}
          />
        ) : (
          <JobScheduleReadBody {...{ startDate, endDate }} />
        )}
      </div>
      {reschedulable && !editMode && (
        <div className={classes.actionContainer}>
          <IconButton aria-label="edit" onClick={handleEnterEditMode} size="large">
            <EditIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default JobSchedule;
