import React from "react";
import dayjs from "dayjs";
import clsx from "clsx";
import { Tooltip } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { formatDuration } from "../../../formatDuration";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    // alignItems: "top",
  },
  dayContainer: {
    ...theme.typography.subtitle1,
    flex: "1 1 auto",
    flexBasis: "40px",
    flexGrow: 1,
    textAlign: "center",
    lineHeight: 1,
    padding: theme.spacing(1),
  },
  dot: {
    height: theme.spacing(1),
    background: theme.palette.secondary.main,
    borderRadius: theme.spacing(0.5),
    minWidth: theme.spacing(1),
    display: "inline-block",
  },
  timeBooked: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
    padding: `2px`,
    borderRadius: theme.spacing(0.5),
    fontSize: "small",
  },
  noTimeBooked: {
    background: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  someTimeBooked: {
    background: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
  todayDayContainer: {
    border: `1px solid ${theme.palette.secondary.light}`,
  },
}));

const CalendarDay = ({ firstDate, jobs, bookedTime }) => {
  const classes = useStyles();

  const firstDateJs = dayjs(firstDate);

  return (
    <div className={classes.root}>
      {[0, 1, 2, 3, 4, 5, 6]
        .map((day) => firstDateJs.add(day, "day"))
        .map((date) => {
          const hasJobs =
            jobs.length > 0 &&
            jobs.some(({ node }) =>
              date.isBetween(node.startDate, node.endDate, "day", "[")
            );
          const bookedOnThisDay =
            bookedTime.find((x) => date.isSame(x.date, "day"))?.totalTime || 0;
          return (
            <div
              key={date.format()}
              className={clsx(classes.dayContainer, {
                [classes.todayDayContainer]: date.isSame(dayjs(), "day"),
              })}
            >
              <div>{date.format("ddd")}</div>
              <div>
                <b>{date.format("DD")}</b>
              </div>
              <div>
                <Tooltip title="Total time booked">
                  <div
                    className={clsx(classes.timeBooked, {
                      [classes.someTimeBooked]: bookedOnThisDay > 0,
                      [classes.noTimeBooked]: bookedOnThisDay === 0,
                    })}
                  >
                    {formatDuration(bookedOnThisDay)}
                  </div>
                </Tooltip>
              </div>
              {hasJobs && (
                <div>
                  <div className={classes.dot}></div>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default CalendarDay;
