import React from "react";
import gql from "graphql-tag";
import { useMutation } from "urql";
import { Form } from "react-final-form";
import { Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ContactFormFields from "../../ContactFormFields";

const useStyles = makeStyles(theme => ({
  "mt-6": {
    marginTop: theme.spacing(6)
  },
  "m-2": {
    margin: theme.spacing(2)
  },
  "mb-2": {
    marginBottom: theme.spacing(2)
  },
  "mt-2": {
    marginTop: theme.spacing(2)
  },
  "mr-2": {
    marginRight: theme.spacing(2)
  }
}));

const CHANGE_CONTACT = gql`
  mutation ChangeContact(
    $tenantKey: ID!
    $id: ID!
    $input: Tenant_Job_ChangeContact_InputType!
  ) {
    tenant(key: $tenantKey) {
      jobs {
        changeContact(id: $id, input: $input) {
          job {
            id
            contact {
              name
              emailAddress
              telephone {
                phone
                label
              }
            }
          }
        }
      }
    }
  }
`;

const JobContactEditBody = ({
  tenantKey,
  jobId,
  contact,
  onCancel,
  onChanged
}) => {
  const classes = useStyles();

  const [initialFromValues] = React.useState({
    name: contact.name,
    emailAddress: contact.emailAddress,
    telephone: contact.telephone.map(({ phone, label }) => ({ phone, label }))
  });

  const [{ fetching }, executeChangeContact] = useMutation(CHANGE_CONTACT);

  const handleSaveChanges = form => {
    const values = {
      tenantKey,
      id: jobId,
      input: { contact: { ...form } }
    };
    executeChangeContact(values).then(() => {
      onChanged();
    });
  };

  const disabled = fetching;

  return (
    <Form
      initialValues={initialFromValues}
      onSubmit={handleSaveChanges}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <ContactFormFields disabled={disabled} />
          <div className={classes.actions}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={fetching}
              className={classes["mr-2"]}
            >
              Save contact details
            </Button>
            <Button onClick={onCancel} disabled={fetching}>
              Cancel
            </Button>
          </div>
        </form>
      )}
    />
  );
};

export default JobContactEditBody;
