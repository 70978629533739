import React from "react";
import { Route, Routes } from "react-router-dom";

import RequiresAuth from "./RequiresAuth";

import Tenant from "./Tenant";
import TenantSelection from "./TenantSelection";

import SignIn from "./SignIn/Page";

const AppRoutes = () => (
  <Routes>
    <Route path="/login" element={<SignIn />} />
    <Route path="/">
      <Route
        index
        element={
          <RequiresAuth>
            <TenantSelection />
          </RequiresAuth>
        }
      />
      <Route
        path=":tenantKey/*"
        element={
          <RequiresAuth>
            <Tenant />
          </RequiresAuth>
        }
      />
    </Route>

    <Route element={<NoRoutePage />} />
  </Routes>
);
export default AppRoutes;

const NoRoutePage = () => {
  return <h1>404 - these aren't the jobs you're looking for</h1>;
};
