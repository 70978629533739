import React from "react";
import gql from "graphql-tag";
import { useMutation } from "urql";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Attachment as AttachmentsIcon } from "@mui/icons-material";
import JobAttachmentUpload from "../../JobAttachmentUpload";
import JobAttachmentRow from "./JobAttachmentRow";

const REMOVE_MUTATION = gql`
  mutation RemoveJobAttachment(
    $tenantKey: ID!
    $jobId: ID!
    $attachmentId: ID!
  ) {
    tenant(key: $tenantKey) {
      jobs {
        removeAttachment(id: $jobId, attachmentId: $attachmentId) {
          job {
            id
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "top",
    padding: theme.spacing(1),
    marginLeft: -theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  iconContainer: {
    flex: "0 0 auto",
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  bodyContainer: {
    flex: "1 1 auto",
    paddingTop: theme.spacing(1),
  },
  actionContainer: { flex: "0 0 auto" },
  "mb-2": { marginBottom: theme.spacing(2) },
  attachmentRoot: {
    marginBottom: theme.spacing(2),
  },
  uploadContainer: { margin: `${theme.spacing(1)} 0` },
}));

const JobAttachments = ({ tenantKey, jobId, attachments, editable }) => {
  const classes = useStyles();
  const [removeAttachment, setRemoveAttachment] = React.useState(null);
  const [, executeMutation] = useMutation(REMOVE_MUTATION);

  const handleRemoveClick = (attachmentId) => {
    setRemoveAttachment(attachmentId);
  };

  const handleCancelRemove = () => {
    setRemoveAttachment(null);
  };

  const handleConfirmRemoveClick = () => {
    executeMutation({
      tenantKey,
      jobId,
      attachmentId: removeAttachment,
    }).then(({ data, error }) => {
      if (!error) setRemoveAttachment(null);
    });
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.iconContainer}>
          <AttachmentsIcon />
        </div>
        <div className={classes.bodyContainer}>
          <div className={classes["mb-2"]}>
            <>Attachments</>
          </div>
          <div>
            <div className={classes.uploadContainer}>
              <JobAttachmentUpload tenantKey={tenantKey} jobId={jobId} />
            </div>
            <div>
              {attachments.totalCount === 0 && (
                <p>
                  <em>No attachments yet</em>
                </p>
              )}
              {attachments.edges.map(({ cursor, node }) => (
                <JobAttachmentRow
                  attachment={node}
                  key={cursor}
                  editable={editable}
                  onRemoveClick={handleRemoveClick}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={Boolean(removeAttachment)}
        onClose={handleCancelRemove}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Remove attachment</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to remove an attachment.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelRemove} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmRemoveClick} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default JobAttachments;
