import React from "react";
import dayjs from "dayjs";
import { useTenantKey } from "../../useTenantKey";
import { Paper, Typography, Button, LinearProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import gql from "graphql-tag";
import { useQuery } from "urql";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import { useCSVDownloader } from "react-papaparse";

const PAGE_SIZE = 1000;

const QUERY = gql`
  query TimeslipsQuery(
    $tenantKey: ID!
    $first: Int!
    $low: DateTimeOffset!
    $high: DateTimeOffset!
  ) {
    tenant(key: $tenantKey) {
      timeslips {
        all(
          first: $first
          filter: { dateBetween: { low: $low, high: $high } }
        ) {
          totalCount
          pageInfo {
            hasNextPage
          }
          edges {
            cursor
            node {
              id
              date
              user {
                fullName
              }
              overtime
              startTime
              siteArrivalTime
              siteDepartureTime
              finishTime
              timeOnSite
              travelTime
              vehicleRegistration
              vehicleStartOdometerReading
              vehicleFinishOdometerReading
              job {
                title
                customer {
                  name
                }
                site {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

const csvHeaders = [
  "User",
  "Date",
  "Job",
  "Customer",
  "Site",
  "Overtime",
  "Start time",
  "Site arrival time",
  "Site departure time",
  "Finish time",
  "Total hours",
  "Travel time",
  "Vehicle reg.",
  "starting mileage",
  "End mileage",
];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    flex: "1 1 auto",
  },
  titleButtonLeft: { marginRight: theme.spacing(2) },
  kpiRoot: {
    display: "flex",
    flexWrap: "wrap",
  },
  picker: { marginRight: theme.spacing(2) },
  fetchContainer: { marginBottom: theme.spacing(2) },
}));

const TimeslipsCard = () => {
  const classes = useStyles();
  const { tenantKey } = useTenantKey();
  const [pauseQuery, setPauseQuery] = React.useState(true);
  const [dateRange, setDateRange] = React.useState({
    low: dayjs().startOf("week").subtract(7, "days").format(),
    high: dayjs().startOf("week").subtract(7, "days").endOf("week").format(),
  });
  // const adminUrls = useAdminUrls();
  // const { tenantKey } = useTenantKey();
  const { CSVDownloader, Type } = useCSVDownloader();

  const [{ fetching, data }] = useQuery({
    query: QUERY,
    variables: {
      tenantKey,
      first: PAGE_SIZE,
      low: dateRange?.low,
      high: dateRange?.high,
    },
    pause: Boolean(!dateRange || pauseQuery),
    requestPolicy: "cache-and-network",
  });

  const csvData = React.useMemo(() => {
    const edges = data?.tenant.timeslips.all.edges || [];

    const newData = (edges || []).map(({ node }) => [
      node.user.fullName,
      dayjs(node.date).format("L"),
      node.job.title,
      node.job.customer.name,
      node.job.site.name,
      node.overtime,
      dayjs(node.startTime).format("LT"),
      dayjs(node.siteArrivalTime).format("LT"),
      dayjs(node.siteDepartureTime).format("LT"),
      dayjs(node.finishTime).format("LT"),
      Number((node.timeOnSite + node.travelTime) / 60 / 60).toFixed(2),
      Number(node.travelTime / 60 / 60).toFixed(2),
      node.vehicleRegistration,
      node.vehicleStartOdometerReading,
      node.vehicleFinishOdometerReading,
    ]);

    return [csvHeaders, ...newData];
  }, [data]);

  const handleSetDateRange = (value) => {
    setPauseQuery(true);
    setDateRange(!value ? null : { low: value[0], high: value[1] });
  };

  const handleDownload = () => {
    setPauseQuery(false);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Typography
          variant="h5"
          gutterBottom
          className={classes.titleContainer}
        >
          <div className={classes.title}>Timeslips</div>
        </Typography>
        <div className={classes.fetchContainer}>
          <DateRangePicker
            className={classes.picker}
            value={dateRange ? [dateRange.low, dateRange.high] : null}
            onChange={handleSetDateRange}
          />
          <Button onClick={handleDownload} disabled={fetching || !dateRange}>
            Fetch timeslips
          </Button>
        </div>
        {fetching && <LinearProgress variant="query" />}
        {!fetching && data && !pauseQuery && (
          <div>
            <p>
              <Button
                variant="contained"
                component={CSVDownloader}
                type={Type.Button}
                filename={"timeslips"}
                bom={true}
                config={{
                  delimiter: ",",
                }}
                data={csvData}
              >
                Timeslips are ready, click to download
              </Button>
            </p>
            {data?.tenant.timeslips.all.pageInfo.hasNextPage && (
              <div>
                <em>
                  Only the latest{" "}
                  <b>
                    {PAGE_SIZE} of {data?.tenant.timeslips.all.totalCount}
                  </b>{" "}
                  timeslips have been included. Reduce the time frame to include
                  all records.
                </em>
              </div>
            )}
          </div>
        )}
      </Paper>
    </>
  );
};

export default TimeslipsCard;
