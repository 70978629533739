import React from "react";
import clsx from "clsx";
import { IconButton } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import {
  ContactPhone as ContactIcon,
  Edit as EditIcon,
} from "@mui/icons-material";

import JobContactReadBody from "./JobContactReadBody";
import JobContactEditBody from "./JobContactEditBody";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: theme.spacing(2),
    alignItems: "top",
    padding: theme.spacing(1),
    marginLeft: -theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  rootInEdit: {
    background: theme.palette.action.focus,
  },
  iconContainer: {
    flex: "0 0 auto",
    marginRight: theme.spacing(2),
  },
  bodyContainer: {
    flex: "1 1 auto",
  },
  actionContainer: { flex: "0 0 auto" },
  "mt-6": {
    marginTop: theme.spacing(6),
  },
  "m-2": {
    margin: theme.spacing(2),
  },
  "mb-2": {
    marginBottom: theme.spacing(2),
  },
  "mt-2": {
    marginTop: theme.spacing(2),
  },
}));

const JobContact = ({ editable, tenantKey, jobId, contact }) => {
  const classes = useStyles();
  const [editMode, setEditMode] = React.useState(false);

  const handleEnterEditMode = () => {
    setEditMode(true);
  };

  const handleCanelEditMode = () => {
    setEditMode(false);
  };

  const handleChanged = () => {
    setEditMode(false);
  };

  return (
    <div className={clsx(classes.root, { [classes.rootInEdit]: editMode })}>
      <div className={classes.iconContainer}>
        <ContactIcon />
      </div>
      <div className={classes.bodyContainer}>
        {editMode ? (
          <JobContactEditBody
            {...{ tenantKey, jobId, contact }}
            onCancel={handleCanelEditMode}
            onChanged={handleChanged}
          />
        ) : (
          <JobContactReadBody {...{ contact }} />
        )}
      </div>
      {editable && !editMode && (
        <div className={classes.actionContainer}>
          <IconButton aria-label="edit" onClick={handleEnterEditMode} size="large">
            <EditIcon />
          </IconButton>
        </div>
      )}
    </div>
  );

  // return (
  //   <Card>
  //     <Form
  //       initialValues={initialFromValues}
  //       onSubmit={handleSaveChanges}
  //       render={({ handleSubmit, pristine }) => (
  //         <form onSubmit={handleSubmit}>
  //           <CardHeader
  //             avatar={<ContactIcon />}
  //             title="Contact"
  //             action={
  //               !editable || editMode ? null : (
  //                 <IconButton aria-label="edit" onClick={handleEnterEditMode}>
  //                   <EditIcon />
  //                 </IconButton>
  //               )
  //             }
  //           />
  //           <CardContent>
  //             {editMode ? (
  //               <div>
  //                 <TextField
  //                   name="contact.name"
  //                   label="Name"
  //                   required
  //                   disabled={disabled}
  //                   fullWidth
  //                   className={classes["mb-2"]}
  //                 />
  //                 <TextField
  //                   name="contact.emailAddress"
  //                   type="email"
  //                   label="Email address"
  //                   placeholder="contact@example.com"
  //                   required
  //                   disabled={disabled}
  //                   fullWidth
  //                   className={classes["mb-2"]}
  //                 />
  //                 <TextField
  //                   name="contact.telephone[0].phone"
  //                   type="tel"
  //                   label="Phone number"
  //                   required
  //                   disabled={disabled}
  //                   fullWidth
  //                   className={classes["mb-2"]}
  //                 />
  //                 <TextField
  //                   name="contact.telephone[0].label"
  //                   label="Label"
  //                   required
  //                   disabled={disabled}
  //                   fullWidth
  //                   className={classes["mb-2"]}
  //                 />
  //               </div>
  //             ) : (
  //               <>
  //                 <p>
  //                   Name: <b>{contact.name}</b>
  //                 </p>
  //                 <p>
  //                   Email:{" "}
  //                   <b>
  //                     <a href={`mailto:${contact.emailAddress}`}>
  //                       {contact.emailAddress}
  //                     </a>
  //                   </b>
  //                 </p>
  //                 {contact.telephone.map((tel, idx) => (
  //                   <p key={`tel-${idx}`}>
  //                     {tel.label}:{" "}
  //                     <b>
  //                       <a href={`tel:${tel.phone}`}>{tel.phone}</a>
  //                     </b>
  //                   </p>
  //                 ))}
  //               </>
  //             )}
  //           </CardContent>
  //           {editMode && (
  //             <CardActions disableSpacing>
  //               <Button
  //                 size="small"
  //                 color="primary"
  //                 aria-label="save change to contact"
  //                 type="submit"
  //                 disabled={pristine || fetching}
  //               >
  //                 Save contact details
  //               </Button>
  //               <Button
  //                 size="small"
  //                 color="primary"
  //                 onClick={handleCanelEditMode}
  //                 disabled={fetching}
  //               >
  //                 Cancel
  //               </Button>
  //             </CardActions>
  //           )}
  //         </form>
  //       )}
  //     />
  //   </Card>
  // );
};

export default JobContact;
