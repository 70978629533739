import React from "react";
import gql from "graphql-tag";
import { useMutation } from "urql";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Form } from "react-final-form";
import TextField from "../../Fields/TextField";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import CheckboxGroup from "./CheckboxGroup";

const COMPLETE_JOB_MUTATION = gql`
  mutation CompleteJob(
    $tenantKey: ID!
    $jobId: ID!
    $input: Tenant_Job_Complete_InputType!
  ) {
    tenant(key: $tenantKey) {
      jobs {
        complete(id: $jobId, input: $input) {
          job {
            id
            status
          }
        }
      }
    }
  }
`;

const checklistOptions = [
  "Testing complete and results saved",
  "Outlet(s) labelled - including cabinet identity",
  "Site left clean and tidy",
  "Site checked for materials and tools before leaving",
  "Site Contact informed and completed walkthrough",
  "All firestop/gas seals complete",
];

const CompleteJobDialog = ({
  tenantKey,
  jobId,
  open,
  onCompleted,
  onCancel,
}) => {
  const [{ fetching }, executeMutation] = useMutation(COMPLETE_JOB_MUTATION);

  const [initialFromValues] = React.useState({
    notes: "",
    checklist: [],
  });

  const onSubmit = ({ checklist, notes }) => {
    const variables = {
      tenantKey,
      jobId,
      input: {
        notes,
        checklist: checklist.map((name) => ({ name, checked: true })),
      },
    };
    executeMutation(variables).then(({ error }) => {
      error && console.error(error);
      if (!error) onCompleted();
    });
  };

  return (
    <>
      <Dialog open={open} fullWidth fullScreen>
        <Form
          initialValues={initialFromValues}
          mutators={{
            ...arrayMutators,
          }}
          validate={(values) => {
            const errors = {};

            if (values.checklist.length !== checklistOptions.length) {
              errors.checklist = "All items must be complete";
            }

            return errors;
          }}
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle id="form-dialog-title">Complete job</DialogTitle>
              <DialogContent>
                {/* <code>{JSON.stringify(values, null, 2)}</code> */}
                <TextField
                  name="notes"
                  disabled={fetching}
                  multiline
                  maxRows={20}
                  fullWidth
                  label="Notes"
                />
                <div>
                  <FieldArray
                    name="checklist"
                    component={CheckboxGroup}
                    options={checklistOptions}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={onCancel} color="primary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={submitting || fetching}
                >
                  Complete job
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

export default CompleteJobDialog;
