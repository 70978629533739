import React from "react";
import dayjs from "dayjs";
import gql from "graphql-tag";
import { useQuery } from "urql";
import {
  LinearProgress,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
} from "@mui/material";
import { useCSVDownloader } from "react-papaparse";
import { formatDuration } from "../../formatDuration";

const QUERY = gql`
  query JobNode($id: ID!) {
    node(id: $id) {
      id
      ... on Tenant_Job_JobType {
        title
        customer {
          name
        }
        site {
          name
        }
        timeslips {
          edges {
            cursor
            node {
              date
              user {
                fullName
              }
              overtime
              startTime
              siteArrivalTime
              siteDepartureTime
              finishTime
              timeOnSite
              travelTime
              vehicleRegistration
              vehicleStartOdometerReading
              vehicleFinishOdometerReading
            }
          }
        }
      }
    }
  }
`;

// const useStyles = makeStyles(theme => ({
//   // tableContainer: {marginBottom}
// }));

const JobTimesheetsTable = ({ jobId }) => {
  // const classes = useStyles();
  const [{ fetching, data }] = useQuery({
    query: QUERY,
    variables: { id: jobId },
    pause: !jobId,
    requestPolicy: "cache-and-network",
  });

  const edges = React.useMemo(() => data?.node.timeslips.edges || [], [data]);

  const { CSVDownloader, Type } = useCSVDownloader();

  const csvData = React.useMemo(() => {
    const rows = (edges || []).map(({ node }) => [
      node.user.fullName,
      dayjs(node.date).format("L"),
      data.node.title,
      data.node.customer.name,
      data.node.site.name,
      node.overtime,
      dayjs(node.startTime).format("LT"),
      dayjs(node.siteArrivalTime).format("LT"),
      dayjs(node.siteDepartureTime).format("LT"),
      dayjs(node.finishTime).format("LT"),
      Number((node.timeOnSite + node.travelTime) / 60 / 60).toFixed(2),
      Number(node.travelTime / 60 / 60).toFixed(2),
      node.vehicleRegistration,
      node.vehicleStartOdometerReading,
      node.vehicleFinishOdometerReading,
    ]);

    return [
      [
        "User",
        "Date",
        "Job",
        "Customer",
        "Site",
        "Overtime",
        "Start time",
        "Site arrival time",
        "Site departure time",
        "Finish time",
        "Total hours",
        "Travel time",
        "Vehicle reg.",
        "Starting mileage",
        "End mileage",
      ],
      ...rows,
    ];
  }, [edges, data]);

  if (fetching) return <LinearProgress variant="indeterminate" />;

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="Job materials table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Day</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Time on site</TableCell>
              <TableCell>Travel time</TableCell>
              <TableCell>OT</TableCell>
              <TableCell>Start time</TableCell>
              <TableCell>Site arrival time</TableCell>
              <TableCell>Site departure time</TableCell>
              <TableCell>Finish time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {edges.map(({ cursor, node }) => (
              <TableRow key={cursor}>
                <TableCell>{dayjs(node.date).format("L")}</TableCell>
                <TableCell>{node.user.fullName}</TableCell>
                <TableCell>{formatDuration(node.timeOnSite)}</TableCell>
                <TableCell>{formatDuration(node.travelTime)}</TableCell>
                <TableCell>{node.overtime && "OT"}</TableCell>
                <TableCell>{dayjs(node.startTime).format("LT")}</TableCell>
                <TableCell>
                  {dayjs(node.siteArrivalTime).format("LT")}
                </TableCell>
                <TableCell>
                  {dayjs(node.siteDepartureTime).format("LT")}
                </TableCell>
                <TableCell>{dayjs(node.finishTime).format("LT")}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={2}>
                <Button
                  variant="text"
                  size="small"
                  color="secondary"
                  component={CSVDownloader}
                  type={Type.Button}
                  filename={"timeslips"}
                  bom={true}
                  data={csvData}
                >
                  Download table
                </Button>
              </TableCell>
              <TableCell>
                {formatDuration(
                  edges.reduce(
                    (agg, { node }) => agg + (node.timeOnSite ?? 0),
                    0
                  )
                )}
              </TableCell>
              <TableCell>
                {formatDuration(
                  edges.reduce(
                    (agg, { node }) => agg + (node.travelTime ?? 0),
                    0
                  )
                )}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default JobTimesheetsTable;
