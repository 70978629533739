import React from "react";
import gql from "graphql-tag";
import { useMutation } from "urql";
import { Form } from "react-final-form";
import TextField from "../../Fields/TextField";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const CHANGE_DESCRIPTION = gql`
  mutation ChangeDescription($tenantKey: ID!, $id: ID!, $description: String!) {
    tenant(key: $tenantKey) {
      jobs {
        changeDescription(id: $id, input: { description: $description }) {
          job {
            id
            description
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  actions: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  btnLeft: {
    marginRight: theme.spacing(2),
  },
  formattingHelpButtonContainer: {
    marginLeft: "auto",
  },
}));

const FormattingHelpDialog = ({ open, onCloseClick }) => (
  <Dialog open={open}>
    <DialogTitle>Formatting help</DialogTitle>
    <DialogContent>
      <DialogContentText>
        The job description uses Markdown for formatting. Here are the basics.
        See the complete syntax.
      </DialogContentText>

      <p>First Level Header</p>
      <code># Making Scrambled Eggs: A Primer</code>

      <p>Second Level Header</p>
      <code>## 1.1: Preparation</code>

      <p>Paragraphs</p>
      <code>
        Add two new lines to start a new paragraph.
        <br />
        <br />
        Crack two eggs into the bowl and whisk.
      </code>

      <p>Bold</p>
      <code>**Carefully** crack the eggs. </code>

      <p>Emphasis</p>
      <code>Whisk the eggs *vigorously*.</code>

      <p>Lists</p>
      <code>
        Ingredients: <br />
        <br />- Eggs <br />- Oil <br />- *Optional:* milk
      </code>
    </DialogContent>
    <DialogActions>
      <Button color="primary" onClick={onCloseClick}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

const JobDescriptionEditBody = ({
  tenantKey,
  jobId,
  description,
  onCancel,
  onChanged,
}) => {
  const classes = useStyles();
  const [helpOpen, setHelpOpen] = React.useState(false);
  const [initialFromValues] = React.useState({
    description,
  });

  const [{ fetching }, executeChangeDescription] =
    useMutation(CHANGE_DESCRIPTION);

  const handleSaveChanges = (form) => {
    const values = {
      tenantKey,
      id: jobId,
      description: form.description,
    };
    executeChangeDescription(values).then(() => {
      onChanged();
    });
  };

  return (
    <>
      <Form
        initialValues={initialFromValues}
        onSubmit={handleSaveChanges}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              name="description"
              required
              disabled={fetching}
              multiline
              maxRows={20}
              fullWidth
              label="Description"
            />
            <div className={classes.actions}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={fetching}
                className={classes.btnLeft}
              >
                Save changes
              </Button>
              <Button onClick={onCancel} disabled={fetching}>
                Cancel
              </Button>
              <div className={classes.formattingHelpButtonContainer}>
                <Button
                  size="small"
                  variant="text"
                  color="info"
                  onClick={() => setHelpOpen(true)}
                >
                  Formatting help
                </Button>
              </div>
            </div>
          </form>
        )}
      />
      <FormattingHelpDialog
        open={helpOpen}
        onCloseClick={() => setHelpOpen(false)}
      />
    </>
  );
};

export default JobDescriptionEditBody;
