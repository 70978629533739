import React from "react";
import clsx from "clsx";
import { Grid, Chip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useEngineerUrls } from "../../../Urls";
import { Link as RouterLink } from "react-router-dom";

import DayJobSchedule from "./DayJobSchedule";

import { green } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    color: theme.palette.text.primary,
    textDecoration: "none",
    "&:hover": {
      background: theme.palette.background.default,
    },
  },
  jobTitleContainer: {
    ...theme.typography.subtitle1,
    display: "flex",
  },
  jobTitleCustomerName: {
    flexGrow: 1,
  },
  customerName: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
  siteName: {
    color: theme.palette.text.hint,
  },
  flexCenter: { display: "flex", alignItems: "center" },
  completedJobChip: {
    borderColor: green[500],
    color: green[500],
  },
}));

const DayJob = ({ date, job, className }) => {
  const classes = useStyles();
  const engineerUrls = useEngineerUrls();

  return (
    <RouterLink
      className={clsx(className, classes.root)}
      to={engineerUrls.job(job.id)}
    >
      <Grid container spacing={2}>
        <Grid item xs={2} md={1} className={classes.flexCenter}>
          <div>
            <DayJobSchedule
              date={date}
              startDate={job.startDate}
              endDate={job.endDate}
            />
          </div>
        </Grid>
        <Grid item xs={10} md={11}>
          <div className={classes.jobTitleContainer}>
            <div className={classes.jobTitleCustomerName}>
              <span className={classes.customerName}>{job.customer.name}</span>
            </div>
            {job.status === "COMPLETED" && (
              <Chip
                label="Completed"
                className={classes.completedJobChip}
                size="small"
                variant="outlined"
              />
            )}
          </div>
          <div>
            <span className={classes.siteName}>{job.site.name}</span>
          </div>
          <div>
            <small>{job.title}</small>
          </div>
        </Grid>
      </Grid>
    </RouterLink>
  );
};

export default DayJob;
