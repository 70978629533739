import React from "react";
import { Grid, Switch, FormControlLabel } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import TextField from "../../Fields/TextField";
import { Field } from "react-final-form";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  "mt-6": {
    marginTop: theme.spacing(6),
  },
  "m-2": {
    margin: theme.spacing(2),
  },
  "mb-2": {
    marginBottom: theme.spacing(2),
  },
  "mt-2": {
    marginTop: theme.spacing(2),
  },
  variantsRoot: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  variantName: {
    flex: "1 1 auto",
  },
  variantUnitCost: {
    marginLeft: theme.spacing(2),
    width: "100px",
  },
  variantActions: {
    marginLeft: theme.spacing(2),
  },
}));

const FormLayout = ({ disabled, password }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="name"
            placeholder="Joe Bloggs"
            label="Name"
            required
            fullWidth
            disabled={disabled}
            className={classes["mb-2"]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="emailAddress"
            type="email"
            placeholder="joe@example.com"
            label="Email"
            required
            fullWidth
            disabled={disabled}
            className={classes["mb-2"]}
          />
        </Grid>
        {password && (
          <Grid item xs={12} sm={6}>
            <TextField
              name="password"
              type="password"
              label="Password"
              required
              fullWidth
              disabled={disabled}
              className={classes["mb-2"]}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <Field name="isAdmin" type="checkbox">
            {({ input, meta }) => (
              <FormControlLabel
                label="Administrator"
                className={classes["mb-2"]}
                control={
                  <>
                    <Switch {...input} defaultChecked={input} />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </>
                }
              />
            )}
          </Field>
        </Grid>
      </Grid>
    </>
  );
};

export default FormLayout;
