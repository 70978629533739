import React from "react";
import clsx from "clsx";
import gql from "graphql-tag";
import { useMutation } from "urql";
import { useTenantKey } from "../../useTenantKey";
import {
  Container,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  Link,
  CircularProgress,
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const ACCEPT_RISK_ASSESSMENT_MUTATION = gql`
  mutation AcceptJobRiskAssessment($tenantKey: ID!, $jobId: ID!) {
    tenant(key: $tenantKey) {
      jobs {
        rams {
          accept(id: $jobId) {
            job {
              id
              riskAssessment {
                src
                originalFilename
                acceptedByMe
                acceptedBy {
                  timestamp
                  user {
                    id
                    fullName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  marginRight: { marginRight: theme.spacing(2) },
  customerRoot: {
    background: theme.palette.background.default,
    padding: `${theme.spacing(2)} 0`,
    marginBottom: theme.spacing(2),
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
  "mt-2": { marginTop: theme.spacing(2) },
  buttonWrapper: {
    margin: theme.spacing(1),
    position: "relative",
    display: "inline-block",
  },
  button: {},
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  linkContainer: {
    overflow: "auto",
  },
}));

const JobRiskAssessmentAgreement = ({ job }) => {
  const { tenantKey } = useTenantKey();
  const classes = useStyles();
  const [{ fetching }, executeMutation] = useMutation(
    ACCEPT_RISK_ASSESSMENT_MUTATION
  );

  const [agreed, setAgreed] = React.useState(false);

  const handleAgreeChange = (ev) => {
    setAgreed(ev.target.checked);
  };

  const handleConfirmAcceptance = () => {
    return executeMutation({
      tenantKey,
      jobId: job.id,
    });
  };

  return (
    <>
      <div className={clsx(classes.customerRoot)}>
        <Container>
          <Typography variant="h3" gutterBottom>
            {job.title}
          </Typography>
          <Typography variant="h5">
            {job.customer.name} <small>({job.site.name})</small>
          </Typography>
        </Container>
      </div>
      <Container>
        <p>Before commencing any works, you must read the following RAMS:</p>
        <p className={classes.linkContainer}>
          <Link href={job.riskAssessment.src} target="_blank">
            {job.riskAssessment.originalFilename}
          </Link>
        </p>
        <p>
          You <em>must</em> confirm the following:
        </p>

        <ol>
          <li>That you have read and understood the contents of the RAMS.</li>
          <li>
            Anything that you did not understand has been explained to you to
            your satisfaction.
          </li>
          <li>
            That you agree to follow the RAMS and understand that the control
            measures are provided for your safety and the safety of others
          </li>
        </ol>

        <FormControlLabel
          control={<Checkbox checked={agreed} onChange={handleAgreeChange} />}
          label="I agree to the above conditions"
        />
        <div>
          <div className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={fetching || !agreed}
              onClick={handleConfirmAcceptance}
            >
              Accept
            </Button>
            {fetching && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default JobRiskAssessmentAgreement;
