import React from "react";
import clsx from "clsx";
import { IconButton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ChangeHistory as PoIcon, Edit as EditIcon } from "@mui/icons-material";

import JobCustomerPoReadBody from "./JobCustomerPoReadBody";
import JobCustomerPoEditBody from "./JobCustomerPoEditBody";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: theme.spacing(2),
    alignItems: "top",
    padding: theme.spacing(1),
    marginLeft: -theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  rootInEdit: {
    background: theme.palette.action.focus,
  },
  iconContainer: {
    flex: "0 0 auto",
    marginRight: theme.spacing(2),
  },
  bodyContainer: {
    flex: "1 1 auto",
  },
  actionContainer: { flex: "0 0 auto" },
}));

const JobCustomerPo = ({ editable, tenantKey, jobId, po: customerPo }) => {
  const classes = useStyles();
  const [editMode, setEditMode] = React.useState(false);

  const handleEnterEditMode = () => {
    setEditMode(true);
  };

  const handleCanelEditMode = () => {
    setEditMode(false);
  };
  const handleChanged = () => {
    setEditMode(false);
  };

  return (
    <div className={clsx(classes.root, { [classes.rootInEdit]: editMode })}>
      <div className={classes.iconContainer}>
        <PoIcon />
      </div>
      <div className={classes.bodyContainer}>
        {editMode ? (
          <JobCustomerPoEditBody
            {...{ tenantKey, jobId, customerPo }}
            onCancel={handleCanelEditMode}
            onChanged={handleChanged}
          />
        ) : (
          <JobCustomerPoReadBody {...{ customerPo }} />
        )}
      </div>
      {editable && !editMode && (
        <div className={classes.actionContainer}>
          {/* <Button size="small" variant="outline">
            Edit
          </Button> */}
          <IconButton aria-label="edit" onClick={handleEnterEditMode} size="large">
            <EditIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default JobCustomerPo;
