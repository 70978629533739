import React from "react";
import dayjs from "dayjs";
import gql from "graphql-tag";
import { useMutation } from "urql";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Link,
  IconButton,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {
  WorkOutline as RiskAssessmentIcon,
  Clear as RemoveIcon,
} from "@mui/icons-material"; // Rule
import JobRiskAssessmentUpload from "./JobRiskAssessmentUpload";

const REMOVE_MUTATION = gql`
  mutation RemoveJobRiskAssessment($tenantKey: ID!, $jobId: ID!) {
    tenant(key: $tenantKey) {
      jobs {
        rams {
          remove(id: $jobId) {
            job {
              id
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "top",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    marginLeft: -theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  iconContainer: {
    flex: "0 0 auto",
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  bodyContainer: {
    flex: "1 1 auto",
    paddingTop: theme.spacing(1),
  },
  actionContainer: { flex: "0 0 auto" },
  "mb-2": { marginBottom: theme.spacing(2) },
  attachmentRoot: {
    marginBottom: theme.spacing(2),
  },
  uploadContainer: { margin: `${theme.spacing(1)} 0` },
  assessmentContainer: {
    display: "flex",
    alignItems: "center",
  },
  assessmentLink: {
    // flex: "1 1 auto",
  },
  assessmentRemoveContainer: {
    marginLeft: theme.spacing(2),
  },
  hint: {
    color: theme.palette.text.hint,
  },
}));

const JobRiskAssessment = ({ tenantKey, jobId, riskAssessment, editable }) => {
  const classes = useStyles();
  const [removeAttachment, setRemoveAttachment] = React.useState(null);
  const [, executeMutation] = useMutation(REMOVE_MUTATION);

  const handleRemoveClick = () => {
    setRemoveAttachment(true);
  };

  const handleCancelRemove = () => {
    setRemoveAttachment(null);
  };

  const handleConfirmRemoveClick = () => {
    return executeMutation({
      tenantKey,
      jobId,
      attachmentId: removeAttachment,
    }).then(({ data, error }) => {
      if (!error) setRemoveAttachment(null);
    });
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.iconContainer}>
          <RiskAssessmentIcon />
        </div>
        <div className={classes.bodyContainer}>
          <div className={classes["mb-2"]}>
            <>Risk assessment</>
          </div>
          <div>
            {!riskAssessment && (
              <div className={classes.uploadContainer}>
                <JobRiskAssessmentUpload tenantKey={tenantKey} jobId={jobId} />
              </div>
            )}
            {riskAssessment && (
              <div>
                <div className={classes.assessmentContainer}>
                  <div className={classes.assessmentLink}>
                    <Link href={riskAssessment.src} target="_blank">
                      {riskAssessment.originalFilename}
                    </Link>
                  </div>
                  {editable && (
                    <div className={classes.assessmentRemoveContainer}>
                      <IconButton size="small" onClick={handleRemoveClick}>
                        <RemoveIcon />
                      </IconButton>
                    </div>
                  )}
                </div>
                <div>
                  Accepted by:
                  {riskAssessment.acceptedBy.length === 0 && " No one"}
                  <ul>
                    {riskAssessment.acceptedBy.map(({ timestamp, user }) => (
                      <li key={user.id}>
                        {user.fullName}{" "}
                        <span className={classes.hint}>
                          {dayjs(timestamp).format("L LT")}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={Boolean(removeAttachment)}
        onClose={handleCancelRemove}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Remove risk assessment
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to remove the risk assessment.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelRemove} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmRemoveClick} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default JobRiskAssessment;
