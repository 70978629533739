import React from "react";
import gql from "graphql-tag";
import { useQuery } from "urql";
import dayjs from "dayjs";
import { useTheme, Zoom, Fab, LinearProgress } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Add as AddIcon } from "@mui/icons-material";
import AddTimeslipDialog from "./AddTimeslipDialog";
import EditTimeslipDialog from "./EditTimeslipDialog";
import { formatDuration } from "../../formatDuration";

const QUERY = gql`
  query JobTimeslips($tenantKey: ID!, $jobId: ID!) {
    tenant(key: $tenantKey) {
      me {
        timeslips {
          all(filter: { jobId: $jobId }) {
            totalCount
            edges {
              cursor
              node {
                id
                date
                overtime
                siteArrivalTime
                siteDepartureTime
                # startTime
                # finishTime
                timeOnSite
                travelTime
                totalTime
                # notes
              }
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(12),
  },
  fab: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  slipRoot: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  timeContainer: {
    display: "flex",
    alignItems: "center",
  },
  timeLabel: {
    flex: "1 1 auto",
  },
  timeValue: {
    // flex: "1 1 auto"
  },
  dateContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  dateLabel: {
    // flex: "1 1 auto"
  },
  dateValue: {
    ...theme.typography.h5,
    flex: "1 1 auto",
  },
  overtimeLabel: {
    ...theme.typography.caption,
    color: theme.palette.secondary.main,
    padding: `0px ${theme.spacing(1)}`,
    border: `1px solid ${theme.palette.secondary.main}`,
  },
}));

const TimeslipsTabPanel = ({ tenantKey, jobId }) => {
  const classes = useStyles();
  const [addOpen, setAddOpen] = React.useState(false);
  const [editTimeslipId, setEditTimeslipId] = React.useState(null);

  const theme = useTheme();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const [{ fetching, data }, executeQuery] = useQuery({
    query: QUERY,
    variables: { tenantKey, jobId },
    requestPolicy: "cache-and-network",
  });

  const handleTimeslipAdded = () => {
    setAddOpen(false);
    if (data?.tenant.me.timeslips.all.totalCount === 0) {
      // Work around for urql not refetching when no existing records
      executeQuery({ tenantKey, jobId });
    }
  };

  const handleAddClick = () => {
    setAddOpen(true);
  };

  const handleCancelAddClick = () => setAddOpen(false);

  const handleEditClick = (id) => () => setEditTimeslipId(id);

  const handleCancelEdit = () => setEditTimeslipId(null);
  const handleEditDone = () => setEditTimeslipId(null);
  const handleRetracted = () => {
    setEditTimeslipId(null);
    executeQuery({ tenantKey, jobId });
  };

  return (
    <div className={classes.root}>
      {fetching && <LinearProgress variant="indeterminate" />}
      {data && (
        <>
          {data.tenant.me.timeslips.all.totalCount === 0 && (
            <p>You haven't booked any time to this job</p>
          )}
          {data.tenant.me.timeslips.all.edges.map(({ cursor, node }) => (
            <div
              className={classes.slipRoot}
              key={cursor}
              onClick={handleEditClick(node.id)}
            >
              <div className={classes.dateContainer}>
                <div className={classes.dateValue}>
                  {dayjs(node.date).format("LL")}
                </div>
                {node.overtime && (
                  <div className={classes.overtimeLabel}>overtime</div>
                )}
                {/* <div className={classes.dateAction}>
                  <IconButton size="small">
                    <RetractIcon />
                  </IconButton>
                </div> */}
              </div>
              <div className={classes.timeContainer}>
                <div className={classes.timeLabel}>
                  On site{" "}
                  <small>
                    ({dayjs(node.siteArrivalTime).format("LT")} -{" "}
                    {dayjs(node.siteDepartureTime).format("LT")})
                  </small>
                </div>
                <div className={classes.timeValue}>
                  {formatDuration(node.timeOnSite)}
                </div>
              </div>
              <div className={classes.timeContainer}>
                <div className={classes.timeLabel}>Travel time</div>
                <div className={classes.timeValue}>
                  {formatDuration(node.travelTime)}
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      <Zoom
        in={true}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${transitionDuration.exit}ms`,
        }}
        unmountOnExit
      >
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          onClick={handleAddClick}
        >
          <AddIcon />
        </Fab>
      </Zoom>

      <AddTimeslipDialog
        {...{ jobId, tenantKey }}
        open={addOpen}
        onAdded={handleTimeslipAdded}
        onCancel={handleCancelAddClick}
      />
      <EditTimeslipDialog
        open={Boolean(editTimeslipId)}
        tenantKey={tenantKey}
        onCancel={handleCancelEdit}
        onEditied={handleEditDone}
        onRetracted={handleRetracted}
        id={editTimeslipId}
      />
    </div>
  );
};

export default TimeslipsTabPanel;
