import React from "react";
import { Grid, IconButton, Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Delete as RemoveIcon, Add as AddIcon } from "@mui/icons-material";
import TextField from "../../Fields/TextField";
import { FieldArray } from "react-final-form-arrays";

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  "mt-6": {
    marginTop: theme.spacing(6)
  },
  "m-2": {
    margin: theme.spacing(2)
  },
  "mb-2": {
    marginBottom: theme.spacing(2)
  },
  "mt-2": {
    marginTop: theme.spacing(2)
  },
  variantsRoot: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2)
  },
  variantName: {
    flex: "1 1 auto"
  },
  variantUnitCost: {
    marginLeft: theme.spacing(2),
    width: "100px"
  },
  variantActions: {
    marginLeft: theme.spacing(2)
  }
}));

const FormLayout = ({ disabled }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="name"
            placeholder="Material name"
            label="Name"
            required
            fullWidth
            disabled={disabled}
            className={classes["mb-2"]}
          />
        </Grid>
      </Grid>
      <FieldArray name="variants">
        {({ fields }) => (
          <>
            {fields.map((name, index) => (
              <div className={classes.variantsRoot} key={`variant-${index}`}>
                <div className={classes.variantName}>
                  <TextField
                    name={`${name}.name`}
                    label="Variant"
                    required
                    disabled={disabled}
                    fullWidth
                  />
                </div>
                <div className={classes.variantUnitCost}>
                  <TextField
                    name={`${name}.unitCost`}
                    type="number"
                    label="Unit cost"
                    required
                    disabled={disabled}
                    fullWidth
                  />
                </div>
                <div className={classes.variantActions}>
                  <IconButton size="small" onClick={() => fields.remove(index)}>
                    <RemoveIcon />
                  </IconButton>
                </div>
              </div>
            ))}
            <Button onClick={() => fields.push({})} color="secondary">
              <AddIcon /> Add variant
            </Button>
          </>
        )}
      </FieldArray>
    </>
  );
};

export default FormLayout;
