import React from "react";
import dayjs from "dayjs";
import gql from "graphql-tag";
import { useAdminUrls } from "../../Urls";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import useQueryString from "../../useQueryString";
import { useQuery } from "urql";
import { useTenantKey } from "../../useTenantKey";
import {
  Breadcrumbs,
  Container,
  Typography,
  LinearProgress,
  Link,
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {
  Add as AddIcon,
  CloudDownload as DownloadIcon,
  Event as CalendarIcon,
  List as ListIcon,
  ChevronLeft as PreviousPageIcon,
  ChevronRight as NextPageIcon,
} from "@mui/icons-material";

import SearchField from "./SearchField";
import JobStatusChip from "./JobStatusChip";
import DownloadJobsCsvDialog from "./DownloadJobsCsvDialog";
import JobCalendar from "./JobCalendar";

const QUERY = gql`
  query JobsQuery(
    $tenantKey: ID!
    $q: String
    $statusIn: [Tenant_Job_JobStatusType!]
    $scheduledBetween: DateBetweenFilterType
    $afterCursor: String
    $beforeCursor: String
  ) {
    tenant(key: $tenantKey) {
      jobs {
        all(
          first: 100
          after: $afterCursor
          before: $beforeCursor
          filter: {
            q: $q
            statusIn: $statusIn
            scheduledBetween: $scheduledBetween
          }
          orderBy: START_DATE_DESC
        ) {
          totalCount
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              title
              reference
              status
              startDate
              endDate
              customer {
                id
                name
                po
              }
              site {
                name
              }
              invoiceNumber
            }
          }
        }
      }
    }
  }
`;

const usePageLayoutStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  "mb-2": {
    marginBottom: theme.spacing(2),
  },
  "mt-2": { marginTop: theme.spacing(2) },
  pageBottom: {
    marginBottom: theme.spacing(10),
  },
}));

const PageLayout = ({ initialSearch, onSearch, hideDateRange, children }) => {
  const classes = usePageLayoutStyles();
  const adminUrls = useAdminUrls();

  return (
    <Container className={classes["mt-2"]}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to={adminUrls.dashboard} component={RouterLink}>
          Admin
        </Link>
        <Typography color="textPrimary">Jobs</Typography>
      </Breadcrumbs>
      <Typography variant="h3" gutterBottom>
        Jobs
      </Typography>
      <SearchField
        initialSearch={initialSearch}
        onSearch={onSearch}
        className={classes["mb-2"]}
        hideDateRange={hideDateRange}
      />
      <div className={classes.pageBottom}>{children}</div>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        component={RouterLink}
        to={adminUrls.newJob}
      >
        <AddIcon />
      </Fab>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  marginRight: { marginRight: theme.spacing(1) },
  "mb-2": {
    marginBottom: theme.spacing(2),
  },
  "mr-1": {
    marginRight: theme.spacing(1),
  },
  "mr-2": {
    marginRight: theme.spacing(2),
  },
  toolBarRoot: {
    marginBottom: theme.spacing(2),
    display: "flex",
  },
  toolBarDownloadContainer: {
    flex: "1 1 auto",
  },
  tableContainer: {
    marginBottom: theme.spacing(2),
  },
}));

const ListPage = () => {
  const queryParams = useQueryString();
  const navigate = useNavigate();
  const classes = useStyles();
  const [downloadDialogOpen, setDownloadDialogOpen] = React.useState(false);
  const [showCalendar, setShowCalendar] = React.useState(false);

  const ParseSearchQuery = () => {
    const { q, statusIn, scheduledBetween } = JSON.parse(
      atob(queryParams.q || "") || "{}"
    );

    return {
      q: q || undefined,
      statusIn: statusIn || ["SCHEDULED", "DRAFT", "COMPLETED"],
      scheduledBetween: scheduledBetween || undefined,
      beforeCursor: queryParams.before || undefined,
      afterCursor: queryParams.after || undefined,
    };
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [queryParams]);

  const searchQuery = ParseSearchQuery();
  const { tenantKey } = useTenantKey();
  const adminUrls = useAdminUrls();
  const [{ fetching, data }] = useQuery({
    query: QUERY,
    variables: { tenantKey, ...searchQuery },
    requestPolicy: "cache-and-network",
  });

  const setSearchQuery = (
    { q, statusIn, scheduledBetween },
    { before, after } = {
      before: undefined,
      after: undefined,
    }
  ) => {
    const query = JSON.stringify({
      q: q || "",
      statusIn: statusIn || ["SCHEDULED", "DRAFT", "COMPLETED"],
      scheduledBetween,
    });
    navigate(
      `${adminUrls.jobs}?q=${btoa(query)}&before=${before || ""}&after=${
        after || ""
      }`,
      { replace: true }
    );
  };

  const handleSearch = ({ q, statusIn, scheduledBetween }) => {
    setSearchQuery({
      q,
      statusIn: statusIn?.some((x) => true) ? statusIn : undefined,
      scheduledBetween,
    });
  };

  const handleCalendarSearch = ({ low, high }) => {
    setSearchQuery({
      q: searchQuery.q,
      statusIn: searchQuery.statusIn,
      scheduledBetween: {
        low,
        high,
      },
    });
  };

  const handleShowAsCalendar = () => {
    setShowCalendar(true);
  };

  const handleShowAsList = () => {
    setShowCalendar(false);
    setSearchQuery({
      q: searchQuery.q,
      statusIn: searchQuery.statusIn,
    });
  };

  const handlePreviousPageClick = (startCursor) => () => {
    setSearchQuery(
      {
        q: searchQuery.q,
        statusIn: searchQuery.statusIn,
        scheduledBetween: searchQuery.scheduledBetween,
      },
      { before: startCursor }
    );
  };

  const handleNextPageClick = (endCursor) => () => {
    setSearchQuery(
      {
        q: searchQuery.q,
        statusIn: searchQuery.statusIn,
        scheduledBetween: searchQuery.scheduledBetween,
      },
      { after: endCursor }
    );
  };

  const allJobs = data?.tenant.jobs.all || {
    totalCount: 0,
    edges: [],
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false,
    },
  };

  return (
    <PageLayout
      initialSearch={searchQuery}
      onSearch={handleSearch}
      hideDateRange={showCalendar}
    >
      {fetching && <LinearProgress variant="indeterminate" />}
      <>
        <div className={classes.toolBarRoot}>
          <DownloadJobsCsvDialog
            open={downloadDialogOpen}
            queryVariables={{ tenantKey, ...searchQuery }}
            handleClose={() => setDownloadDialogOpen(false)}
          />
          <div className={classes.toolBarDownloadContainer}>
            <Button
              onClick={() => setDownloadDialogOpen(true)}
              disabled={fetching}
            >
              <DownloadIcon className={classes.marginRight} /> Download
            </Button>
          </div>
          <div className={classes.toolBarViewContainer}>
            {!showCalendar && (
              <Tooltip title="Show calendar view">
                <IconButton onClick={handleShowAsCalendar} size="large">
                  <CalendarIcon />
                </IconButton>
              </Tooltip>
            )}
            {showCalendar && (
              <Tooltip title="Show list view">
                <IconButton onClick={handleShowAsList} size="large">
                  <ListIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        {showCalendar && (
          <JobCalendar jobs={allJobs} onFetchEvents={handleCalendarSearch} />
        )}
        {!showCalendar && (
          <>
            <TableContainer
              className={classes.tableContainer}
              component={Paper}
            >
              <Table aria-label="Jobs table">
                <TableHead>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>Schedule</TableCell>
                    <TableCell>Invoice #</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allJobs.edges.map(({ cursor, node }) => (
                    <TableRow key={cursor}>
                      <TableCell>
                        <JobStatusChip status={node.status} size="small" />
                      </TableCell>
                      <TableCell>
                        {node.reference && `[${node.reference}]`}{" "}
                        <Link
                          component={RouterLink}
                          to={adminUrls.job(node.id)}
                        >
                          {node.title || "(NO TITLE)"}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          component={RouterLink}
                          to={adminUrls.customer(node.customer.id)}
                        >
                          {node.customer.name} ({node.site.name})
                        </Link>
                      </TableCell>
                      <TableCell>
                        {dayjs(node.startDate).format("L LT")} -{" "}
                        {dayjs(node.endDate).format("L LT")}
                      </TableCell>
                      <TableCell>{node.invoiceNumber}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {(allJobs.pageInfo.hasNextPage ||
              allJobs.pageInfo.hasPreviousPage) && (
              <div className={classes.paginationContainer}>
                <Button
                  className={classes["mr-1"]}
                  aria-label="previous page"
                  disabled={!allJobs.pageInfo.hasPreviousPage}
                  onClick={handlePreviousPageClick(
                    allJobs.pageInfo.startCursor
                  )}
                >
                  <PreviousPageIcon /> Previous page
                </Button>
                <Button
                  aria-label="next page"
                  disabled={!allJobs.pageInfo.hasNextPage}
                  onClick={handleNextPageClick(allJobs.pageInfo.endCursor)}
                >
                  Next page <NextPageIcon />
                </Button>
              </div>
            )}
          </>
        )}
        {allJobs.totalCount === 0 && <p>No jobs found</p>}
      </>
    </PageLayout>
  );
};

export default ListPage;
