import React from "react";
import { TextField, Grid, Chip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

const statusOptions = ["DRAFT", "SCHEDULED", "COMPLETED", "CLOSED"];

const SearchField = ({ initialSearch, onSearch, className, hideDateRange }) => {
  const [qValue, setQValue] = React.useState(initialSearch.q || "");
  const [statusValue, setStatusInValue] = React.useState(
    initialSearch.statusIn
  );
  const [dateRange, setDateRange] = React.useState(
    initialSearch.scheduledBetween
  );

  React.useEffect(() => {
    setDateRange(initialSearch.scheduledBetween);
  }, [setDateRange, initialSearch]);

  React.useEffect(() => {
    setStatusInValue(initialSearch.statusIn);
  }, [setStatusInValue, initialSearch]);

  const handleSearch = (event) => {
    event.preventDefault();
    onSearch({
      q: qValue,
      statusIn: statusValue,
      scheduledBetween: dateRange,
    });
  };

  const handleStatusChange = (e, values) => {
    onSearch({ q: qValue, statusIn: values, scheduledBetween: dateRange });
  };

  const handleSetDateRange = (value) => {
    onSearch({
      q: qValue,
      statusIn: statusValue,
      scheduledBetween: value ? { low: value[0], high: value[1] } : undefined,
    });
  };

  return (
    <form onSubmit={handleSearch} className={className}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          <TextField
            variant="outlined"
            fullWidth
            type="search"
            defaultValue={qValue}
            placeholder="Search"
            onChange={(event) => setQValue(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <Autocomplete
            multiple
            options={statusOptions}
            getOptionLabel={(option) => option}
            defaultValue={initialSearch.statusIn}
            onChange={handleStatusChange}
            renderTags={(value, getTagProps) => {
              return value.map((option, index) => (
                <Chip
                  label={option}
                  // disabled={mutating}
                  {...getTagProps({ index })}
                />
              ));
            }}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                {...params}
                // label="Status"
                placeholder="Status in"
              />
            )}
          />
        </Grid>
      </Grid>
      {!hideDateRange && (
        <div className={`foo`}>
          <DateRangePicker
            value={Boolean(dateRange) ? [dateRange.low, dateRange.high] : null}
            onChange={handleSetDateRange}
          />
        </div>
      )}
      <button hidden>Submit</button>
    </form>
  );
};

export default SearchField;
