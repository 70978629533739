import React from "react";

import gql from "graphql-tag";
import { useMutation } from "urql";
import { Form } from "react-final-form";
import { Button } from "@mui/material";
import SiteFormFields from "./SiteFormFields";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const NEW_SITE = gql`
  mutation NewSite(
    $tenantKey: ID!
    $customerId: ID!
    $input: Tenant_Customer_AddSite_InputType!
  ) {
    tenant(key: $tenantKey) {
      customers {
        addSite(id: $customerId, input: $input) {
          customer {
            id
            sites {
              totalCount
              edges {
                node {
                  id
                  name
                  address {
                    line1
                    line2
                    townCity
                    county
                    postCode
                  }
                  contact {
                    name
                    emailAddress
                    telephone {
                      phone
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const NewSiteDialog = ({
  tenantKey,
  customerId,
  open,
  onCancel,
  onAdded,
  fetching,
}) => {
  const [, executeMutation] = useMutation(NEW_SITE);

  const [initialFromValues] = React.useState({
    name: "",
    address: {
      line1: "",
      line2: "",
      townCity: "",
      county: "",
      postCode: "",
    },
    contact: null,
  });

  const handleSubmit = (form) => {
    executeMutation({
      customerId,
      tenantKey,
      input: { ...form },
    }).then(() => {
      onAdded();
    });
  };

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={onCancel}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">New customer site</DialogTitle>
      <Form
        initialValues={initialFromValues}
        onSubmit={handleSubmit}
        render={({ handleSubmit, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              <SiteFormFields disabled={fetching} values={values} />
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel} color="primary">
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={pristine || fetching}
              >
                Save new site
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

export default NewSiteDialog;
