import React from "react";
import clsx from "clsx";
import { Chip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { amber, lime, green, blue, grey } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  draft: {
    background: amber[400],
  },
  scheduled: {
    background: lime[500],
  },
  completed: {
    background: green[500],
  },
  closed: {
    background: blue[500],
  },
  cancelled: {
    background: grey[400],
  },
}));

const JobStatusChip = ({ status, size, className }) => {
  const classes = useStyles();
  if (status === "DRAFT")
    return (
      <Chip
        size={size}
        label={status}
        className={clsx([className, classes.draft])}
      />
    );
  if (status === "SCHEDULED")
    return (
      <Chip
        size={size}
        label={status}
        className={clsx([className, classes.scheduled])}
      />
    );
  if (status === "COMPLETED")
    return (
      <Chip
        size={size}
        label={status}
        className={clsx([className, classes.completed])}
      />
    );
  if (status === "CLOSED")
    return (
      <Chip
        size={size}
        label={status}
        className={clsx([className, classes.closed])}
      />
    );
  if (status === "CANCELLED")
    return (
      <Chip
        size={size}
        label={status}
        className={clsx([className, classes.cancelled])}
      />
    );

  return <Chip size={size} label={status} />;
};

export default JobStatusChip;
