import React from "react";
import { useDropzone } from "react-dropzone";

const FileUpload = ({
  children,
  getSignedUrl,
  onProgress,
  onUploadReady,
  // onComplete,
  onError,
}) => {
  const [progress, setProgress] = React.useState(0);
  const [dropped, setDropped] = React.useState(false);

  const handleFileUploadComplete = React.useCallback(
    ({ acceptedFiles, file, preSign }) => {
      setProgress((prev) => (prev += 1 / acceptedFiles.length));

      onUploadReady && onUploadReady({ file, preSign });
    },
    [onUploadReady]
  );

  React.useEffect(() => {
    if (!dropped) return;
    onProgress({ total: Number(progress.toFixed(2)) });
  }, [dropped, progress, onProgress]);

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      setDropped(true);
      setProgress(0);

      for (let i = 0; i < acceptedFiles.length; i++) {
        const file = acceptedFiles[i];

        const formData = new FormData();
        formData.append("file", file);

        getSignedUrl(file).then((preSign) => {
          const responsePromise = fetch(
            new Request(preSign.signedUrl, {
              method: "PUT",
              body: file,
              headers: new Headers({
                "Content-Type": file.type,
                "Access-Control-Allow-Origin": "*",
              }),
            })
          );
          responsePromise.then((response) => {
            if (response.status !== 200) {
              console.log("onError", { response });
              onError && onError({ file, preSign });

              return response.text().then((body) => console.error(body));
            }

            handleFileUploadComplete({ acceptedFiles, file, preSign });
          });
        });
      }
    },
    [getSignedUrl, handleFileUploadComplete, onError]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children({ isDragActive })}
    </div>
  );
};

export default FileUpload;
