import React from "react";

import gql from "graphql-tag";
import { useQuery, useMutation } from "urql";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import { useAdminUrls } from "../../Urls";
import { useTenantKey } from "../../useTenantKey";
import { useMembership } from "../../useMembership";
import {
  Container,
  Breadcrumbs,
  Chip,
  Typography,
  LinearProgress,
  Fab,
  Snackbar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  IconButton,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Tooltip,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {
  Add as AddIcon,
  Delete as RemoveIcon,
  Lock as ChangePasswordIcon,
} from "@mui/icons-material";

import NewDialog from "./NewDialog";
import EditDialog from "./EditDialog";
import ChangePasswordDialog from "./ChangePasswordDialog";

const QUERY = gql`
  query AllMembersQuery($tenantKey: ID!) {
    tenant(key: $tenantKey) {
      memberships {
        all {
          totalCount
          pageInfo {
            hasNextPage
          }
          edges {
            cursor
            node {
              id
              roles
              user {
                fullName
                emailAddress
              }
            }
          }
        }
      }
    }
  }
`;

const EDIT_MUTATION = gql`
  mutation EditMembership(
    $tenantKey: ID!
    $id: ID!
    $input: Tenant_Memberships_EditDetails_InputType!
  ) {
    tenant(key: $tenantKey) {
      memberships {
        edit(id: $id, input: $input) {
          membership {
            id
            roles
            user {
              fullName
              emailAddress
            }
          }
        }
      }
    }
  }
`;

const REMOVE_MUTATION = gql`
  mutation RemoveMembership($tenantKey: ID!, $id: ID!) {
    tenant(key: $tenantKey) {
      memberships {
        remove(id: $id) {
          membership {
            id
            roles
            user {
              fullName
              emailAddress
            }
          }
        }
      }
    }
  }
`;

const CREATE_NEW_MUTATION = gql`
  mutation CreateNewMember(
    $tenantKey: ID!
    $input: Tenant_Memberships_AddNew_InputType!
  ) {
    tenant(key: $tenantKey) {
      memberships {
        addNew(input: $input) {
          membership {
            id
            roles
            user {
              id
              fullName
              emailAddress
            }
          }
        }
      }
    }
  }
`;

const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($tenantKey: ID!, $id: ID!, $newPassword: String!) {
    tenant(key: $tenantKey) {
      memberships {
        changePassword(id: $id, input: { newPassword: $newPassword }) {
          membership {
            id
          }
        }
      }
    }
  }
`;

const usePageLayoutStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  "mb-2": {
    marginBottom: theme.spacing(2),
  },
  "mt-2": { marginTop: theme.spacing(2) },
}));

const PageLayout = ({ children, onNewClick }) => {
  const adminUrls = useAdminUrls();
  const classes = usePageLayoutStyles();
  const handleAddClick = () => {
    onNewClick();
  };

  return (
    <Container className={classes["mt-2"]}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to={adminUrls.dashboard} component={RouterLink}>
          Admin
        </Link>
        ><Typography color="textPrimary">Team</Typography>
      </Breadcrumbs>
      <Typography variant="h3" gutterBottom>
        Team
      </Typography>
      {children}
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={handleAddClick}
      >
        <AddIcon />
      </Fab>
    </Container>
  );
};

const usePageStyles = makeStyles((theme) => ({
  "mb-8": {
    marginBottom: theme.spacing(8),
  },
  roleChip: {
    marginRight: theme.spacing(1),
  },
}));

const Page = () => {
  const { membership } = useMembership();
  const { tenantKey } = useTenantKey();
  const adminUrls = useAdminUrls();
  const classes = usePageStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const [result, executeQuery] = useQuery({
    query: QUERY,
    variables: { tenantKey },
    requestPolicy: "cache-and-network",
  });
  const [editResult, executeEdit] = useMutation(EDIT_MUTATION);

  const [removeMembershipId, setRemoveMembershipId] = React.useState(null);
  const [removeResult, executeRemove] = useMutation(REMOVE_MUTATION);
  const [createNewResult, executeCreateNew] = useMutation(CREATE_NEW_MUTATION);

  const [changePasswordId, setChangePasswordId] = React.useState(null);
  const [changePasswordResult, executeChangePassword] = useMutation(
    CHANGE_PASSWORD_MUTATION
  );

  const [newDialogOpen, setNewDialogOpen] = React.useState(null);
  const [newMembershipSnackbarOpen, setNewMembershipSnackbarOpen] =
    React.useState(false);

  const handleFinishEdit = () => {
    navigate(adminUrls.members, { replace: true });
  };

  const handleCancelEdit = handleFinishEdit;

  const handleEditSubmit = ({ name, emailAddress, isAdmin }) => {
    const input = { name, emailAddress, roles: ["ENGINEER"] };
    if (isAdmin) input.roles.push("ADMIN");

    executeEdit({
      tenantKey,
      id,
      input,
    }).then(({ data, error }) => {
      if (!error) handleFinishEdit();
    });
  };

  const handleCreateNewSubmit = ({ name, emailAddress, isAdmin, password }) => {
    const input = { name, emailAddress, roles: ["ENGINEER"], password };
    if (isAdmin) input.roles.push("ADMIN");

    executeCreateNew({
      tenantKey,
      input,
    }).then(() => {
      setNewDialogOpen(false);
      setNewMembershipSnackbarOpen(true);
    });
  };

  const handleRemoveMembershipClick = (id) => () => setRemoveMembershipId(id);

  const handleCancelRemoveClick = () => setRemoveMembershipId(null);

  const handleConfirmRemoveClick = () => {
    executeRemove({
      tenantKey,
      id: removeMembershipId,
    }).then(({ error }) => {
      if (!error) setRemoveMembershipId(null);
      executeQuery({ tenantKey });
    });
  };

  const handleChangeUserPasswordClick = (id) => () => setChangePasswordId(id);
  const handleCancelChangePassword = () => setChangePasswordId(null);
  const handleChangePasswordSubmit = ({ newPassword }) => {
    executeChangePassword({
      tenantKey,
      id: changePasswordId,
      newPassword,
    }).then(({ data, error }) => {
      if (!error) setChangePasswordId(null);
      else {
        console.error("Failed to change password", { data, error });
      }
    });
  };

  return (
    <PageLayout onNewClick={() => setNewDialogOpen(true)}>
      {(result.fetching || editResult.fetching || removeResult.fetching) && (
        <LinearProgress variant="indeterminate" />
      )}
      {!result.fetching && (
        <>
          {/* <code>{JSON.stringify(membership, null, 2)}</code> */}
          <TableContainer component={Paper} className={classes["mb-8"]}>
            <Table aria-label="memberships table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Roles</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {result.data.tenant.memberships.all.edges.map(
                  ({ cursor, node }) => (
                    <TableRow key={cursor}>
                      <TableCell>
                        <b>
                          <Link
                            component={RouterLink}
                            to={adminUrls.editMembership(node.id)}
                            replace
                          >
                            {node.user.fullName}
                          </Link>
                        </b>
                      </TableCell>
                      <TableCell>
                        <Link href={`mailto:${node.user.emailAddress}`}>
                          {node.user.emailAddress}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {node.roles.map((role) => (
                          <Chip
                            className={classes.roleChip}
                            key={role}
                            label={role}
                            size="small"
                            color={role === "ADMIN" ? "secondary" : "default"}
                          />
                        ))}
                      </TableCell>
                      <TableCell>
                        {node.id !== membership.id && (
                          <Tooltip title="Remove team member">
                            <IconButton
                              size="small"
                              onClick={handleRemoveMembershipClick(node.id)}
                            >
                              <RemoveIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title="Change user password">
                          <IconButton
                            size="small"
                            onClick={handleChangeUserPasswordClick(node.id)}
                          >
                            <ChangePasswordIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <EditDialog
            fetching={editResult.fetching}
            membershipId={id}
            tenantKey={tenantKey}
            open={Boolean(id)}
            onClose={handleCancelEdit}
            onSubmit={handleEditSubmit}
          />
          <NewDialog
            fetching={createNewResult.fetching}
            id={id}
            open={Boolean(newDialogOpen)}
            onClose={() => setNewDialogOpen(false)}
            onSubmit={handleCreateNewSubmit}
          />
          <ChangePasswordDialog
            fetching={changePasswordResult.fetching}
            open={Boolean(changePasswordId)}
            onClose={handleCancelChangePassword}
            onSubmit={handleChangePasswordSubmit}
          />
          <Snackbar
            open={newMembershipSnackbarOpen}
            onClose={() => {
              setNewMembershipSnackbarOpen(false);
            }}
            message="Team member added"
          />
          <Dialog
            open={Boolean(removeMembershipId)}
            onClose={handleCancelRemoveClick}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Remove member</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You are about to remove a team member.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelRemoveClick} color="primary">
                Cancel
              </Button>
              <Button
                onClick={handleConfirmRemoveClick}
                color="primary"
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </PageLayout>
  );
};

export default Page;
