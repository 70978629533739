import React from "react";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  "mt-6": {
    marginTop: theme.spacing(6)
  },
  "m-2": {
    margin: theme.spacing(2)
  },
  "mb-2": {
    marginBottom: theme.spacing(2)
  },
  "mt-2": {
    marginTop: theme.spacing(2)
  }
}));

const JobContactReadyBody = ({ contact }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes["mb-2"]}>
        <b>{contact.name}</b>
      </div>
      <p>
        Email:{" "}
        <>
          <a href={`mailto:${contact.emailAddress}`}>{contact.emailAddress}</a>
        </>
      </p>
      {contact.telephone.map((tel, idx) => (
        <p key={`tel-${idx}`}>
          {tel.label}:{" "}
          <>
            <a href={`tel:${tel.phone}`}>{tel.phone}</a>
          </>
        </p>
      ))}
    </>
  );
};

export default JobContactReadyBody;
