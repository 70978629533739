import React from "react";
import { useTenantKey } from "../../useTenantKey";
import { Paper, Typography, Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "urql";

import { useAdminUrls } from "../../Urls";

const KPI_QUERY = gql`
  query JobKpiQuery($tenantKey: ID!) {
    tenant(key: $tenantKey) {
      draft: jobs {
        all(filter: { statusIn: [DRAFT] }) {
          totalCount
        }
      }
      scheduled: jobs {
        all(filter: { statusIn: [SCHEDULED] }) {
          totalCount
        }
      }
      completed: jobs {
        all(filter: { statusIn: [COMPLETED] }) {
          totalCount
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2)
  },
  titleContainer: {
    display: "flex",
    alignItems: "center"
  },
  title: {
    flex: "1 1 auto"
  },
  titleButtonLeft: { marginRight: theme.spacing(2) },
  kpiRoot: {
    display: "flex",
    flexWrap: "wrap"
  },
  kpiContainer: {
    border: `1px solid ${theme.palette.secondary.main}`,
    padding: `0 ${theme.spacing(2)} ${theme.spacing(2)}`,
    margin: theme.spacing(2),
    flex: "1 1 auto",
    textAlign: "center"
  },
  kpiTitle: {
    color: theme.palette.text.hint
  },
  kpiValue: {
    fontSize: theme.typography.h2.fontSize
  }
}));

const JobsCard = () => {
  const classes = useStyles();
  const adminUrls = useAdminUrls();
  const { tenantKey } = useTenantKey();

  const [{ fetching, data }] = useQuery({
    query: KPI_QUERY,
    variables: { tenantKey },
    requestPolicy: "cache-and-network"
  });

  return (
    <>
      <Paper className={classes.paper}>
        <Typography
          variant="h5"
          gutterBottom
          className={classes.titleContainer}
        >
          <div className={classes.title}>Jobs</div>
          <div className={classes.titleLinks}>
            <Button
              to={adminUrls.jobs}
              component={RouterLink}
              className={classes.titleButtonLeft}
              color="primary"
            >
              view jobs
            </Button>{" "}
            <Button
              to={adminUrls.newJob}
              component={RouterLink}
              color="primary"
            >
              create new job
            </Button>
          </div>
        </Typography>
        <div className={classes.kpiRoot}>
          <div className={classes.kpiContainer}>
            <Typography variant="h6" className={classes.kpiTitle} gutterBottom>
              Draft
            </Typography>
            <div className={classes.kpiValue}>
              {fetching ? "..." : data?.tenant.draft.all.totalCount}
            </div>
          </div>
          <div className={classes.kpiContainer}>
            <Typography variant="h6" className={classes.kpiTitle} gutterBottom>
              Scheduled
            </Typography>
            <div className={classes.kpiValue}>
              {fetching ? "..." : data?.tenant.scheduled.all.totalCount}
            </div>
          </div>
          <div className={classes.kpiContainer}>
            <Typography variant="h6" className={classes.kpiTitle} gutterBottom>
              Completed
            </Typography>
            <div className={classes.kpiValue}>
              {fetching ? "..." : data?.tenant.completed.all.totalCount}
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default JobsCard;
