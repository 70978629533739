import React from "react";
import dayjs from "dayjs";
import { useAdminUrls } from "../../Urls";
import { useNavigate } from "react-router-dom";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import makeStyles from '@mui/styles/makeStyles';

const formats = {
  dateFormat: "DD",
  dayFormat: "ddd DD",
  weekdayFormat: "ddd",
  monthHeaderFormat: "MMMM YYYY",
  dayHeaderFormat: "ddd MMMM DD",
};
const localizer = dateFnsLocalizer({
  format: (value, format) => {
    const dayFormat = format;
    return dayjs(value).format(dayFormat);
  },
  parse: (value) => dayjs(value).format(),
  startOfWeek: (value) => dayjs(value).startOf("week"),
  getDay: (value) => dayjs(value).day(),
  locales: () => [],
});

const useStyles = makeStyles((theme) => ({
  eventA: {
    background: "#ce93d8",
  },
  eventB: {
    background: "#4db6ac",
  },
  eventC: {
    background: "#4caf50",
  },
  eventD: {
    background: "#ff9800",
  },
  eventE: {
    background: "#ff7043",
  },
}));

const eventClasses = ["eventA", "eventB", "eventC", "eventD", "eventE"];

const JobCalendar = ({ jobs, onFetchEvents }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const adminUrls = useAdminUrls();

  const handleRangeChange = ({ start, end }) => {
    if (!start || !end) return;

    const variables = {
      low: start && dayjs(start).format(),
      high: end && dayjs(end).format(),
    };
    onFetchEvents(variables);
  };

  const handleSelectEvent = (event) => {
    navigate(event.extendedProps.viewUrl);
  };

  return (
    <>
      <Calendar
        localizer={localizer}
        formats={formats}
        events={jobs.edges.map(({ node }, index) => ({
          id: node.id,
          title: node.title,
          start: node.startDate,
          end: node.endDate,
          extendedProps: {
            viewUrl: adminUrls.job(node.id),
            className: classes[eventClasses[index % eventClasses.length]],
          },
        }))}
        eventPropGetter={(event) => ({
          className: event.extendedProps.className,
        })}
        defaultView="month"
        views={["month"]}
        popup={true}
        onRangeChange={handleRangeChange}
        onSelectEvent={handleSelectEvent}
        style={{ height: "80vh" }}
      />
    </>
  );
};

export default JobCalendar;
