import React from "react";
import dayjs from "dayjs";
import gql from "graphql-tag";
import { useMutation } from "urql";
import { Form } from "react-final-form";
import TextField from "../../Fields/TextField";
import { Button, Grid } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const CHANGE_SCHEDULE = gql`
  mutation ChangeSchedule(
    $tenantKey: ID!
    $id: ID!
    $startDate: DateTimeOffset!
    $endDate: DateTimeOffset!
  ) {
    tenant(key: $tenantKey) {
      jobs {
        changeSchedule(
          id: $id
          input: { startDate: $startDate, endDate: $endDate }
        ) {
          job {
            id
            startDate
            endDate
          }
        }
      }
    }
  }
`;

const EditForm = ({ fetching }) => (
  <Grid container>
    <Grid item xs={12} sm={12} md={6}>
      <TextField
        name="startDate"
        type="date"
        required
        disabled={fetching}
        label="Start date"
      />
      <TextField
        name="startTime"
        type="time"
        step="300"
        required
        disabled={fetching}
        label="Start time"
        InputLabelProps={{
          shrink: true
        }}
      />
    </Grid>
    <Grid item xs={12} sm={12} md={6}>
      <TextField
        name="endDate"
        type="date"
        required
        disabled={fetching}
        label="End date"
      />
      <TextField
        name="endTime"
        type="time"
        step="300"
        required
        disabled={fetching}
        label="End time"
        InputLabelProps={{
          shrink: true
        }}
      />
    </Grid>
  </Grid>
);

const useStyles = makeStyles(theme => ({
  actions: {
    marginTop: theme.spacing(2)
  },
  btnLeft: {
    marginRight: theme.spacing(2)
  }
}));

const JobScheduleEditBody = ({
  onCancel,
  onRescheduled,
  tenantKey,
  jobId,
  startDate,
  endDate
}) => {
  const classes = useStyles();
  const [initialFromValues] = React.useState({
    startDate: dayjs(startDate).format("YYYY-MM-DD"),
    startTime: dayjs(startDate).format("HH:mm"),
    endDate: dayjs(endDate).format("YYYY-MM-DD"),
    endTime: dayjs(endDate).format("HH:MM")
  });

  const [{ fetching }, executeChangeSchedule] = useMutation(CHANGE_SCHEDULE);

  const handleSaveChanges = form => {
    const values = {
      tenantKey,
      id: jobId,
      startDate: dayjs(`${form.startDate}T${form.startTime}`).format(),
      endDate: dayjs(`${form.endDate}T${form.endTime}`).format()
    };
    executeChangeSchedule(values).then(() => {
      onRescheduled();
    });
  };

  return (
    <Form
      initialValues={initialFromValues}
      onSubmit={handleSaveChanges}
      render={({ handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit}>
          <EditForm fetching={fetching} />
          <div className={classes.actions}>
            <Button
              variant="contained"
              color="primary"
              aria-label="save change to schedule"
              type="submit"
              disabled={fetching}
              className={classes.btnLeft}
            >
              Reschedule
            </Button>
            <Button onClick={onCancel} disabled={fetching}>
              Cancel
            </Button>
          </div>
        </form>
      )}
    />
  );
};

export default JobScheduleEditBody;
