import React from "react";
import dayjs from "dayjs";

import { Form } from "react-final-form";
import { Grid, Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import TextField from "../../../Fields/TextField";

const useStyles = makeStyles((theme) => ({
  marginBottom: { marginBottom: theme.spacing(2) },
  marginRight: { marginRight: theme.spacing(2) },
}));

const DetailStep = ({ disabled, onComplete, onBack }) => {
  const classes = useStyles();
  const handleSubmit = (form) => {
    const values = {
      reference: form.reference,
      title: form.title,
      description: form.description,
      startDate: dayjs(`${form.startDate}T${form.startTime}`).format(),
      endDate: dayjs(`${form.endDate}T${form.endTime}`).format(),
    };
    onComplete(values);
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} className={classes.marginBottom}>
              <Grid item xs={12} sm={3}>
                <TextField name="reference" fullWidth label="Reference" />
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField name="title" required fullWidth label="Title" />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  name="startDate"
                  type="date"
                  required
                  disabled={disabled}
                  label="Start date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  name="startTime"
                  type="time"
                  step="300"
                  required
                  disabled={disabled}
                  label="Start time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  name="endDate"
                  type="date"
                  required
                  disabled={disabled}
                  label="End date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  name="endTime"
                  type="time"
                  step="300"
                  required
                  disabled={disabled}
                  label="End time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="description"
                  required
                  fullWidth
                  label="Description"
                  multiline
                  maxRows={20}
                />
              </Grid>
            </Grid>
            <div>
              <Button onClick={onBack} className={classes.marginRight}>
                Back
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Create job
              </Button>
            </div>
          </form>
        )}
      />
    </>
  );
};

export default DetailStep;
