import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Menu,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AccountCircle } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { useEngineerUrls, useAdminUrls } from "../Urls";
import { useAuth } from "../AuthContext";
import { useMembership } from "../useMembership";

const useStyles = makeStyles((theme) => ({
  logo: {
    flexGrow: 1,
    maxHeight: "64px",
  },
  logoImg: {
    maxHeight: "64px",
  },
  marginRight: { marginRight: theme.spacing(2) },
}));

export default function MenuAppBar() {
  const { tenantKey, fetching, isInAnyRole } = useMembership();
  const classes = useStyles();
  const adminUrls = useAdminUrls();
  const engineerUrls = useEngineerUrls();
  const { isAuthenticated, signOut } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    signOut();
  };

  return (
    <AppBar position="static" color="transparent">
      <Toolbar>
        <div className={classes.logo}>
          <RouterLink to={`/${tenantKey}/`}>
            <img src="/logo138.jpg" className={classes.logoImg} alt="Logo" />
          </RouterLink>
        </div>
        <Button
          color="inherit"
          className={classes.marginRight}
          variant="outlined"
          component={RouterLink}
          to={engineerUrls.dashboard}
        >
          Engineer
        </Button>
        {!fetching && isInAnyRole("ADMIN") && (
          <Button
            color="inherit"
            className={classes.marginRight}
            component={RouterLink}
            to={adminUrls.dashboard}
          >
            Admin
          </Button>
        )}
        {isAuthenticated && (
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              size="large"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
              <MenuItem component={RouterLink} to="/">
                Home
              </MenuItem>
              <MenuItem disabled>{process.env.REACT_APP_VERSION}</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
