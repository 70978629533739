import React from "react";
import gql from "graphql-tag";
import { useMutation } from "urql";
import { Form } from "react-final-form";
import TextField from "../../Fields/TextField";
import { Button } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const CHANGE_TITLE = gql`
  mutation ChangeTitle($tenantKey: ID!, $id: ID!, $title: String!) {
    tenant(key: $tenantKey) {
      jobs {
        changeTitle(id: $id, input: { title: $title }) {
          job {
            id
            title
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  actions: {
    marginTop: theme.spacing(2)
  },
  btnLeft: {
    marginRight: theme.spacing(2)
  }
}));

const JobTitleEditBody = ({ tenantKey, jobId, title, onCancel, onChanged }) => {
  const classes = useStyles();
  const [initialFromValues] = React.useState({
    title
  });

  const [{ fetching }, executeChangeTitle] = useMutation(CHANGE_TITLE);

  const handleSaveChanges = form => {
    const values = {
      tenantKey,
      id: jobId,
      title: form.title
    };
    executeChangeTitle(values).then(() => {
      onChanged();
    });
  };

  return (
    <Form
      initialValues={initialFromValues}
      onSubmit={handleSaveChanges}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <TextField
            name="title"
            required
            disabled={fetching}
            fullWidth
            label="Title"
          />
          <div className={classes.actions}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={fetching}
              className={classes.btnLeft}
            >
              Save changes
            </Button>
            <Button onClick={onCancel} disabled={fetching}>
              Cancel
            </Button>
          </div>
        </form>
      )}
    />
  );
};

export default JobTitleEditBody;
