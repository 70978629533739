import React from "react";
import { Paper, Typography, Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink } from "react-router-dom";

import { useAdminUrls } from "../../Urls";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2)
  },
  titleContainer: {
    display: "flex",
    alignItems: "center"
  },
  title: {
    flex: "1 1 auto"
  },
  titleButtonLeft: { marginRight: theme.spacing(2) }
}));

const JobsCard = () => {
  const classes = useStyles();
  const adminUrls = useAdminUrls();

  return (
    <>
      <Paper className={classes.paper}>
        <Typography
          variant="h5"
          gutterBottom
          className={classes.titleContainer}
        >
          <div className={classes.title}>Materials</div>
          <div className={classes.titleLinks}>
            <Button
              to={adminUrls.materials}
              component={RouterLink}
              className={classes.titleButtonLeft}
              color="primary"
            >
              view
            </Button>
          </div>
        </Typography>
      </Paper>
    </>
  );
};

export default JobsCard;
