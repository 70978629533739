import React from "react";
import clsx from "clsx";
import { TextField as MaterialTextField, IconButton, Menu, MenuItem } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Autocomplete } from '@mui/material';
import { MoreVert as MenuIcon } from "@mui/icons-material";
import TextField from "../../Fields/TextField";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex" },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  materialFieldContainer: {
    flex: "1 1 auto",
  },
  qtyField: {
    width: "60px",
  },
  materialField: {
    flexGrow: 1,
  },
  hidden: { display: "none" },
}));

const MaterialForm = ({ options, form, fieldName, onRemove }) => {
  const classes = useStyles();
  const [freeSolo, setFreeSolo] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const handleMenuClick = (e) => {
    setMenuAnchorEl(e.target);
  };

  const handleMenuClose = () => setMenuAnchorEl(null);

  const handleSetFreeSolo = () => {
    form.change(`${fieldName}.unitCost`, null);
    setFreeSolo(true);
  };

  const handleMaterialSelectChange = (e, value) => {
    if (value === null) {
      form.change(`${fieldName}.name`, "");
      form.change(`${fieldName}.unitCost`, null);
    } else if (typeof value === "string") {
      form.change(`${fieldName}.name`, value);
      form.change(`${fieldName}.unitCost`, null);
    } else {
      form.change(`${fieldName}.name`, value.name);
      form.change(`${fieldName}.unitCost`, value.unitCost);
    }
  };

  return (
    <div key={fieldName}>
      <div className={classes.root}>
        <div
          className={clsx(classes.materialFieldContainer, classes.marginRight)}
        >
          {!freeSolo && (
            <>
              <Autocomplete
                options={options}
                groupBy={(option) => option.type}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  return option.variant;
                }}
                isOptionEqualToValue={(option, value) => {
                  return (
                    option.type === value.type &&
                    option.variant === value.variant
                  );
                }}
                onChange={handleMaterialSelectChange}
                renderInput={(params) => (
                  <MaterialTextField
                    variant="outlined"
                    className={classes.marginRight}
                    fullWidth
                    required
                    {...params}
                    placeholder="Material"
                  />
                )}
              />
            </>
          )}

          <TextField
            name={`${fieldName}.name`}
            required
            autoFocus
            type="text"
            label="Material"
            fullWidth
            className={clsx(classes.formField, classes.materialField, {
              [classes.hidden]: !freeSolo,
            })}
          />
        </div>
        <TextField
          name={`${fieldName}.qty`}
          required
          type="number"
          label="Qty"
          min={0}
          fullWidth
          className={clsx(classes.formField, classes.qtyField)}
        />
        <IconButton
          aria-controls="options-menu"
          aria-haspopup="true"
          onClick={handleMenuClick}
          size="large">
          <MenuIcon />
        </IconButton>
      </div>
      <Menu
        id="options-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            onRemove();
            handleMenuClose();
          }}
        >
          Remove
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleSetFreeSolo();
            handleMenuClose();
          }}
        >
          Unlisted material
        </MenuItem>
      </Menu>
      <TextField
        name={`${fieldName}.unitCost`}
        type="text"
        inputMode="decimal"
        label="Unit cost"
        fullWidth
        className={classes.hidden}
      />
    </div>
  );
};

export default MaterialForm;
