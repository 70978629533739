import React from "react";
import gql from "graphql-tag";
import { useMutation } from "urql";
import { Form } from "react-final-form";
import TextField from "../../Fields/TextField";
import { Button } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const CHANGE_INVOICE_NUMBER = gql`
  mutation ChangeInvoiceNumber(
    $tenantKey: ID!
    $id: ID!
    $invoiceNumber: String!
  ) {
    tenant(key: $tenantKey) {
      jobs {
        setInvoiceNumber(id: $id, input: { invoiceNumber: $invoiceNumber }) {
          job {
            id
            invoiceNumber
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  actions: {
    marginTop: theme.spacing(2)
  },
  btnLeft: {
    marginRight: theme.spacing(2)
  }
}));

const JobInvoiceNumberEditBody = ({
  onCancel,
  onChanged,
  tenantKey,
  jobId,
  invoiceNumber
}) => {
  const classes = useStyles();
  const [initialFromValues] = React.useState({
    invoiceNumber: invoiceNumber
  });

  const [{ fetching }, executeChangeInvoiceNumber] = useMutation(
    CHANGE_INVOICE_NUMBER
  );

  const handleSaveChanges = form => {
    const values = {
      tenantKey,
      id: jobId,
      invoiceNumber: form.invoiceNumber
    };
    executeChangeInvoiceNumber(values).then(() => {
      onChanged();
    });
  };

  return (
    <Form
      initialValues={initialFromValues}
      onSubmit={handleSaveChanges}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <TextField
            name="invoiceNumber"
            required
            disabled={fetching}
            label="Invoice number"
          />
          <div className={classes.actions}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={fetching}
              className={classes.btnLeft}
            >
              Save
            </Button>
            <Button onClick={onCancel} disabled={fetching}>
              Cancel
            </Button>
          </div>
        </form>
      )}
    />
  );
};

export default JobInvoiceNumberEditBody;
