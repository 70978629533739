import React from "react";
import gql from "graphql-tag";
import dayjs from "dayjs";
import { useTenantKey } from "../../useTenantKey";
import { useAdminUrls } from "../../Urls";
import { useParams, Link as RouterLink } from "react-router-dom";
import { useQuery } from "urql";
import { LinearProgress, Breadcrumbs, Link, Container, Typography, Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Alert, AlertTitle } from '@mui/material';

import JobStatusChip from "./JobStatusChip.js";
import JobStatusFlipButton from "./JobStatusFlipButton.js";
import JobTitle from "./JobTitle.js";
import JobSchedule from "./JobSchedule.js";
import JobReference from "./JobReference.js";
import JobDescription from "./JobDescription.js";
import JobAttachments from "./JobAttachments.js";
import JobRiskAssessment from "./JobRiskAssessment.js";
import JobSite from "./JobSite.js";
import JobContact from "./JobContact.js";
import JobNotificationEmails from "./JobNotificationEmails.js";
import JobEngineers from "./JobEngineers.js";
import JobInvoiceNumber from "./JobInvoiceNumber.js";
import JobCustomerPo from "./JobCustomerPo.js";
import JobTimesheetsTable from "./JobTimesheetsTable.js";
import JobMaterialsTable from "./JobMaterialsTable.js";

import showdown from "showdown";

const mdConverter = new showdown.Converter();

const QUERY = gql`
  query JobNode($id: ID!) {
    node(id: $id) {
      id
      ... on Tenant_Job_JobType {
        status
        title
        reference
        startDate
        endDate
        description
        customer {
          id
          name
          po
        }
        invoiceNumber
        site {
          name
          address {
            line1
            line2
            townCity
            county
            postCode
          }
        }
        contact {
          name
          emailAddress
          telephone {
            phone
            label
          }
        }
        notificationEmails
        assignedEngineers {
          totalCount
          edges {
            cursor
            node {
              id
              fullName
            }
          }
        }
        riskAssessment {
          src
          originalFilename
          acceptedBy {
            timestamp
            user {
              id
              fullName
            }
          }
        }
        attachments {
          totalCount
          edges {
            cursor
            node {
              id
              originalFilename
              src
              attachedAt
            }
          }
        }
        completion {
          completedAt
          notes
          completedBy {
            id
            fullName
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
  "mb-2": { marginBottom: theme.spacing(2) },
  "mr-2": { marginRight: theme.spacing(2) },
}));

const ViewPage = () => {
  const adminUrls = useAdminUrls();
  const classes = useStyles();
  const { id } = useParams("id");
  const { tenantKey } = useTenantKey();
  const [result] = useQuery({
    query: QUERY,
    variables: { id },
    pause: !id,
    requestPolicy: "cache-and-network",
  });

  const node = result?.data?.node;
  const editable =
    node?.status === "DRAFT" ||
    node?.status === "SCHEDULED" ||
    node?.status === "COMPLETED";
  const canReschedule =
    node?.status === "DRAFT" || node?.status === "SCHEDULED";

  const completionNotesHtml = React.useMemo(
    () =>
      !Boolean(node && node.completion)
        ? ""
        : mdConverter.makeHtml(node.completion.notes),
    [node]
  );

  return (
    <Container className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to={adminUrls.dashboard} component={RouterLink}>
          Admin
        </Link>
        <Link color="inherit" to={adminUrls.jobs} component={RouterLink}>
          Jobs
        </Link>
        <Typography color="textPrimary">Job</Typography>
      </Breadcrumbs>
      {result.fetching && <LinearProgress variant="indeterminate" />}
      {!result.fetching && (
        <>
          <Typography variant="h3" gutterBottom>
            <JobTitle
              jobId={id}
              tenantKey={tenantKey}
              editable={editable}
              title={node.title}
            />
          </Typography>
          <Typography variant="h5" gutterBottom>
            {node.customer.name}
          </Typography>
          <div className={classes["mb-2"]}>
            <JobStatusChip status={node.status} className={classes["mr-2"]} />
            <JobStatusFlipButton
              tenantKey={tenantKey}
              jobId={id}
              currentStatus={node.status}
            />
          </div>
          {node.completion && (
            <div className={classes["mb-2"]}>
              <Alert icon={false} severity="info">
                <AlertTitle>
                  Completed by {node.completion.completedBy.fullName} on{" "}
                  {dayjs(node.completion.completedAt).format("L")}
                </AlertTitle>
                <div
                  dangerouslySetInnerHTML={{ __html: completionNotesHtml }}
                />
              </Alert>
            </div>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4}>
              <JobSchedule
                jobId={id}
                tenantKey={tenantKey}
                reschedulable={canReschedule}
                startDate={node.startDate}
                endDate={node.endDate}
              />
              <JobReference
                jobId={id}
                tenantKey={tenantKey}
                reference={node.reference}
                editable={editable}
              />
              <JobCustomerPo
                jobId={id}
                tenantKey={tenantKey}
                po={node.customer.po}
                editable={editable}
              />
              <JobInvoiceNumber
                jobId={id}
                tenantKey={tenantKey}
                invoiceNumber={node.invoiceNumber}
                editable={editable}
              />
              <JobSite
                editable={editable}
                jobId={id}
                tenantKey={tenantKey}
                site={node.site}
              />
              <JobContact
                editable={editable}
                jobId={id}
                tenantKey={tenantKey}
                contact={node.contact}
              />
              <JobNotificationEmails
                jobId={id}
                tenantKey={tenantKey}
                emails={node.notificationEmails}
                editable={editable}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <JobEngineers
                jobId={id}
                tenantKey={tenantKey}
                editable={editable}
                assignedEngineers={node.assignedEngineers}
              />
              <JobRiskAssessment
                editable={editable}
                jobId={id}
                tenantKey={tenantKey}
                riskAssessment={node.riskAssessment}
              />
              <JobDescription
                editable={editable}
                jobId={id}
                tenantKey={tenantKey}
                description={node.description}
              />
              <JobAttachments
                editable={editable}
                jobId={id}
                tenantKey={tenantKey}
                attachments={node.attachments}
              />
            </Grid>
          </Grid>
          <div className={classes["mb-2"]}>
            <Typography variant="h5" gutterBottom>
              Timesheets
            </Typography>
            <JobTimesheetsTable jobId={id} />
          </div>
          <div className={classes["mb-2"]}>
            <Typography variant="h5" gutterBottom>
              Materials
            </Typography>
            <JobMaterialsTable jobId={id} />
          </div>
        </>
      )}
    </Container>
  );
};

export default ViewPage;
