import React from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "urql";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import MaterialForm from "./MaterialForm";

const MATERIAL_AUTOCOMPLETE_QUERY = gql`
  query Materials($tenantKey: ID!) {
    tenant(key: $tenantKey) {
      materials {
        all(filter: {}) {
          totalCount
          pageInfo {
            hasNextPage
          }
          edges {
            cursor
            node {
              id
              name
              variants {
                name
                unitCost
              }
            }
          }
        }
      }
    }
  }
`;

const RECORD_MATERIALS = gql`
  mutation JobMaterials(
    $tenantKey: ID!
    $jobId: ID!
    $materials: [Tenant_Job_Materials_Record_MaterialInputType!]!
  ) {
    tenant(key: $tenantKey) {
      jobs {
        materials {
          record(input: { jobId: $jobId, materials: $materials }) {
            job {
              id
              materials {
                totalCount
                edges {
                  node {
                    id
                    name
                    qty
                    unitCost
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const RecordMaterials = ({ tenantKey, jobId, open, onRecorded, onCancel }) => {
  const [{ fetching }, executeRecordMaterials] = useMutation(RECORD_MATERIALS);

  const [materialOptionsResult] = useQuery({
    query: MATERIAL_AUTOCOMPLETE_QUERY,
    variables: { tenantKey },
    pause: !tenantKey,
    requestPolicy: "cache-and-network",
  });
  const allMaterials =
    materialOptionsResult.data?.tenant.materials.all.edges.reduce(
      (acc, { node: next }) => {
        return [
          ...acc,
          ...next.variants.map((v) => ({
            type: next.name,
            variant: v.name,
            name: `${next.name} - ${v.name}`,
            unitCost: v.unitCost,
          })),
        ];
      },
      []
    ) || [];

  const [initialFromValues] = React.useState({
    materials: [
      {
        name: "",
        qty: 1,
      },
    ],
  });

  const onSubmit = ({ materials }) => {
    const variables = {
      tenantKey,
      jobId,
      materials: materials.map(({ name, qty, unitCost }) => ({
        name,
        unitCost,
        qty: qty ? Number(qty) : null,
      })),
    };
    executeRecordMaterials(variables).then(({ data, error }) => {
      if (!error) onRecorded();
      else {
        console.error("Failed to record materials", error);
      }
    });
  };

  return (
    <>
      <Dialog open={open} fullWidth fullScreen>
        <Form
          initialValues={initialFromValues}
          mutators={{
            ...arrayMutators,
          }}
          onSubmit={onSubmit}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle id="form-dialog-title">Record materials</DialogTitle>
              <DialogContent>
                {/* <code>{JSON.stringify(values, null, 2)}</code> */}
                <FieldArray name="materials">
                  {({ fields }) => (
                    <>
                      {fields.map((name, index) => (
                        <MaterialForm
                          key={name}
                          options={allMaterials}
                          form={form}
                          fieldName={name}
                          onRemove={() => fields.remove(index)}
                        />
                      ))}
                    </>
                  )}
                </FieldArray>
                <Button
                  type="button"
                  onClick={() =>
                    form.mutators.push("materials", {
                      name: "",
                      qty: 1,
                      unitCost: null,
                    })
                  }
                >
                  Add material
                </Button>
              </DialogContent>
              <DialogActions>
                <Button onClick={onCancel} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary" disabled={fetching}>
                  Record materials
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

export default RecordMaterials;
