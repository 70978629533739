import React from "react";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import { useQuery } from "urql";
import { LinearProgress } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import JobDetails from "./JobDetails";
import JobRiskAssessmentAgreement from "./JobRiskAssessmentAgreement";

const QUERY = gql`
  query JobNode($id: ID!) {
    node(id: $id) {
      id
      ... on Tenant_Job_JobType {
        status
        title
        reference
        startDate
        endDate
        description
        customer {
          id
          name
        }
        site {
          name
          addressFormat
          address {
            line1
            line2
            townCity
            county
            postCode
          }
        }
        contact {
          name
          emailAddress
          telephone {
            phone
            label
          }
        }
        assignedEngineers {
          totalCount
          edges {
            cursor
            node {
              id
              fullName
            }
          }
        }
        riskAssessment {
          src
          originalFilename
          acceptedByMe
        }
        attachments {
          totalCount
          edges {
            cursor
            node {
              id
              originalFilename
              src
              attachedAt
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  "mt-2": { marginTop: theme.spacing(2) },
}));

const JobPage = () => {
  const { id } = useParams();
  const classes = useStyles();
  const [result] = useQuery({
    query: QUERY,
    variables: { id },
    pause: !id,
    requestPolicy: "cache-and-network",
  });

  const job = result.data?.node;
  const agreedToRiskAssessment = Boolean(job?.riskAssessment)
    ? job.riskAssessment.acceptedByMe
    : true;

  return (
    <div className={classes["mt-2"]}>
      {result.fetching && <LinearProgress variant="indeterminate" />}
      {job && !agreedToRiskAssessment && (
        <JobRiskAssessmentAgreement job={job} />
      )}
      {job && agreedToRiskAssessment && <JobDetails job={job} />}
    </div>
  );
};

export default JobPage;
