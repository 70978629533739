import { useLocation } from "react-router-dom";

function useQueryString() {
  var query = new URLSearchParams(useLocation().search);

  const params = {};
  for (const [key, value] of query) {
    params[key] = value;
  }

  return params;
}

export default useQueryString;
