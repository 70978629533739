import React from "react";

import gql from "graphql-tag";
import { useMutation } from "urql";
import {
  IconButton,
  Button,
  Tooltip,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import NewSiteDialog from "./NewSiteDialog";
import EditSiteDialog from "./EditSiteDialog";
import { Add as AddSiteIcon, Delete as RemoveIcon } from "@mui/icons-material";

import DialogContent from "@mui/material/DialogContent";

const REMOVE_SITE_MUTATION = gql`
  mutation EditCustomerSite($tenantKey: ID!, $customerId: ID!, $siteId: ID!) {
    tenant(key: $tenantKey) {
      customers {
        removeSite(id: $customerId, siteId: $siteId) {
          customer {
            id
            sites {
              totalCount
              edges {
                node {
                  id
                  name
                  address {
                    line1
                    line2
                    townCity
                    county
                    postCode
                  }
                  contact {
                    name
                    emailAddress
                    telephone {
                      phone
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const EditDialogSitesDialogContent = ({ customerId, tenantKey, sites }) => {
  const [newSiteOpen, setNewSiteOpen] = React.useState(false);
  const [edittingSite, setEdittingSite] = React.useState(null);

  const [, executeRemoveSiteMutation] = useMutation(REMOVE_SITE_MUTATION);
  const [removeSiteId, setRemoveSiteId] = React.useState(null);

  const handleNewSiteClick = () => {
    setNewSiteOpen(true);
  };

  const handleCancelNewSiteClick = () => {
    setNewSiteOpen(false);
  };

  const handleNewSiteAdded = () => {
    setNewSiteOpen(false);
  };

  const handleEditSiteClick = (site) => () => {
    setEdittingSite(site);
  };

  const handleCancelEditSiteClick = () => {
    setEdittingSite(null);
  };

  const handleSiteEditted = () => {
    setEdittingSite(null);
  };

  const handleRemoveSiteClick = (siteId) => () => {
    setRemoveSiteId(siteId);
  };

  const handleConfirmRemoveSiteClick = () => {
    executeRemoveSiteMutation({
      tenantKey,
      customerId,
      siteId: removeSiteId,
    });
    setRemoveSiteId(null);
  };

  const handleCancelRemoveSiteClick = () => {
    setRemoveSiteId(null);
  };

  return (
    <>
      <DialogContent dividers>
        <Typography variant="h5" gutterBottom>
          Sites{" "}
          <Tooltip title="Add a new site">
            <IconButton onClick={handleNewSiteClick} size="large">
              <AddSiteIcon />
            </IconButton>
          </Tooltip>
        </Typography>
        {sites.totalCount === 0 && <p>Customer only has a main site</p>}
        <List>
          {sites.edges.map(({ cursor: siteCursor, node: siteNode }) => (
            <ListItemButton
              key={siteCursor}
              onClick={handleEditSiteClick(siteNode)}
            >
              <ListItemText primary={siteNode.name} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="remove"
                  onClick={handleRemoveSiteClick(siteNode.id)}
                  size="large"
                >
                  <RemoveIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItemButton>
          ))}
        </List>
      </DialogContent>

      <NewSiteDialog
        {...{
          open: newSiteOpen,
          customerId,
          tenantKey,
          onCancel: handleCancelNewSiteClick,
          onAdded: handleNewSiteAdded,
        }}
      />
      {edittingSite && (
        <EditSiteDialog
          {...{
            open: Boolean(edittingSite),
            customerId,
            tenantKey,
            site: edittingSite,
            onCancel: handleCancelEditSiteClick,
            onEditted: handleSiteEditted,
          }}
        />
      )}
      <Dialog
        open={Boolean(removeSiteId)}
        onClose={handleCancelRemoveSiteClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Remove site</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to remove a site from a customer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelRemoveSiteClick} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmRemoveSiteClick}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditDialogSitesDialogContent;
