import React from "react";
import { Route, Routes } from "react-router-dom";

import AdminPage from "./Page";
import CustomerPage from "./Customer/Page";
import ViewJobPage from "./Jobs/ViewPage";
import NewJobPage from "./Jobs/NewPage";
import ListJobsPage from "./Jobs/ListPage";
import ListMaterialsPage from "./Materials/ListPage";
import ListMembersPage from "./Members/ListPage";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route>
        <Route index element={<AdminPage />} />
        <Route path="customers/:id" element={<CustomerPage />} />
        <Route path="customers" element={<CustomerPage />} />
        <Route path="jobs/new" element={<NewJobPage />} />
        <Route path="jobs/:id" element={<ViewJobPage />} />
        <Route path="jobs" element={<ListJobsPage />} />
        <Route path="members" element={<ListMembersPage />} />
        <Route path="members/:id" element={<ListMembersPage />} />
        <Route path="materials" element={<ListMaterialsPage />} />
        <Route path="materials/:id" element={<ListMaterialsPage />} />
      </Route>
    </Routes>
  );
};

export default AdminRoutes;
