import React from "react";
import { Form } from "react-final-form";
import { Button, LinearProgress } from "@mui/material";
import FormFields from "./FormFields";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const NewDialog = ({ fetching, open, onClose, onSubmit }) => {
  const [initialFromValues] = React.useState({
    name: "",
    emailAddress: "",
    password: "",
    isAdmin: false,
  });

  return (
    <Dialog
      open={open}
      scroll="paper"
      fullWidth
      onClose={onClose}
      aria-labelledby="form-dialog-title">
      <>
        <Form
          initialValues={initialFromValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, values }) => (
            <>
              <form onSubmit={handleSubmit}>
                <DialogTitle id="form-dialog-title">
                  Add new team member
                </DialogTitle>
                <DialogContent dividers>
                  {fetching && <LinearProgress variant="indeterminate" />}
                  {/* <code>{JSON.stringify(values, null, 2)}</code> */}
                  <FormFields disabled={fetching} password />
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary" disabled={fetching}>
                    Add member
                  </Button>
                </DialogActions>
              </form>
            </>
          )}
        />
      </>
    </Dialog>
  );
};

export default NewDialog;
