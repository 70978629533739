import React from "react";
import { TextField as MaterialTextField } from "@mui/material";
import { Field } from "react-final-form";

const TextField = ({
  name,
  label,
  placeholder,
  required,
  disabled,
  fullWidth,
  multiline,
  maxRows,
  type = "text",
  className,
  InputLabelProps,
  ...rest
}) => (
  <Field name={name}>
    {({ input, meta }) => (
      <>
        <MaterialTextField
          {...input}
          {...{
            type,
            label,
            placeholder,
            required,
            disabled,
            fullWidth,
            variant: "filled",
            className,
            multiline,
            maxRows,
            InputLabelProps,
          }}
          {...rest}
        />
        {meta.touched && meta.error && <span>{meta.error}</span>}
      </>
    )}
  </Field>
);

export default TextField;
