import React from "react";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  noValue: {
    // color: theme.palette.warning.dark
  }
}));

const JobCustomerPoReadBody = ({ customerPo }) => {
  const classes = useStyles();

  if (customerPo) return <b>{customerPo}</b>;

  return (
    <div className={classes.noValue}>
      <em>Customer PO not set</em>
    </div>
  );
};

export default JobCustomerPoReadBody;
