import React from "react";

import gql from "graphql-tag";
import { useQuery, useMutation } from "urql";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import { useAdminUrls } from "../../Urls";
import { useTenantKey } from "../../useTenantKey";
import {
  Container,
  Breadcrumbs,
  TextField,
  Typography,
  LinearProgress,
  Fab,
  Snackbar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Add as AddIcon, RemoveCircle as HideIcon } from "@mui/icons-material";

import NewDialog from "./NewDialog";
import EditDialog from "./EditDialog";

const QUERY = gql`
  query AllCustomersQuery($tenantKey: ID!, $nameQuery: String!) {
    tenant(key: $tenantKey) {
      customers {
        all(filter: { name: { query: $nameQuery } }) {
          totalCount
          pageInfo {
            hasNextPage
          }
          edges {
            cursor
            node {
              id
              name
              addressFormat
            }
          }
        }
      }
    }
  }
`;

const EDIT_CUSTOMER_MUTATION = gql`
  mutation EditCustomer(
    $tenantKey: ID!
    $id: ID!
    $input: Tenant_Customer_Edit_InputType!
  ) {
    tenant(key: $tenantKey) {
      customers {
        edit(id: $id, input: $input) {
          customer {
            id
            name
            status
            address {
              line1
              line2
              townCity
              county
              postCode
            }
            contact {
              name
              emailAddress
              telephone {
                phone
                label
              }
            }
          }
        }
      }
    }
  }
`;

const CREATE_NEW_MUTATION = gql`
  mutation CreateNewCustomer(
    $tenantKey: ID!
    $input: Tenant_Customer_CreateNew_InputType!
  ) {
    tenant(key: $tenantKey) {
      customers {
        createNew(input: $input) {
          customer {
            id
            name
            status
            address {
              line1
              line2
              townCity
              county
              postCode
            }
            contact {
              name
              emailAddress
              telephone {
                phone
                label
              }
            }
          }
        }
      }
    }
  }
`;

const MARK_AS_HIDDEN_MUTATION = gql`
  mutation CreateNewCustomer($tenantKey: ID!, $id: ID!) {
    tenant(key: $tenantKey) {
      customers {
        markAsHidden(id: $id) {
          customer {
            id
            name
          }
        }
      }
    }
  }
`;

const Search = ({ q, onSearch, className }) => {
  const [value, setValue] = React.useState(q);

  const handleSearch = (event) => {
    event.preventDefault();
    onSearch(value);
  };

  return (
    <form onSubmit={handleSearch} className={className}>
      <TextField
        fullWidth
        type="search"
        defaultValue={q}
        placeholder="Search by name"
        onChange={(event) => setValue(event.target.value)}
      />
    </form>
  );
};

const usePageLayoutStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  "mb-2": {
    marginBottom: theme.spacing(2),
  },
  "mt-2": { marginTop: theme.spacing(2) },
}));

const PageLayout = ({ q, onSearch, children, onNewClick }) => {
  const adminUrls = useAdminUrls();
  const classes = usePageLayoutStyles();
  const handleAddClick = () => {
    onNewClick();
  };

  return (
    <Container className={classes["mt-2"]}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to={adminUrls.dashboard} component={RouterLink}>
          Admin
        </Link>
        ><Typography color="textPrimary">Customers</Typography>
      </Breadcrumbs>
      <Typography variant="h3" gutterBottom>
        Customers
      </Typography>
      <Search q={q} onSearch={onSearch} className={classes["mb-2"]} />
      {children}
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={handleAddClick}
      >
        <AddIcon />
      </Fab>
    </Container>
  );
};

const usePageStyles = makeStyles((theme) => ({
  "mb-8": {
    marginBottom: theme.spacing(8),
  },
}));

const Page = () => {
  const adminUrls = useAdminUrls();
  const classes = usePageStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const [nameQuery, setNameQuery] = React.useState("");
  const [hideCustomerId, setHideCustomerId] = React.useState(null);
  const { tenantKey } = useTenantKey();
  const [result] = useQuery({
    query: QUERY,
    variables: { tenantKey, nameQuery },
    requestPolicy: "cache-and-network",
  });
  const [editResult, executeEdit] = useMutation(EDIT_CUSTOMER_MUTATION);
  const [createNewResult, executeCreateNew] = useMutation(CREATE_NEW_MUTATION);
  const [, executeMarkAsHidden] = useMutation(MARK_AS_HIDDEN_MUTATION);

  // const [editingId, setEditingId] = React.useState(id);
  const [newDialogOpen, setNewDialogOpen] = React.useState(null);
  const [newCustomerSnackbarOpen, setNewCustomerSnackbarOpen] =
    React.useState(false);

  const handleSearch = (q) => {
    setNameQuery(q);
  };

  const handleFinishEdit = () => {
    navigate(adminUrls.customers);
  };

  const handleCancelEdit = handleFinishEdit;

  const handleEditSubmit = (form) => {
    executeEdit({
      tenantKey,
      id,
      input: form,
    }).then(() => {
      handleFinishEdit();
    });
  };

  const handleCreateNewSubmit = (form) => {
    executeCreateNew({
      tenantKey,
      input: form,
    }).then(() => {
      setNewDialogOpen(false);
      setNewCustomerSnackbarOpen(true);
    });
  };

  const handleHideCustomerClick = (id) => () => {
    setHideCustomerId(id);
  };

  const handleConfirmHideCustomerClick = () => {
    executeMarkAsHidden({
      tenantKey,
      id: hideCustomerId,
    });
    setHideCustomerId(null);
  };

  const handleCancelHideCustomerClick = () => {
    setHideCustomerId(null);
  };

  return (
    <PageLayout
      q={nameQuery}
      onSearch={handleSearch}
      onNewClick={() => setNewDialogOpen(true)}
    >
      {result.fetching && <LinearProgress variant="indeterminate" />}
      {!result.fetching && (
        <>
          <TableContainer component={Paper} className={classes["mb-8"]}>
            <Table aria-label="customers table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {result.data.tenant.customers.all.edges.map(
                  ({ cursor, node }) => (
                    <TableRow key={cursor}>
                      <TableCell>
                        <b>
                          <Link
                            component={RouterLink}
                            to={adminUrls.customer(node.id)}
                          >
                            {node.name || "[Customer name]"}
                          </Link>
                          {/* <Button onClick={handleEditClick(node.id)}>
                            {node.name}
                          </Button> */}
                        </b>
                      </TableCell>
                      <TableCell>{node.addressFormat}</TableCell>
                      <TableCell>
                        <IconButton
                          edge="end"
                          aria-label="remove"
                          onClick={handleHideCustomerClick(node.id)}
                          size="large"
                        >
                          <HideIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <EditDialog
            fetching={editResult.fetching}
            customerId={id}
            tenantKey={tenantKey}
            open={Boolean(id)}
            onClose={handleCancelEdit}
            onSubmit={handleEditSubmit}
          />
          <NewDialog
            fetching={createNewResult.fetching}
            id={id}
            open={Boolean(newDialogOpen)}
            onClose={() => setNewDialogOpen(false)}
            onSubmit={handleCreateNewSubmit}
          />
          <Snackbar
            open={newCustomerSnackbarOpen}
            onClose={() => {
              setNewCustomerSnackbarOpen(false);
            }}
            message="Customer added"
          />
          <Dialog
            open={Boolean(hideCustomerId)}
            onClose={handleCancelHideCustomerClick}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Hide customer</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You are about to hide a customer.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelHideCustomerClick} color="primary">
                Cancel
              </Button>
              <Button
                onClick={handleConfirmHideCustomerClick}
                color="primary"
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </PageLayout>
  );
};

export default Page;
