import React from "react";
import { Route, Routes } from "react-router-dom";

import DashboardPage from "./Dashboard";
import JobPage from "./Job/JobPage";
import { Page as JobsListPage } from "./Jobs";

const EngineerRoutes = () => {
  return (
    <Routes>
      <Route>
        <Route index element={<DashboardPage />} />
        <Route path="jobs/:id" element={<JobPage />} />
        <Route path="jobs" element={<JobsListPage />} />
      </Route>
    </Routes>
  );
};

export default EngineerRoutes;
