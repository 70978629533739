import React from "react";
import TextField from "./Fields/TextField";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  "mt-6": {
    marginTop: theme.spacing(6)
  },
  "m-2": {
    margin: theme.spacing(2)
  },
  "mb-2": {
    marginBottom: theme.spacing(2)
  },
  "mt-2": {
    marginTop: theme.spacing(2)
  }
}));

const ContactFormFields = ({ name = "", disabled }) => {
  const classes = useStyles();

  return (
    <>
      <TextField
        name={`${name && `${name}.`}name`}
        label="Name"
        required
        disabled={disabled}
        fullWidth
        className={classes["mb-2"]}
      />
      <TextField
        name={`${name && `${name}.`}emailAddress`}
        type="email"
        label="Email address"
        placeholder="contact@example.com"
        required
        disabled={disabled}
        fullWidth
        className={classes["mb-2"]}
      />
      <TextField
        name={`${name && `${name}.`}telephone[0].phone`}
        type="tel"
        label="Phone number"
        required
        disabled={disabled}
        fullWidth
        className={classes["mb-2"]}
      />
      <TextField
        name={`${name && `${name}.`}telephone[0].label`}
        label="Label"
        required
        disabled={disabled}
        fullWidth
        className={classes["mb-2"]}
      />
    </>
  );
};

export default ContactFormFields;
