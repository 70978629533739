import React from "react";

import { Form } from "react-final-form";
import { Button, LinearProgress } from "@mui/material";
import TextField from "../../Fields/TextField";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const ChangePasswordDialog = ({ open, onClose, onSubmit, fetching }) => {
  const [initialFromValues] = React.useState({ newPassword: "" });

  return (
    <Dialog
      open={open}
      scroll="paper"
      fullWidth
      onClose={onClose}
      aria-labelledby="form-dialog-title">
      <>
        <Form
          initialValues={initialFromValues}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <>
              <form onSubmit={handleSubmit}>
                <DialogTitle id="form-dialog-title">
                  Change use password
                </DialogTitle>
                <DialogContent>
                  {fetching && <LinearProgress variant="indeterminate" />}
                  <TextField
                    name="newPassword"
                    type="password"
                    label="Password"
                    required
                    fullWidth
                    disabled={fetching}
                    // className={classes["mb-2"]}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary" disabled={fetching}>
                    Change password
                  </Button>
                </DialogActions>
              </form>
            </>
          )}
        />
      </>
    </Dialog>
  );
};

export default ChangePasswordDialog;
