import React from "react";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  noValue: {
    // color: theme.palette.warning.dark
  }
}));

const JobReferenceReadBody = ({ reference }) => {
  const classes = useStyles();

  if (reference) return <b>{reference}</b>;

  return (
    <div className={classes.noValue}>
      <em>&nbsp;</em>
    </div>
  );
};

export default JobReferenceReadBody;
