import React from "react";
import dayjs from "dayjs";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  LinearProgress,
} from "@mui/material";
import { useCSVDownloader } from "react-papaparse";

import gql from "graphql-tag";
import { useQuery } from "urql";

const QUERY = gql`
  query DownloadJobsQuery(
    $tenantKey: ID!
    $q: String
    $statusIn: [Tenant_Job_JobStatusType!]
    $scheduledBetween: DateBetweenFilterType
  ) {
    tenant(key: $tenantKey) {
      jobs {
        all(
          first: 2000
          filter: {
            q: $q
            statusIn: $statusIn
            scheduledBetween: $scheduledBetween
          }
          orderBy: START_DATE_DESC
        ) {
          totalCount
          pageInfo {
            hasNextPage
          }
          edges {
            node {
              id
              title
              status
              startDate
              endDate
              customer {
                id
                name
                po
              }
              site {
                name
              }
              invoiceNumber
            }
          }
        }
      }
    }
  }
`;

const headers = [
  "Customer",
  "Job",
  "Site",
  "Customer PO",
  "Invoice #",
  "Start Date",
  "End Date",
  "Status",
];

const DownloadJobsCsvDialog = ({
  open,
  handleClose,
  queryVariables,
  filename = "jobs",
}) => {
  const [{ fetching, data }] = useQuery({
    query: QUERY,
    variables: queryVariables,
    pause: !open,
    requestPolicy: "network-only",
  });
  const { CSVDownloader, Type } = useCSVDownloader();

  const csvData = React.useMemo(() => {
    const rows = (data?.tenant.jobs.all.edges || []).map(({ node }) => [
      node.customer?.name || "",
      node.title,
      node.site?.name || "",
      node.customer?.po || "",
      node.invoiceNumber || "",
      dayjs(node.startDate).format("L LT"),
      dayjs(node.endDate).format("L LT"),
      node.status,
    ]);

    return [headers, ...rows];
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">Download jobs</DialogTitle>
      <DialogContent>
        {fetching && (
          <DialogContentText id="alert-dialog-description">
            Downloading data, a link will appear below when ready
          </DialogContentText>
        )}
        {!fetching && (
          <DialogContentText id="alert-dialog-description">
            Your download is ready. Click on the link below to begin the
            download.
          </DialogContentText>
        )}
        {fetching ? (
          <LinearProgress variant="query" />
        ) : (
          <DialogContentText>
            {csvData && (
              <Button
                variant="contained"
                component={CSVDownloader}
                type={Type.Button}
                filename={filename}
                bom={true}
                data={csvData}
              >
                Export ready, click to download
              </Button>
            )}
            {data?.tenant.jobs.all.pageInfo.hasNextPage && (
              <p>
                <em>
                  Not all data is included in the result, try limiting search
                  timeframe.
                </em>
              </p>
            )}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadJobsCsvDialog;
