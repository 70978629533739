import React from "react";
import dayjs from "dayjs";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
    padding: theme.spacing(1),
    fontWeight: "bold",
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const DayHeader = ({ date }) => {
  const classes = useStyles();

  return <div className={classes.root}>{dayjs(date).format("ddd L")}</div>;
};

export default DayHeader;
