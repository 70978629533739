import React from "react";
import dayjs from "dayjs";

const JobScheduleReadBody = ({ startDate, endDate }) => (
  <>
    {dayjs(startDate).format("L LT")}
    <br />
    {dayjs(endDate).format("L LT")}
  </>
);

export default JobScheduleReadBody;
