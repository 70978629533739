import React from "react";
import { Container } from "@mui/material";
import dayjs from "dayjs";
import gql from "graphql-tag";
import useQueryString from "../../useQueryString";
import { useQuery } from "urql";
import { useTenantKey } from "../../useTenantKey";
import { useEngineerUrls } from "../../Urls";
import { LinearProgress, Typography, Chip, Link, TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate, Link as RouterLink } from "react-router-dom";
import Tabs from "../Dashboard/Tabs";
import { green } from '@mui/material/colors';

const QUERY = gql`
  query EngineerJobsListQuery($tenantKey: ID!, $q: String) {
    tenant(key: $tenantKey) {
      jobs {
        all(
          filter: {
            assignedToUser: true
            statusIn: [SCHEDULED, COMPLETED]
            q: $q
          }
        ) {
          totalCount
          pageInfo {
            hasNextPage
          }
          edges {
            cursor
            node {
              id
              title
              status
              startDate
              endDate
              customer {
                id
                name
              }
              site {
                name
              }
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  jobContainer: { marginBottom: theme.spacing(2) },
  jobInner: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(2),
  },
  "mb-2": { marginBottom: theme.spacing(2) },
  completedJobChip: {
    marginLeft: theme.spacing(2),
    borderColor: green[500],
    color: green[500],
  },
  searchBoxContainer: {
    marginBottom: theme.spacing(2),
  },
  siteName: {
    color: theme.palette.text.hint,
  },
}));

const SearchBox = ({ value, onChange }) => {
  const [inputValue, setInputValue] = React.useState(value || "");
  const [dirty, setDirty] = React.useState(false);

  const handleChange = React.useCallback(
    (e) => {
      setDirty(true);
      setInputValue(e.target.value);
    },
    [setInputValue]
  );

  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      onChange({ q: inputValue });
    },
    [onChange, inputValue]
  );

  React.useEffect(() => {
    if (dirty && inputValue === "") onChange({ q: inputValue });
  }, [inputValue, dirty, onChange]);

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth
        type="search"
        variant="outlined"
        value={inputValue}
        placeholder="Job, customer or site name"
        onChange={handleChange}
      />
      <button type="submit" style={{ display: "none" }}>
        Search
      </button>
    </form>
  );
};

const Page = () => {
  const navigate = useNavigate();
  const { q } = useQueryString();
  const classes = useStyles();
  const engineerUrls = useEngineerUrls();
  const { tenantKey } = useTenantKey();
  // const [q, setQ] = React.useState("");

  const [{ data, fetching, error }] = useQuery({
    query: QUERY,
    variables: { tenantKey, q },
    requestPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (error) console.error("Failed to fetch engineer jobs", error);
  }, [error]);

  const handleSearchChange = ({ q: newQuery }) => {
    if (q === newQuery) return;
    navigate(`${engineerUrls.jobs}?q=${encodeURIComponent(newQuery)}`);
  };

  return (
    <>
      <div className={classes["mb-2"]}>
        <Tabs selected={1} />
      </div>
      <Container>
        <div className={classes.searchBoxContainer}>
          <SearchBox value={q} onChange={handleSearchChange} />
        </div>

        {fetching && <LinearProgress variant="query" />}
        {error && <p>Failed to fetch jobs, refresh the page to try again.</p>}
        {data && data.tenant?.jobs?.all && (
          <div>
            {data.tenant.jobs.all.edges.map(({ cursor, node }) => (
              <div key={cursor} className={classes.jobContainer}>
                <div className={classes.jobInner}>
                  <Typography variant="h5">
                    {node.customer.name}
                    {node.status === "COMPLETED" && (
                      <Chip
                        label="Completed"
                        className={classes.completedJobChip}
                        size="small"
                        variant="outlined"
                      />
                    )}
                  </Typography>
                  <Typography gutterBottom variant="h6">
                    <span className={classes.siteName}>{node.site.name}</span>
                  </Typography>
                  <Typography gutterBottom>
                    <small>
                      <b>{dayjs(node.startDate).format("L")}</b>{" "}
                      {dayjs(node.startDate).format("LT")} -{" "}
                      {dayjs(node.endDate).isSame(node.startDate, "day") ||
                        dayjs(node.endDate).format("L")}{" "}
                      {dayjs(node.endDate).format("LT")}
                    </small>
                  </Typography>
                  <Typography>
                    <Link component={RouterLink} to={engineerUrls.job(node.id)}>
                      {node.title}
                    </Link>
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        )}
      </Container>
    </>
  );
};

export default Page;
