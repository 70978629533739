import React from "react";
import { useParams } from "react-router-dom";

const Context = React.createContext();

function Provider(props) {
  const { tenantKey } = useParams();

  return (
    <Context.Provider
      value={{
        tenantKey
      }}
      {...props}
    />
  );
}

const useTenantKey = () => React.useContext(Context);

export { Provider, useTenantKey };
