import React from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "urql";
import { useTheme, Zoom, Fab, LinearProgress } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Add as AddIcon } from "@mui/icons-material";
import RecordMaterialsDialog from "./RecordMaterialsDialog";
import MaterialRow from "./MaterialRow";

const QUERY = gql`
  query JobNode($id: ID!) {
    node(id: $id) {
      id
      ... on Tenant_Job_JobType {
        materials {
          totalCount
          edges {
            cursor
            node {
              id
              name
              qty
            }
          }
        }
      }
    }
  }
`;

const REMOVE_MATERIAL_MUTATION = gql`
  mutation RemoveMaterial($tenantKey: ID!, $jobId: ID!, $materialId: ID!) {
    tenant(key: $tenantKey) {
      jobs {
        materials {
          remove(input: { jobId: $jobId, materialId: $materialId }) {
            job {
              id
              materials {
                totalCount
                edges {
                  node {
                    id
                    name
                    qty
                    unitCost
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(12)
  },
  fab: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  },
  edgeRoot: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center"
  },
  qtyContainer: {},
  qtyLabel: {},
  nameContainer: {
    flex: "1 1 auto"
  },
  nameValue: {
    ...theme.typography.h5,
    flex: "1 1 auto"
  }
}));

const MaterialsTabPanel = ({ tenantKey, jobId }) => {
  const classes = useStyles();
  const [addOpen, setAddOpen] = React.useState(false);

  const theme = useTheme();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen
  };

  const [{ fetching, data }] = useQuery({
    query: QUERY,
    variables: { id: jobId },
    requestPolicy: "cache-and-network"
  });

  const [
    { fetching: fetchingMutation },
    executeRemoveMaterialMutation
  ] = useMutation(REMOVE_MATERIAL_MUTATION);

  const handleMaterialsAdded = () => {
    setAddOpen(false);
  };

  const handleAddClick = () => {
    setAddOpen(true);
  };

  const handleCancelAddClick = () => setAddOpen(false);

  const handleRemoveMaterial = id => () => {
    return executeRemoveMaterialMutation({ tenantKey, jobId, materialId: id });
  };

  return (
    <div className={classes.root}>
      {fetching ||
        (fetchingMutation && <LinearProgress variant="indeterminate" />)}
      {data && (
        <>
          {data.node.materials.totalCount === 0 && (
            <p>No materials have been recorded against this job.</p>
          )}
          {data.node.materials.edges.map(({ cursor, node }) => (
            <MaterialRow
              key={cursor}
              node={node}
              onRemove={handleRemoveMaterial(node.id)}
            />
          ))}
        </>
      )}
      <Zoom
        in={true}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${transitionDuration.exit}ms`
        }}
        unmountOnExit
      >
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          onClick={handleAddClick}
        >
          <AddIcon />
        </Fab>
      </Zoom>

      <RecordMaterialsDialog
        {...{ jobId, tenantKey }}
        open={addOpen}
        onRecorded={handleMaterialsAdded}
        onCancel={handleCancelAddClick}
      />
    </div>
  );
};

export default MaterialsTabPanel;
