import React from "react";
import gql from "graphql-tag";
import { useMutation } from "urql";
import { Form } from "react-final-form";
import TextField from "../../Fields/TextField";
import { Button } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const CHANGE_NOTIFICATION_EMAILS = gql`
  mutation ChangeNotificationEmails(
    $tenantKey: ID!
    $id: ID!
    $emails: [String!]!
  ) {
    tenant(key: $tenantKey) {
      jobs {
        setNotificationEmails(id: $id, input: { emails: $emails }) {
          job {
            id
            notificationEmails
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  actions: {
    marginTop: theme.spacing(2),
  },
  btnLeft: {
    marginRight: theme.spacing(2),
  },
}));

const JobNotificationEmailsEditBody = ({
  onCancel,
  onChanged,
  tenantKey,
  jobId,
  emails,
}) => {
  const classes = useStyles();
  const [initialFromValues] = React.useState({
    emails: emails.join("; "),
  });

  const [{ fetching }, executeChangeNotificationEmails] = useMutation(
    CHANGE_NOTIFICATION_EMAILS
  );

  const handleSaveChanges = (form) => {
    const values = {
      tenantKey,
      id: jobId,
      emails: form.emails.split(";").map((x) => x.trim()),
    };
    executeChangeNotificationEmails(values).then(() => {
      onChanged();
    });
  };

  return (
    <Form
      initialValues={initialFromValues}
      onSubmit={handleSaveChanges}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <TextField
            name="emails"
            required
            fullWidth
            disabled={fetching}
            label="Email addresses"
            placeholder="john@example.com; jane@example.com"
          />
          <div className={classes.actions}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={fetching}
              className={classes.btnLeft}
            >
              Save
            </Button>
            <Button onClick={onCancel} disabled={fetching}>
              Cancel
            </Button>
          </div>
        </form>
      )}
    />
  );
};

export default JobNotificationEmailsEditBody;
