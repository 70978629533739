import React from "react";
import gql from "graphql-tag";
import { useMutation } from "urql";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const SCHEDULE = gql`
  mutation SetAsScheduled($tenantKey: ID!, $jobId: ID!) {
    tenant(key: $tenantKey) {
      jobs {
        schedule(id: $jobId) {
          job {
            id
            status
          }
        }
      }
    }
  }
`;

const CANCEL = gql`
  mutation SetAsCancelled($tenantKey: ID!, $jobId: ID!) {
    tenant(key: $tenantKey) {
      jobs {
        cancel(id: $jobId) {
          job {
            id
            status
          }
        }
      }
    }
  }
`;

const CLOSE = gql`
  mutation SetAsClosed($tenantKey: ID!, $jobId: ID!) {
    tenant(key: $tenantKey) {
      jobs {
        close(id: $jobId) {
          job {
            id
            status
          }
        }
      }
    }
  }
`;

const REOPEN = gql`
  mutation Reopen($tenantKey: ID!, $jobId: ID!) {
    tenant(key: $tenantKey) {
      jobs {
        reopen(id: $jobId) {
          job {
            id
            status
          }
        }
      }
    }
  }
`;

const BACK_TO_SCHEDULED = gql`
  mutation MoveBackToScheduled($tenantKey: ID!, $jobId: ID!) {
    tenant(key: $tenantKey) {
      jobs {
        moveBackToScheduled(id: $jobId) {
          job {
            id
            status
          }
        }
      }
    }
  }
`;

const JobStatusFlipButton = ({ tenantKey, jobId, currentStatus }) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [confirmChangeOpen, setConfirmChangeOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const [{ fetching: fetchingSchedule }, executeSchedule] =
    useMutation(SCHEDULE);
  const [{ fetching: fetchingCancel }, executeCancel] = useMutation(CANCEL);
  const [{ fetching: fetchingClose }, executeClose] = useMutation(CLOSE);
  const [{ fetching: fetchingReopen }, executeReopen] = useMutation(REOPEN);
  const [
    { fetching: fetchingMoveBackToScheduled },
    executeMoveBackToScheduled,
  ] = useMutation(BACK_TO_SCHEDULED);

  const [options, setOptions] = React.useState([]);
  const [allowed, setAllowed] = React.useState(false);

  React.useEffect(() => {
    const fetching =
      fetchingSchedule ||
      fetchingCancel ||
      fetchingClose ||
      fetchingReopen ||
      fetchingMoveBackToScheduled;

    const newAllowed = !fetching && options.some((opt) => opt.allowed);

    setAllowed(newAllowed);
  }, [
    options,
    fetchingSchedule,
    fetchingCancel,
    fetchingClose,
    fetchingReopen,
    fetchingMoveBackToScheduled,
  ]);

  const [selectedIndex, setSelectedIndex] = React.useState(
    Math.max(
      options.findIndex((opt) => opt.allowed),
      0
    )
  );

  React.useEffect(() => {
    const availableOptions = [
      {
        action: "SCHEDULE",
        execute: executeSchedule,
        label: "Schedule",
        allowed: currentStatus === "DRAFT",
      },
      {
        action: "CANCEL",
        execute: executeCancel,
        label: "Cancel",
        allowed: currentStatus === "DRAFT" || currentStatus === "SCHEDULED",
      },
      {
        action: "CLOSE",
        execute: executeClose,
        label: "Close",
        allowed: currentStatus === "COMPLETED",
      },
      {
        action: "BACK-TO-SCHEDULED",
        execute: executeMoveBackToScheduled,
        label: "Back to scheduled",
        allowed: currentStatus === "COMPLETED",
      },
      {
        action: "REOPEN",
        execute: executeReopen,
        label: "Reopen",
        allowed: currentStatus === "CLOSED",
      },
    ];
    const visibleOptions = availableOptions.filter((x) => x.allowed);

    setOptions(visibleOptions);
    setSelectedIndex(
      Math.max(
        visibleOptions.findIndex((opt) => opt.allowed),
        0
      )
    );
  }, [
    currentStatus,
    executeCancel,
    executeClose,
    executeSchedule,
    executeReopen,
    executeMoveBackToScheduled,
  ]);

  const handleChangeClick = () => {
    setConfirmChangeOpen(true);
  };
  const handleCancelChangeClick = () => {
    setConfirmChangeOpen(false);
  };

  const handleConfirmChangeClick = () => {
    const opt = options[selectedIndex];

    // console.info(`You clicked ${opt.action}`);
    opt.execute({ tenantKey, jobId }).then(() => {
      setMenuOpen(false);
      setConfirmChangeOpen(false);
    });
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setMenuOpen(false);
    setConfirmChangeOpen(true);
  };

  const handleToggleMenu = () => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
  };

  const handleCloseMenu = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setMenuOpen(false);
  };

  return (
    <>
      <Dialog
        open={confirmChangeOpen}
        onClose={handleCancelChangeClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Change job status</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to change the status of this job.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelChangeClick} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmChangeClick} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <ButtonGroup
        variant="outlined"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
        disabled={!allowed}
      >
        <Button onClick={handleChangeClick}>
          {options[selectedIndex]?.label || ""}
        </Button>
        <Button
          color="primary"
          size="small"
          aria-controls={menuOpen ? "split-button-menu" : undefined}
          aria-expanded={menuOpen ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggleMenu}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={menuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.action}
                      disabled={!option.allowed}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default JobStatusFlipButton;
