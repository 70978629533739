import React from "react";
import dayjs from "dayjs";
import gql from "graphql-tag";
import { useQuery, useMutation } from "urql";
import { LinearProgress, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Form } from "react-final-form";
import TimeslipFormFields from "./TimeslipFormFields";

const QUERY = gql`
  query TimeslipNode($id: ID!) {
    node(id: $id) {
      id
      ... on Tenant_Me_Timeslips_TimeslipType {
        overtime
        date
        startTime
        siteArrivalTime
        siteDepartureTime
        finishTime
        notes
        vehicleRegistration
        vehicleStartOdometerReading
        vehicleFinishOdometerReading
      }
    }
  }
`;

const MUTATION = gql`
  mutation EditJobTimeslip(
    $tenantKey: ID!
    $id: ID!
    $input: Tenant_Me_Timeslips_Edit_InputType!
  ) {
    tenant(key: $tenantKey) {
      me {
        timeslips {
          edit(id: $id, input: $input) {
            timeslip {
              id
              date
            }
          }
        }
      }
    }
  }
`;

const RETRACT_MUTATION = gql`
  mutation RetractJobTimeslip($tenantKey: ID!, $id: ID!) {
    tenant(key: $tenantKey) {
      me {
        timeslips {
          retract(id: $id) {
            timeslip {
              id
              date
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles(() => ({
  retractButton: {
    marginRight: "auto",
  },
}));

const EditTimeslipDialog = ({
  tenantKey,
  id,
  open,
  onEditied,
  onRetracted,
  onCancel,
}) => {
  const classes = useStyles();

  const [query] = useQuery({
    query: QUERY,
    variables: { id },
    pause: !id,
    requestPolicy: "cache-and-network",
  });

  const [{ fetching }, executeEditTimeslip] = useMutation(MUTATION);
  const [{ fetching: retracting }, executeRetractTimeslip] =
    useMutation(RETRACT_MUTATION);

  const [initialFormValues, setInitialFormValues] = React.useState(null);

  React.useEffect(() => {
    const node = query.data?.node;

    if (!node) return;

    setInitialFormValues({
      date: dayjs(node.date).format("YYYY-MM-DD"),
      overtime: node.overtime,
      startTime: dayjs(node.startTime).format("HH:mm"),
      siteArrivalTime: dayjs(node.siteArrivalTime).format("HH:mm"),
      siteDepartureTime: dayjs(node.siteDepartureTime).format("HH:mm"),
      finishTime: dayjs(node.finishTime).format("HH:mm"),
      notes: node.notes,
      vehicle: {
        registration: node.vehicleRegistration,
        startOdometerReading: node.vehicleStartOdometerReading,
        finishOdometerReading: node.vehicleFinishOdometerReading,
      },
    });
  }, [query]);

  const onSubmit = ({
    date,
    startTime,
    siteArrivalTime,
    siteDepartureTime,
    finishTime,
    notes,
    overtime,
    vehicle,
  }) => {
    const input = {
      date: dayjs(`${date}`).format("YYYY-MM-DD"),
      startTime: dayjs(`${date}T${startTime}`).format(),
      siteArrivalTime: dayjs(`${date}T${siteArrivalTime}`).format(),
      siteDepartureTime: dayjs(`${date}T${siteDepartureTime}`).format(),
      finishTime: dayjs(`${date}T${finishTime}`).format(),
      notes,
      vehicle: {
        finishOdometerReading: Boolean(vehicle.finishOdometerReading)
          ? Number(vehicle.finishOdometerReading)
          : null,
        registration: vehicle.registration,
        startOdometerReading: Boolean(vehicle.startOdometerReading)
          ? Number(vehicle.startOdometerReading)
          : null,
      },
      overtime,
    };
    return executeEditTimeslip({ tenantKey, id, input }).then(
      ({ data, error }) => {
        if (!error) onEditied();
        else {
          console.log(error);
        }
      }
    );
  };

  const [confirmRetract, setConfirmRetract] = React.useState(false);
  const handleRetractClick = () => {
    setConfirmRetract(true);
    setTimeout(() => setConfirmRetract(false), 6000);
  };
  const handleConfirmRetractClick = () => {
    executeRetractTimeslip({ tenantKey, id }).then(({ data, error }) => {
      if (!error) onRetracted();
    });
  };

  return (
    <>
      <Dialog open={open} fullWidth>
        <Form
          initialValues={initialFormValues}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle id="form-dialog-title">Edit timesheet</DialogTitle>
              <DialogContent>
                {query.fetching && <LinearProgress variant="query" />}
                {fetching && <LinearProgress variant="indeterminate" />}
                {retracting && <LinearProgress variant="indeterminate" />}
                {/* <code>{JSON.stringify(values, null, 2)}</code> */}
                <TimeslipFormFields disabled={query.fetching || fetching} />
              </DialogContent>
              <DialogActions>
                {!confirmRetract && (
                  <Button
                    onClick={handleRetractClick}
                    className={classes.retractButton}
                    disabled={fetching || retracting}
                  >
                    Retract
                  </Button>
                )}
                {confirmRetract && (
                  <Button
                    onClick={handleConfirmRetractClick}
                    className={classes.retractButton}
                    color="primary"
                    disabled={retracting}
                  >
                    Click to confirm
                  </Button>
                )}
                <Button onClick={onCancel} color="primary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={fetching || retracting}
                >
                  Save changes
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

export default EditTimeslipDialog;
