import React from "react";
import { Navigate } from "react-router-dom";

import qs from "querystringify";
// TODO: Replace querystringify with import useQueryString from "../../useQueryString";
import { useAuth } from "./AuthContext";
import { useMembership } from "./useMembership";

const defaultSearchRedirect = (location) => ({
  continueTo: `${location.pathname}${location.search}`,
});

const RequiresMemebership = ({
  children,
  rolesAnyOf,
  location,
  redirectPathname,
  redirectSearch,
}) => {
  const { isAuthenticated } = useAuth();
  const { fetching, isInAnyRole, tenantKey } = useMembership();

  if (fetching) {
    return <p>Checking membership</p>;
  }

  if (!isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: redirectPathname || "/login",
          search: qs.stringify(
            {
              ...(redirectSearch || defaultSearchRedirect)(location),
            },
            true
          ),
        }}
      />
    );
  }

  if (!isInAnyRole(rolesAnyOf))
    return (
      <Navigate
        to={{
          pathname: redirectPathname || `/${tenantKey}`,
        }}
      />
    );

  return children;
};

export default RequiresMemebership;
