import React from "react";
import dayjs from "dayjs";
import { IconButton, Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Today as TodayIcon,
} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  dateContainer: {
    ...theme.typography.h5,
    flex: "1 1 auto",
    textAlign: "center",
    position: "relative",
  },
  listButtonContainer: {
    position: "absolute",
    left: theme.spacing(1),
    top: 0,
  },
  todayButtonContainer: {
    position: "absolute",
    right: theme.spacing(1),
    top: 0,
  },
}));

const CalendarHeader = ({
  firstDate,
  onPreviousClick,
  onNextClick,
  onTodayClick,
}) => {
  const classes = useStyles();

  const firstDateJs = dayjs(firstDate);

  return (
    <div className={classes.root}>
      <div>
        <IconButton onClick={onPreviousClick} size="large">
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <div className={classes.dateContainer}>
        {firstDateJs.format("MMMM YYYY")}
        <div className={classes.todayButtonContainer}>
          <Tooltip title="Jump to today">
            <IconButton onClick={onTodayClick} size="small">
              <TodayIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div>
        <IconButton onClick={onNextClick} size="large">
          <ChevronRightIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default CalendarHeader;
