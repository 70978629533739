import React from "react";
import gql from "graphql-tag";
import { useQuery } from "urql";

import {
  CircularProgress,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';

const QUERY = gql`
  query AllCustomersQuery($tenantKey: ID!, $nameQuery: String!) {
    tenant(key: $tenantKey) {
      customers {
        all(filter: { name: { query: $nameQuery } }) {
          totalCount
          pageInfo {
            hasNextPage
          }
          edges {
            cursor
            node {
              id
              name
              contact {
                name
                emailAddress
                telephone {
                  phone
                  label
                }
              }
              address {
                line1
                line2
                townCity
                county
                postCode
              }
              sites {
                totalCount
                edges {
                  cursor
                  node {
                    name
                    contact {
                      name
                      emailAddress
                      telephone {
                        phone
                        label
                      }
                    }
                    address {
                      line1
                      line2
                      townCity
                      county
                      postCode
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const remapAddress = (address) => ({
  line1: address.line1,
  line2: address.line2,
  townCity: address.townCity,
  county: address.county,
  postCode: address.postCode,
});
const remapContact = (contact) => ({
  name: contact.name,
  emailAddress: contact.emailAddress,
  telephone: contact.telephone.map(({ phone, label }) => ({
    phone,
    label,
  })),
});

const useStyles = makeStyles((theme) => ({
  marginBottom: { marginBottom: theme.spacing(2) },
}));

const CustomerStep = ({ tenantKey, onComplete }) => {
  const classes = useStyles();
  const [nameQuery, setNameQuery] = React.useState("");
  const [inputVal, setInputVal] = React.useState("");
  const inputRef = React.useRef("");
  const timeoutId = React.useRef();
  const [selected, setSelected] = React.useState(null);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  // const [defaultOption, setDefaultOption] = React.useState(null);

  const [result] = useQuery({
    query: QUERY,
    variables: { tenantKey, nameQuery },
    requestPolicy: "cache-and-network",
  });

  // React.useEffect(() => {
  //   // setDefaultOption(options[0]);
  // }, [options, setDefaultOption]);

  const handleContinueWithCustomer = () => {
    onComplete({
      customerId: selected.id,
      contact: remapContact(selected.contact),
      site: {
        name: selected.address.townCity,
        address: remapAddress(selected.address),
      },
    });
  };

  const handleContinueWithSite = (site) => () => {
    onComplete({
      customerId: selected.id,
      contact: remapContact(site.contact || selected.contact),
      site: {
        name: site.name,
        address: remapAddress(site.address || selected.address),
      },
    });
  };

  const handleChange = (_, value) => {
    setSelected(value);
  };

  React.useEffect(() => {
    if (result.fetching) {
      setOptions([]);
      return;
    }

    const customers = result.data.tenant.customers.all.edges;
    setOptions(customers.map(({ node }) => node));
  }, [result]);

  const handleInputChange = (event) => {
    setInputVal(event.target.value);
    inputRef.current = event.target.value;
  };

  React.useEffect(() => {
    clearTimeout(timeoutId.current);

    if (!inputVal.trim()) return;

    timeoutId.current = setTimeout(() => {
      setNameQuery(inputRef.current);
    }, 800);
  }, [inputVal]);

  return <>
    <Autocomplete
      className={classes.marginBottom}
      open={open}
      autoComplete
      filterOptions={(x) => x}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      value={selected}
      // defaultValue={defaultOption}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={result.fetching}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label="Customer"
          variant="outlined"
          onChange={handleInputChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {result.fetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
    {selected?.sites.totalCount === 0 && (
      <Button
        variant="contained"
        color="primary"
        onClick={handleContinueWithCustomer}
      >
        Next
      </Button>
    )}
    {selected?.sites.totalCount > 0 && (
      <>
        <Typography variant="h5" gutterBottom>
          Select a site
        </Typography>
        <List>
          {selected.sites.edges.map(
            ({ cursor: siteCursor, node: siteNode }) => (
              <ListItem
                key={siteCursor}
                button
                onClick={handleContinueWithSite(siteNode)}
              >
                <ListItemText
                  primary={siteNode.name}
                  //   secondary={JSON.stringify(siteNode)}
                />
              </ListItem>
            )
          )}
        </List>
      </>
    )}
    {/* {selected && <code>{JSON.stringify(selected, null, 2)}</code>} */}
  </>;
};

export default CustomerStep;
