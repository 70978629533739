import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import qs from "querystringify";

import { useAuth } from "./AuthContext";

const defaultSearchRedirect = (location) => ({
  continueTo: `${location.pathname}${location.search}`,
});

const RequireAuth = ({ children, redirectPathname, redirectSearch }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (isAuthenticated) {
    return children;
  }

  console.log({ redirectSearch, location });

  return (
    <Navigate
      to={{
        pathname: redirectPathname || "/login",
        search: qs.stringify(
          {
            ...(redirectSearch || defaultSearchRedirect)(location),
          },
          true
        ),
      }}
    />
  );
};

export default RequireAuth;
