import React from "react";

import { Form } from "react-final-form";
import { Grid, Button, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AddressFormFields from "../../../AddressFormFields";
import ContactFormFields from "../../../ContactFormFields";
import TextField from "../../../Fields/TextField";

const useStyles = makeStyles(theme => ({
  marginBottom: { marginBottom: theme.spacing(2) },
  marginRight: { marginRight: theme.spacing(2) }
}));

const SiteAndContactStep = ({ site, contact, onComplete, onBack }) => {
  const classes = useStyles();

  const handleSubmit = form => {
    onComplete(form);
  };

  return (
    <>
      <Form
        initialValues={{ site, contact }}
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5">Site</Typography>
                <TextField
                  name="site.name"
                  required
                  fullWidth
                  label="Site name"
                  className={classes.marginBottom}
                />
                <AddressFormFields name="site" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5">Contact</Typography>
                <ContactFormFields name="contact" />
              </Grid>
            </Grid>
            <div>
              <Button onClick={onBack} className={classes.marginRight}>
                Back
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Next
              </Button>
            </div>
          </form>
        )}
      />
    </>
  );
};

export default SiteAndContactStep;
