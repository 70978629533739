import React from "react";
import TextField from "./Fields/TextField";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  "mb-2": {
    marginBottom: theme.spacing(2),
  },
}));

const AddressFormFields = ({ name = "", disabled }) => {
  const classes = useStyles();

  return (
    <>
      <TextField
        name={`${name && `${name}.`}address.line1`}
        label="Line 1"
        required
        disabled={disabled}
        fullWidth
        className={classes["mb-2"]}
      />
      <TextField
        name={`${name && `${name}.`}address.line2`}
        label="Line 2"
        disabled={disabled}
        fullWidth
        className={classes["mb-2"]}
      />
      <TextField
        name={`${name && `${name}.`}address.townCity`}
        label="Town/city"
        required
        disabled={disabled}
        fullWidth
        className={classes["mb-2"]}
      />
      <TextField
        name={`${name && `${name}.`}address.county`}
        label="County"
        disabled={disabled}
        fullWidth
        className={classes["mb-2"]}
      />
      <TextField
        name={`${name && `${name}.`}address.postCode`}
        label="Post code"
        required
        disabled={disabled}
        fullWidth
        className={classes["mb-2"]}
      />
    </>
  );
};

export default AddressFormFields;
