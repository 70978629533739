import React from "react";
import { Form } from "react-final-form";
import { Button, LinearProgress } from "@mui/material";
import FormFields from "./FormFields";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const NewDialog = ({ open, onClose, onSubmit, fetching }) => {
  const [initialFormValues] = React.useState({
    name: "",
    address: {
      line1: "",
      line2: "",
      townCity: "",
      county: "",
      postCode: "",
    },
    contact: {
      name: "",
      emailAddress: "",
      telephone: [
        {
          phone: "",
          label: "Mobile",
        },
      ],
    },
  });

  // const node = result.data.node;

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={onClose}
      aria-labelledby="form-dialog-title">
      <Form
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">New customer</DialogTitle>
            <DialogContent>
              <>
                {fetching && <LinearProgress variant="indeterminate" />}

                <FormFields disabled={fetching} />
              </>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={fetching}>
                Create customer
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

export default NewDialog;
