import React from "react";
import dayjs from "dayjs";
import { Link, IconButton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Delete as RemoveIcon } from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    "&:hover": {
      background: theme.palette.action.hover
    }
  },
  titleContainer: {
    display: "flex",
    alignItems: "center"
  },
  titleLink: {
    flex: "1 1 auto"
  }
}));

const JobAttachments = ({ attachment, onRemoveClick, editable }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <div className={classes.titleLink}>
          <Link href={attachment.src} target="_blank">
            {attachment.originalFilename}
          </Link>
        </div>
        {editable && (
          <div>
            <IconButton
              size="small"
              onClick={() => onRemoveClick(attachment.id)}
            >
              <RemoveIcon />
            </IconButton>
          </div>
        )}
      </div>
      <div>
        <small>Attached {dayjs(attachment.attachedAt).format("L LT")}</small>
      </div>
    </div>
  );
};

export default JobAttachments;
