import React from "react";
import clsx from "clsx";
import { IconButton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {
  FormatAlignLeft as DescriptionIcon,
  Edit as EditIcon,
} from "@mui/icons-material";

import JobDescriptionReadBody from "./JobDescriptionReadBody";
import JobDescriptionEditBody from "./JobDescriptionEditBody";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: theme.spacing(2),
    alignItems: "top",
    padding: theme.spacing(1),
    marginLeft: -theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  rootInEdit: {
    background: theme.palette.action.focus,
  },
  iconContainer: {
    flex: "0 0 auto",
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  bodyContainer: {
    flex: "1 1 auto",
    paddingTop: theme.spacing(1),
  },
  actionContainer: { flex: "0 0 auto" },
  "mb-2": { marginBottom: theme.spacing(2) },
}));

const JobDescription = ({ tenantKey, jobId, description, editable }) => {
  const classes = useStyles();
  const [editMode, setEditMode] = React.useState(false);

  const handleEnterEditMode = () => {
    setEditMode(true);
  };

  const handleCanelEditMode = () => {
    setEditMode(false);
  };

  const handleChanged = () => {
    setEditMode(false);
  };

  return (
    <div className={clsx(classes.root, { [classes.rootInEdit]: editMode })}>
      <div className={classes.iconContainer}>
        <DescriptionIcon />
      </div>
      <div className={classes.bodyContainer}>
        <div className={classes["mb-2"]}>
          <b>Description</b>
        </div>
        {editMode ? (
          <JobDescriptionEditBody
            {...{ tenantKey, jobId, description }}
            onCancel={handleCanelEditMode}
            onChanged={handleChanged}
          />
        ) : (
          <JobDescriptionReadBody {...{ description }} />
        )}
      </div>
      {editable && !editMode && (
        <div className={classes.actionContainer}>
          <IconButton aria-label="edit" onClick={handleEnterEditMode} size="large">
            <EditIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default JobDescription;
